import {
    put, takeLatest, all
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
    INSTALLATION_ELEMENT_CHILDREN,
    INSTALLATION_ROOT_ELEMENT,
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import {
    errorGetTreeForWidgets,
    successGetTreeForWidgets
} from '@/store/actions/widgetDashboard/getTreeForWidgets'
import { GET_TREE_FOR_WIDGETS } from '@/store/actions/widgetDashboard'
import { GEOZONE, ROOT } from '@/constants/objectTypes'

function* getTreeForWidgets() {
    try {
        let geoZoneTree = {}
        try {
            const { data } = yield request({
                url: INSTALLATION_ROOT_ELEMENT,
                method: 'get',
            })
            const roots = yield all(data.map((element) => {
                return {
                    ...element,
                    type: ROOT,
                    name: element.name,
                    location: [element.point.latitude, element.point.longitude],
                }
            }))
            geoZoneTree = roots[0]
        } catch (error) {}

        try {
            const { data: geoZones } = yield request( {
                url: INSTALLATION_ELEMENT_CHILDREN({
                    params: {
                        parentId: geoZoneTree.id,
                    },
                }),
                method: 'get',
            })
            geoZoneTree.children = geoZones.map(item => {
                return {
                    ...item,
                    location: [item.point.latitude, item.point.longitude],
                    type: GEOZONE,
                    zoneSelector: [item.id]
                }
            })
        } catch (error) {}

        yield put(successGetTreeForWidgets([geoZoneTree]))

    } catch (error) {
        console.log('error', error)
        yield put(errorGetTreeForWidgets())
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные геозон. \nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_TREE_FOR_WIDGETS.REQUEST, getTreeForWidgets)
}