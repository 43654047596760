import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { treeHandlers } from 'react-hyper-tree'
import {
  Main,
  Content,
  NavContainer
} from '@/containers/pages/ScheduleManger/styles'
import { FormattedMessage as Lang } from 'react-intl'
import TextControl from '@/components/controls/TextControl'
import UsersIcons from '@/components/icons/users'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import CreateScheduleMenu from '@/forms/ScheduleManager/CreateScheduleMenu/component'
import {
  ACTION_TYPE,
  CREATE,
  PUT
} from '@/constants/semanticNames'
import CreateEditScheduleForm from '@/forms/ScheduleManager/CreateEditScheduleForm'
import CreateEditTaskForm from '@/forms/ScheduleManager/CreateEditTaskForm'
import ScheduleContent from '@/containers/pages/ScheduleManger/components/ScheduleContent'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import { GEOZONE, SCHEDULE } from '@/constants/objectTypes'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import Loader from '@/components/blocks/Loader'

const ScheduleManager = ({
  intl,
  globalLoading,
  requestGetTree,
  treeData,
  treeStatus,
  card,
  scheduleSelectedNode,
  globalFilters,

  failureTableData,
  loadingFailureTable,
  failureDates,
  errorStatus,

  activityTableData,
  loadingActivityTable,
  activityDates,
  action,

  requestUploadSchedule,
  requestGetSchedule,
  requestCreateSchedule,
  requestEditSchedule,
  requestDeleteSchedule,
  setScheduleSelectedNode,
  requestGetFailureLogTable,
  setFailureLogDatesRange,
  setErrorStatus,
  requestGetActivityLogTable,
  setActivityLogDatesRange,
  setAction
}) => {
  useHtmlTitle(intl.messages['menu.scheduleManager'])
  const formRef = useRef(null)
  const taskFormRef = useRef(null)
  const [formValues, setFormValues] = useState()
  const [selectedDeviceType, setSelectedDeviceType] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isTaskFormValid, setIsTaskFormValid] = useState(false)
  const [search, setSearch] = useState('')

  const handleSetPopup = useCallback((value) => () => {
    setIsPopupOpen(value)
  }, [])

  const handleSearchChange = (value) => {
    setSearch(value)
  }


  useEffect(() => {
    requestGetTree()
  }, [requestGetTree])

  const openTreeSelectedNode = useCallback(() => {
    if (treeData.length && scheduleSelectedNode && scheduleSelectedNode.id) {
      const tree = treeHandlers.trees['schedule-manager-tree']
      const path = scheduleSelectedNode.original.options.path
      setTimeout(() => {
        tree.handlers.setSelectedByPath(path.slice(1))
      })
    }
  }, [treeData, scheduleSelectedNode])

  useEffect(() => {
    openTreeSelectedNode()
  }, [openTreeSelectedNode])

  const onTreeNodeSelect = useCallback((node) => {
    setScheduleSelectedNode(node)
  }, [setScheduleSelectedNode])

  const renderCreateEditScheduleForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedSchedule = {}
    let title = ''
    let edit = false
    let onCancel = noop
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedSchedule = {}
      onSubmitErrorLocal = handleSetPopup(true)
      onSubmitLocal = requestCreateSchedule
      onCancel = () => {
        openTreeSelectedNode()
        setFormValues({})
        setSelectedDeviceType(null)
      }
      title = <Lang id={'scheduleManager.titles.create'}/>
    }
    if (formValues[ACTION_TYPE] === PUT) {
      edit = true
      localSelectedSchedule = card
      onSubmitErrorLocal = handleSetPopup(true)
      onSubmitLocal = requestEditSchedule
      onCancel = () => {
        edit = false
        setFormValues({})
        setSelectedDeviceType(null)
        setIsTaskFormValid(false)
        openTreeSelectedNode()
      }
      title = <Lang id={'scheduleManager.titles.edit'}/>
    }
       return (
         <NavContainer br>
           <CreateEditScheduleForm
             ref={formRef}
             taskFormRef={taskFormRef}
             selectedSchedule={localSelectedSchedule}
             submitError={onSubmitErrorLocal}
             setFormValues={setFormValues}
             onCancel={onCancel}
             submit={onSubmitLocal}
             title={title}
             formValues={formValues}
             edit={edit}
             setSelectedDeviceType={setSelectedDeviceType}
             geoZone={scheduleSelectedNode}
             isTaskFormValid={isTaskFormValid}
             intl={intl}
           />
           {isPopupOpen && (
             <GlobalPopup
               content={(
                 <GlobalPopupContent
                   type={'warning'}
                   onClose={handleSetPopup(false)}
                   title={<Lang id={'scheduleManager.popup.executeImpossible'}/>}
                   message={edit ? <Lang id={'scheduleManager.popup.editScheduleValidationErrorMessage'}/> : <Lang id={'scheduleManager.popup.createScheduleValidationErrorMessage'}/>}
                   config={{
                     warning: {
                       icon: MenuIcon.AttentionIcon,
                       buttons: [
                         {
                          statusType: 'warning',
                          onClickSelector: handleSetPopup(false),
                          title: <Lang id={'scheduleManager.popup.accept'}/>,
                         },
                       ],
                     },
                   }}
                 />
               )}
             />
           )}
         </NavContainer>
       )
  }, [
    formValues,
    setFormValues,
    isPopupOpen,
    handleSetPopup,
    scheduleSelectedNode,
    requestCreateSchedule,
    requestEditSchedule,
    card,
    isTaskFormValid,
    openTreeSelectedNode,
    intl
  ])

  return (
    <Main>
      {globalLoading && (
        <GlobalPopup content={<Loader center />} />
      )}
      {formValues && (formValues[ACTION_TYPE] === CREATE || formValues[ACTION_TYPE] === PUT)
        ?
         <>
           {renderCreateEditScheduleForm()}
           {selectedDeviceType &&
             <CreateEditTaskForm
               ref={taskFormRef}
               setFormValues={setFormValues}
               formValues={formValues}
               selectedDeviceType={selectedDeviceType}
               initialValues={card?.tasks}
               edit={formValues[ACTION_TYPE] === PUT}
               setIsTaskFormValid={setIsTaskFormValid}
               intl={intl}
             />
           }
         </>
        : <Content>
            <PageSidebar
              title={<Lang id="scheduleManager.titles.main" />}
              treeId={'schedule-manager-tree'}
              requestGetRootElements={requestGetTree}
              scheduleContent={(
                <CreateScheduleMenu
                  setFormValues={setFormValues}
                  requestUploadSchedule={requestUploadSchedule}
                  isGeoZoneSelected={scheduleSelectedNode?.type === GEOZONE}
                />
              )}
              treeData={treeData}
              onSelect={onTreeNodeSelect}
              rootStatus={treeStatus}
              searchQuery={search}
              globalFilters={globalFilters}
              headerContent={(
                <Lang id="mapsPage.titles.search">
                  {(placeholder) => (
                    <TextControl
                      dark
                      placeholder={placeholder}
                      name="search"
                      icon={UsersIcons.MagnifierIcon}
                      onChange={handleSearchChange}
                      value={search}
                    />
                  )}
                </Lang>
              )}
            />
            <ScheduleContent
              formValues={formValues}
              setFormValues={setFormValues}
              isScheduleSelected={scheduleSelectedNode?.childType === SCHEDULE}
              requestGetCard={requestGetSchedule}
              id={scheduleSelectedNode?.id}
              selectedSchedule={card}
              requestDeleteSchedule={requestDeleteSchedule}
              scheduleSelectedNode={scheduleSelectedNode}

              failureTableData={failureTableData}
              loadingFailureTable={loadingFailureTable}
              failureDates={failureDates}
              errorStatus={errorStatus}
              requestGetFailureLogTable={requestGetFailureLogTable}
              setFailureLogDatesRange={setFailureLogDatesRange}
              setErrorStatus={setErrorStatus}

              activityTableData={activityTableData}
              loadingActivityTable={loadingActivityTable}
              activityDates={activityDates}
              action={action}
              requestGetActivityLogTable={requestGetActivityLogTable}
              setActivityLogDatesRange={setActivityLogDatesRange}
              setAction={setAction}
            />
      </Content>
      }
    </Main>
  )
}

ScheduleManager.propTypes = {
  data: pt.arrayOf(pt.object),
  loadingTable: pt.bool,
  globalLoading: pt.bool,
  loggedInUser: pt.object,
  treeData: pt.array,
  treeStatus: pt.string,
  card: pt.object,

  requestGetTree: pt.func,
  requestGetScheduleManagerTable: pt.func,
  requestUploadSchedule: pt.func,
  requestGetSchedule: pt.func,
  requestCreateSchedule: pt.func,
  requestDeleteSchedule: pt.func,
}

ScheduleManager.defaultProps = {
  data: {},
  loggedInUser: {},
  loadingTable: false,
  globalLoading: false,
  treeData: [],
  treeStatus: '',
  card: {},

  requestGetTree: noop,
  requestGetScheduleManagerTable: noop,
  requestUploadSchedule: noop,
  requestGetSchedule: noop,
  requestCreateSchedule: noop,
  requestDeleteSchedule: noop,
}

export default ScheduleManager