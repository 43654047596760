import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'
import Popup from '@/components/blocks/Popup'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${({ theme }) => theme.sizes.headerHeight}px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border-bottom: 1px solid ${theme.colors.borders.redisign.default};
        background: ${theme.colors.backgrounds.redisign.topDownGradient};
      `
    }
    return `
      border-bottom: 1px solid ${theme.colors.borders.default};
      background: ${theme.colors.backgrounds.header};
    `
  }}
  color: ${({ theme }) => theme.colors.colors.default};
  z-index: 10;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  > a > svg {
    width: 59px;
    max-height: 38px;
    &:first-child {
      margin-left: 10px;
    }
  }
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ControlsContainer = styled.div`
  display: flex;
  height: 100%;
`
export const StyledPopup = styled(Popup)`
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -10;
  transition: 0.4s;
  ${({ opened }) => opened && `
    top: calc(100% +15px);
  `}
`

export const Title = styled.h1`
font-size: 24px;
color: #1061DF;
padding-left: 10px;
`
export const WidgetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 5px 0 5px;
  position: relative;

  & > svg {
    cursor: pointer;
  }
`
