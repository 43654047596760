import {
    put, takeLatest, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateGetConnectedLinesCoordinates } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_CONNECTED_LINES_COORDINATES } from '@/store/actions/installation'
import {
    errorGetConnectedLinesCoordinates,
    successGetConnectedLinesCoordinates
} from '@/store/actions/installation/getConnectedLinesCoordinates'
import { getGlobalFilters } from '@/store/selectors/appSettings'

function* getConnectedLinesCoordinates({ payload: { id, parentId, objectStates } }) {
    try {
        const { asu , objectsTypes, integrations, processes, owners, exploitationOrganizations, ...statuses } = yield select(getGlobalFilters)
        const statusesTrue = Object.keys(statuses).filter((status) => statuses[status])
        const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
        const { data } = yield request({
            url: generateGetConnectedLinesCoordinates({
                params:{
                    id,
                    parentId,
                    integrationTypes: asu,
                    installationTypes: objectsTypes,
                    integrationIds: integrations,
                    cityProcessTypes: processes,
                    objectStates,
                    telemetryStatuses,
                    owners,
                    exploitationOrganizations
                }}),
            method: 'get',
        })
        const formattedData = data.content.map(item => {
            return [
                [item.currentPoint.latitude, item.currentPoint.longitude],
                [item.linkedPoint?.latitude, item.linkedPoint?.longitude]
            ]
        })
        yield put(successGetConnectedLinesCoordinates(formattedData))
    } catch (error) {
        yield put(errorGetConnectedLinesCoordinates({}))
        console.log('function*getConnectedLinesCoordinates -> error', error)
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить координаты линий связи \nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_CONNECTED_LINES_COORDINATES.REQUEST, getConnectedLinesCoordinates)
}