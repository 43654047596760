import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import get from 'lodash/get'
import { Form, Formik } from 'formik'
import {
  HeaderButton,
  Container,
  FieldsContainer,
  Header,
  HeaderTitle,
  IconContainer,
  ObjectsGroupsForm,
  FiltersContainer,
  TreeWrapper
} from './styles'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import CoreIcons from '@/components/icons/core'
import { createObjectsGroupValidator } from '@/constants/validationFields'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import objectsGroupConfig from '@/forms/ObjectsGroups/CreateEditObjectsGroupForm/config'
import CREATE_OBJECTS_GROUP_NAMES from '@/constants/forms/createObjectsGroup'
import { Error } from '@/components/controls/TextControl/styles'
import ColorField from '@/components/fields/ColorField'
import SelectControl from '@/components/controls/SelectControl'
import UsersIcons from '@/components/icons/users'
import TextControl from '@/components/controls/TextControl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import { FILTERS_DEFAULT_VALUES } from '@/containers/pages/ObjectsGroups/component'
import debounce from 'lodash/debounce'
import { DEBOUNCE_DELAY_LARGE } from '@/constants/time'

const CreateEditObjectsGroupForm = React.forwardRef(({
  selectedObjectsGroup,
  setFormValues,
  submit,
  submitError,
  title,
  onCancel,
  formValues,
  edit,
  intl,
  objectsGroupsFilterOptions,
  objectsGroupsFilterOptionsLoading,
  handleGetColor,
  handleFiltersChange,
  handleFiltersReset,
  filters,
  handleCheckNode,
  checkedNodes,
  createTreeData,
  createTreeStatus,
  searchTreeData,
  setNode,
  setFilters
}, ref) => {
  const [isCancelConfirmationPopupOpen, setIsCancelConfirmationPopupOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const onChangeSearch = useCallback(debounce((value) => {
    setSearchQuery(value)
  }, DEBOUNCE_DELAY_LARGE), [])

  const handleSearchChange = (value) => {
    setSearch(value)
    onChangeSearch(value)
  }

  const resetSearch = () => {
    handleSearchChange('')
  }

  const localInitialValues = useMemo(() => {
    const initialValues = objectsGroupConfig.reduce((accumulator, field) => {
    const fieldValue = get(selectedObjectsGroup, field.selector, '')
      return {
        ...accumulator,
        [field.selector]: fieldValue,
      }}, {})

    initialValues.id = selectedObjectsGroup.id
    initialValues.color = edit ? selectedObjectsGroup.color : '#b02020'

    return initialValues
  }, [selectedObjectsGroup, edit])

  const handleSetCancelPopup = useCallback((value) => () => {
    setIsCancelConfirmationPopupOpen(value)
  }, [])

  const errorHandler = (values, validateForm) => () => {
    submitError()
    validateForm(values)
  }

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const handleSubmit = (values) => ()=> {
    setNode({})
    setFilters(FILTERS_DEFAULT_VALUES)
    submit({
      groupId: edit ? selectedObjectsGroup.id : null,
      values,
      onCancel
    })
  }

    return (
      <Formik
        ref={ref}
        key={JSON.stringify(localInitialValues)}
        enableReinitialize
        initialValues={localInitialValues}
        validationSchema={createObjectsGroupValidator}
        validateOnMount
        validateOnChange
        isInitialValid={edit}
        render={({
          touched, errors, values, validateForm, setFieldValue, handleChange, setSubmitting, isValid, ...ownProps
        }) => {
          const onCheckNode = (node) => {
            handleCheckNode(node, true)
          }
          return (
            <ObjectsGroupsForm>
              <Form id="objectsGroupsForm" name="objectsGroupsForm">
                <Container>
                  <Header>
                    <HeaderTitle>
                      {title}
                    </HeaderTitle>
                    <>
                      <HeaderButton type="green" onClick={isValid ? handleSubmit(values) : errorHandler(values, validateForm)}>
                        <Lang id="installation.createGeoZoneForm.save" />
                      </HeaderButton>
                      <HeaderButton type="red" onClick={handleSetCancelPopup(true)}>
                        <Lang id="installation.createGeoZoneForm.cancel" />
                      </HeaderButton>
                    </>
                  </Header>
                  <FieldsContainer>
                    <LabelWithIcon
                      title={<Lang id={'objectsGroups.form.name'} />}
                    />
                    <TextField
                      error={(touched[CREATE_OBJECTS_GROUP_NAMES.NAME] && errors[CREATE_OBJECTS_GROUP_NAMES.NAME])}
                      name={CREATE_OBJECTS_GROUP_NAMES.NAME}
                      fieldProps={{
                        autoComplete: 'off',
                      }}
                      controls={(
                        <>
                          {(get(values, CREATE_OBJECTS_GROUP_NAMES.NAME, null)) &&
                            <PortalTooltip
                              title={(<Lang id="tooltip.erase" />)}
                              renderChildren={(
                                wrapperRef,
                                onMouseEnterHandler,
                                onMouseLeaveHandler,
                              ) => (
                                <IconContainer
                                  type="cross"
                                  onClick={setFieldToNull(CREATE_OBJECTS_GROUP_NAMES.NAME, setFieldValue)}
                                  ref={wrapperRef}
                                  onMouseEnter={onMouseEnterHandler}
                                  onMouseLeave={onMouseLeaveHandler}
                                >
                                 <CoreIcons.EraserIcon />
                                </IconContainer>
                              )}
                            />
                          }
                          </>
                      )}
                    />
                    <LabelWithIcon
                      title={<Lang id={`objectsGroups.form.color`} />}
                    />
                    <ColorField
                      error={(touched[CREATE_OBJECTS_GROUP_NAMES.COLOR] && errors[CREATE_OBJECTS_GROUP_NAMES.COLOR])}
                      onValueChange={handleGetColor}
                      name={CREATE_OBJECTS_GROUP_NAMES.COLOR}
                    />

                    <LabelWithIcon
                      title={<Lang id={`objectsGroups.form.objects`} />}
                    />
                    <TreeWrapper isError={!!errors[CREATE_OBJECTS_GROUP_NAMES.OBJECTS]}>
                      <PageSidebar
                        treeId={'objects-groups-form-tree'}
                        checkTree
                        onCheckNode={onCheckNode}
                        treeData={searchQuery ? searchTreeData: createTreeData}
                        rootStatus={createTreeStatus}
                        searchQuery={searchQuery}
                        withoutTitleRow
                        headerContent={(
                          <Lang id="mapsPage.titles.search">
                            {(placeholder) => (
                              <TextControl
                                dark
                                placeholder={placeholder}
                                name="search"
                                icon={UsersIcons.MagnifierIcon}
                                onChange={handleSearchChange}
                                value={search}
                                resetButton
                                resetField={resetSearch}
                              />
                            )}
                          </Lang>
                        )}
                      />
                    </TreeWrapper>
                    {errors[CREATE_OBJECTS_GROUP_NAMES.OBJECTS] &&
                      <Error>
                        <Lang id={errors[CREATE_OBJECTS_GROUP_NAMES.OBJECTS]} />
                      </Error>
                    }
                  </FieldsContainer>
                </Container>
              </Form>
              <FiltersContainer>
                <Header>
                  <HeaderTitle>
                    <Lang id="objectsGroups.titles.filters" />
                  </HeaderTitle>
                  <>
                    <HeaderButton onClick={handleFiltersReset}>
                      <Lang id="objectsGroups.form.reset" />
                    </HeaderButton>
                  </>
                </Header>
                <FieldsContainer>
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.street`} />}
                  />
                  <SelectControl
                    value={filters.street}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.STREET)}
                    options={objectsGroupsFilterOptions.street || []}
                    placeholder={<Lang id={`objectsGroups.filters.streetPlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.registryNumber`} />}
                  />
                  <SelectControl
                    value={filters.registryNumber}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.REGISTRY_NUMBER)}
                    options={objectsGroupsFilterOptions.registryNumber || []}
                    placeholder={<Lang id={`objectsGroups.filters.registryNumberPlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.objectType`} />}
                  />
                  <SelectControl
                    value={filters.objectType}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.OBJECT_TYPE)}
                    options={objectsGroupsFilterOptions.objectType || []}
                    placeholder={<Lang id={`objectsGroups.filters.objectTypePlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.vols`} />}
                  />
                  <SelectControl
                    value={filters.vols}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.VOLS)}
                    options={objectsGroupsFilterOptions.vols || []}
                    placeholder={<Lang id={`objectsGroups.filters.volsPlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.utilityPoleType`} />}
                  />
                  <SelectControl
                    value={filters.utilityPoleType}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.UTILITY_POLE_TYPE)}
                    options={objectsGroupsFilterOptions.utilityPoleType || []}
                    placeholder={<Lang id={`objectsGroups.filters.utilityPoleTypePlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.lightFixtureType`} />}
                  />
                  <SelectControl
                    value={filters.lightFixtureType}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.LIGHT_FIXTURE_TYPE)}
                    options={objectsGroupsFilterOptions.lightFixtureType || []}
                    placeholder={<Lang id={`objectsGroups.filters.lightFixtureTypePlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.pedestrianCrossingSign`} />}
                  />
                  <SelectControl
                    value={filters.pedestrianCrossingSign}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.PEDESTRIAN_CROSSING_SIGN)}
                    options={objectsGroupsFilterOptions.pedestrianCrossingSign || []}
                    placeholder={<Lang id={`objectsGroups.filters.pedestrianCrossingSignPlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.controlCupboard`} />}
                  />
                  <SelectControl
                    value={filters.controlCupboard}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.CONTROL_CUPBOARD)}
                    options={objectsGroupsFilterOptions.controlCupboard || []}
                    placeholder={<Lang id={`objectsGroups.filters.controlCupboardPlaceholder`} />}
                    withSearch
                    multiselect
                  />
                  <LabelWithIcon
                    title={<Lang id={`objectsGroups.filters.networkCompany`} />}
                  />
                  <SelectControl
                    value={filters.networkCompany}
                    onChange={(value) => handleFiltersChange(value, CREATE_OBJECTS_GROUP_NAMES.NETWORK_COMPANY)}
                    options={objectsGroupsFilterOptions.networkCompany || []}
                    placeholder={<Lang id={`objectsGroups.filters.networkCompanyPlaceholder`} />}
                    withSearch
                    multiselect
                  />
                </FieldsContainer>
              </FiltersContainer>
              {isCancelConfirmationPopupOpen && (
                <GlobalPopup
                  content={(
                    <GlobalPopupContent
                      type={'error'}
                      onClose={handleSetCancelPopup(false)}
                      title={<Lang id={'objectsGroups.popup.attention'}/>}
                      message={edit ? <Lang id={'objectsGroups.popup.cancelEditObjectsGroupMessage'}/> : <Lang id={'objectsGroups.popup.cancelCreateObjectsGroupMessage'}/>}
                      config={{
                        error: {
                          icon: MenuIcon.TreshIcon,
                          buttons: [
                            {
                             statusType: 'flat',
                             onClickSelector: handleSetCancelPopup(false),
                             title: <Lang id="objectsGroups.popup.no" />,
                            },
                            {
                             statusType: 'error',
                             onClickSelector: onCancel,
                             title: <Lang id="objectsGroups.popup.yes" />,
                            },
                          ],
                        },
                      }}
                    />
                  )}
                />
              )}
            </ObjectsGroupsForm>
          )
        }}
      />
    )
})

CreateEditObjectsGroupForm.defaultProps = {
  selectedObjectsGroup: {},
  setFormValues: noop,
  submit: noop,
  submitError: noop,
  onCancel: noop,
  title: false,
  formValues: {},
  edit: false,
}
CreateEditObjectsGroupForm.propTypes = {
  selectedObjectsGroup: pt.objectOf(pt.object),
  setFormValues: pt.func,
  submit: pt.func,
  submitError: pt.func,
  onCancel: pt.func,
  title: pt.string,
  formValues: pt.objectOf(pt.string),
  edit: pt.bool,
}

export default CreateEditObjectsGroupForm