import {
    takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { INSTALLATION_ELEMENT_CHILDREN } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_SEARCH_NODE } from '@/store/actions/search'
import {
    setGlobalLoading,
    setSelectedNode
} from '@/store/actions/appSettings'

function* getSearchNodeSaga({ payload: { id, parentId }}) {
    try {
        yield put(setGlobalLoading(true))
        const response = yield request({
            url: INSTALLATION_ELEMENT_CHILDREN({
                params: {
                   parentId
                },
            }),
            method: 'get',
        })

        const node = response.data.filter(item => item.id === id)[0]

        const updatedNode = {
            ...node,
            original: {
                options: {
                    path: `/${node.path.join('/')}/${node.id}`
                }
            },
        }
        yield put(setGlobalLoading(false))
        yield put(setSelectedNode(updatedNode))

    } catch (error) {
        yield put(setGlobalLoading(false))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные искомого объекта.\nПовторите попытку позже.',
            type: 'error',
        })
    }
    return null
}

export default function* root() {
    yield takeLatest(GET_SEARCH_NODE.REQUEST, getSearchNodeSaga)
}