import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
        showInDashboard: true,
        selector: 'name',
    },
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
        showInDashboard: true,
        selector: 'objectIdOuter',
    },
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
        showInDashboard: true,
        selector: 'MODEL_MARK',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
        showInDashboard: true,
        selector: 'REGISTRY_NUMBER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
        showInDashboard: true,
        selector: 'PROCEDURE_SUPPLIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.location.latitude" />,
        showInDashboard: true,
        selector: 'latitude',
    },
    {
        passportParameter: <Lang id="passportization.fields.location.longitude" />,
        showInDashboard: true,
        selector: 'longitude',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'LIGHTING_CONTROL_BOX_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PARENT_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
        showInDashboard: true,
        selector: 'PARENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
        showInDashboard: true,
        selector: 'WORKING_TEMPERATURE_RANGE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
        showInDashboard: true,
        selector: 'POWER_CONSUMPTION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.CALIBRATION_INTERVAL" />,
        showInDashboard: true,
        selector: 'CALIBRATION_INTERVAL',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
        showInDashboard: true,
        selector: 'START_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
        showInDashboard: true,
        selector: 'END_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
        showInDashboard: false,
        selector: 'ORGANIZATION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
        showInDashboard: false,
        selector: 'OWNER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
        showInDashboard: true,
        selector: 'GUARANTEE_PERIOD',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
        showInDashboard: true,
        selector: 'LIFE_TIME',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_ONE" />,
        showInDashboard: true,
        selector: 'TARIFF_ONE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_TWO" />,
        showInDashboard: true,
        selector: 'TARIFF_TWO',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_THREE" />,
        showInDashboard: true,
        selector: 'TARIFF_THREE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_FOUR" />,
        showInDashboard: true,
        selector: 'TARIFF_FOUR',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.CHECKING_DATE" />,
        showInDashboard: true,
        selector: 'CHECKING_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
        showInDashboard: true,
        selector: 'BYPASS_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
        showInDashboard: true,
        selector: 'MAINTENANCE_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
        showInDashboard: true,
        selector: 'REPAIR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
        showInDashboard: true,
        selector: 'GUARANTEE_EXPIRATION_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.inner.photo" />,
        showInDashboard: true,
        selector: 'photo',
    },
    {
        passportParameter: <Lang id="passportization.fields.inner.file" />,
        showInDashboard: false,
        selector: 'DOCUMENT_TITLE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.plan.date" />,
        showInDashboard: true,
        selector: 'factDate',
      },
      {
        passportParameter: <Lang id="passportization.fields.fact.date" />,
        showInDashboard: true,
        selector: 'planDate',
      },
]
