import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import {
  GET_LIVE_TIME_REPORT_FILE,
} from '@/store/actions/lifeTimeReport'
import { generateLiveTimeReportFileUrl } from '@/constants/apiRoutes'
import {
  successLiveTimeReportFile,
  errorLiveTimeReportFile,
} from '@/store/actions/lifeTimeReport/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getGraphType,
  getTableParameters,
  getSelectedGraphPercentOptions,
  getSelectedGraphYearOptions,
} from '@/store/selectors/lifeTimeReport'
import { GRAPH_PERCENT } from '@/components/charts/Graph/config';
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'

export const EXTENSION_BY_TYPE = {
  pdf: 'pdf',
  excel: 'xlsx',
}

function* getLiveTimeReportFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const type = yield select(getGraphType)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const selectedGraphPercentOptions = yield select(getSelectedGraphPercentOptions)
    const selectedGraphYearOptions = yield select(getSelectedGraphYearOptions)

    const response = yield request({
      url: generateLiveTimeReportFileUrl({
        params: {
          excludeColumns: payload.fileType.allColumns.filter(x => !tableParameters.activeFields.includes(x)),
          parentId: node.id,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          search: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          type: type === GRAPH_PERCENT ? 'PERCENT' : 'YEAR',
          options: type === GRAPH_PERCENT
              ? selectedGraphPercentOptions
              : selectedGraphYearOptions,
        },
        format: EXTENSION_BY_TYPE[payload.fileType.value]
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${node.id || 'all'}_${type === GRAPH_PERCENT ? 'PERCENT' : 'YEAR'}_report.${EXTENSION_BY_TYPE[payload.fileType.value]}`,
    })

    yield put(successLiveTimeReportFile())
  } catch (error) {
    yield put(errorLiveTimeReportFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_LIVE_TIME_REPORT_FILE.REQUEST, getLiveTimeReportFileSaga)
}
