import styled from 'styled-components'

export const StyledTable = styled.table`
  width: 100%;
  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth}px;
  `}
`
export const TableHeadWrapper = styled.div`
  width: 100%;
`

export const TableBodyWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
`

export const StyledHeadRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
`

export const StyledHeadCell = styled.th`
  ${({ customWidth }) => customWidth && `
    min-width: ${customWidth}px;
    width: ${customWidth}px;

    & > div {
      width: ${customWidth}px;
    }
  `}
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const StyledCell = styled.td`
  padding: 0px 5px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ wrap }) => wrap && 'white-space: pre-wrap'};
  ${({ width }) => width && `width: ${width}px`};
  ${({ type }) => {
    if (type === 'string') {
      return `
        text-align: left;
        padding-left: 15px;
      `
    }
    return 'text-align: center;'
  }};
`

export const StyledRow = styled.tr`
  height: 38px;
  cursor: disabled;
  ${({ isSelectable }) => isSelectable && 'cursor: pointer'};
`

export const LoaderContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: calc(100% - 49px);
`
