import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SET_SCHEDULE_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { SET_SCHEDULE } from '@/store/actions/objectsGroups'
import {
  errorSetSchedule,
  successSetSchedule
} from '@/store/actions/objectsGroups/setSchedule'

function* setSchedule({ payload }) {
  const toast = createNotifications()
  try {
    const { groupId, scheduleId } = payload
    const body = {
      groupId,
      scheduleId
    }
    const { data } = yield request({
      url: SET_SCHEDULE_URL,
      method: 'patch',
      body
    })
    if (data) {
      yield put(successSetSchedule())
      toast({
        title: 'Установка расписания',
        type: 'success',
        description: 'Расписание успешно установлено.',
      })
    }
  } catch (error) {
    yield put(errorSetSchedule(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось установить расписание. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(SET_SCHEDULE.REQUEST, setSchedule)
}
