import React, {
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { Formik } from 'formik'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import hashSum from 'hash-sum'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import SelectField from '@/components/fields/SelectField'
import useInstallationParams from '@/hooks/useInstallationParams'
import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'
import {
  FILTER,
  CREATE,
  DOWNLOAD_INSTALLATION_FILE,
  CREATE_TYPE,
  ACTION_TYPE,
} from '@/constants/semanticNames'
import {
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import {
  filterOptions,
  createOptions,
} from './config'
import {
  FormContainer,
  ContentContainer,
  IconContainer,
  HiddenInputContainer, FilterContentWrapper, DownloadIconContainer,
} from './styles'

const InstallationForm = ({
  setFormValues,
  parentCreateError,
  requestDownloadMultipleObject,
  isCreateBlock,
  requestDownloadMultipleObjectScheme,
  alertConfig,
  userRoles,
  parentId,
  requestDownloadMultipleObjectProgress,
  intl
}) => {
  const formicForm = useRef(null)
  const hiddenInput = useRef(null)

  const [urlValues] = useInstallationParams()

  const requestDownloadMultipleObjectHandler = (event) => {
    const files = get(event, 'target.files', [])
    if (files.length !== 0) {
      requestDownloadMultipleObject({
        file: files[0],
        id: urlValues.id,
      })
      requestDownloadMultipleObjectProgress({id: urlValues.id})
    }
    event.target.value = null
  }

  const parsedInitialValues = useMemo(() => ({
    [FILTER]: typeof urlValues[FILTER] === 'string' ? [urlValues[FILTER]] : urlValues[FILTER],
    formId: hashSum(alertConfig),
  }), [urlValues, alertConfig])

  const handleSetValues = useCallback((formValues) => {
    if (formValues[CREATE] === GEOZONE && urlValues.parentTreeId) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (formValues[CREATE] === PROJECT && (urlValues.type !== GEOZONE || urlValues.rootId === urlValues.id)) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (formValues[CREATE] === INTEGRATION && urlValues.type !== PROJECT) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (
      formValues[CREATE] === OBJECT_ELEMENT
      && !(urlValues.type === INTEGRATION || urlValues.type === OBJECT_ELEMENT)
    ) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (
      formValues[CREATE] === DOWNLOAD_INSTALLATION_FILE
      && (urlValues.type !== INTEGRATION && urlValues.type !== OBJECT_ELEMENT)
    ) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (formValues[CREATE] === DOWNLOAD_INSTALLATION_FILE) {
      hiddenInput.current.click()
      formValues[CREATE] = null
      return null
    }

    const newUrlValues = {
      ...urlValues,
      [FILTER]: formValues[FILTER],
      ...formValues[CREATE]
        ? {
          [ACTION_TYPE]: CREATE,
          [CREATE_TYPE]: formValues[CREATE],
        }
        : {},
    }
    if (!isEqual(newUrlValues, urlValues)) {
      setFormValues({
        ...newUrlValues,
        createMode: newUrlValues[ACTION_TYPE] === CREATE
      })
    }
  }, [setFormValues, urlValues, hiddenInput, parentCreateError])

  const onSubmit = useCallback(() => {}, [])

  createOptions.forEach(item => {
    if (item.value === GEOZONE) {
      item.disabled = !userRoles.isSuperAdmin && !parentId
    }
  })

  return (
    <FormContainer>
      <Formik
        ref={formicForm}
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={parsedInitialValues}
        validate={handleSetValues}
        render={({ handleSubmit }) => (
          <>
            <HiddenInputContainer
              type="file"
              accept=".xlsx, .xls"
              ref={hiddenInput}
              onChange={requestDownloadMultipleObjectHandler}
            />
            <ContentContainer>
              <SelectField
                largeOptions
                doNotDisplayValue
                name={CREATE}
                placeholder={intl.messages['installation.add']}
                options={createOptions}
                selectIcon={<UsersIcons.PlusIcon />}
                disabled={isCreateBlock}
              />
            </ContentContainer>
            <ContentContainer>
              <SelectField
                multiselect
                largeOptions
                name={FILTER}
                placeholder={intl.messages['installation.filterObjects']}
                options={filterOptions}
                selectAll={{
                  value: 'ALL',
                  color: STATUS_COLORS.NONE,
                  title: intl.messages['installation.allObjects'],
                }}
              />
            </ContentContainer>
          </>
        )}
      />
      <ContentContainer file gray>
        <FilterContentWrapper
          onClick={requestDownloadMultipleObjectScheme}
          lowerCasePlaceholder
          disableDefaultColor
        >
          <Lang id={"installation.downloadFile"}/>
          <DownloadIconContainer>
            <PortalTooltip
                title={<Lang id={"installation.downloadFile"}/>}
                renderChildren={(
                    wrapperRef,
                    onMouseEnterHandler,
                    onMouseLeaveHandler,
                ) => (
                    <IconContainer
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                    >
                      <UsersIcons.UploadIcon />
                    </IconContainer>
                )}
            />
          </DownloadIconContainer>
        </FilterContentWrapper>
      </ContentContainer>
    </FormContainer>
  )
}

InstallationForm.propTypes = {
  setFormValues: pt.func,
  parentCreateError: pt.func,
  requestDownloadMultipleObject: pt.func,
  requestDownloadMultipleObjectScheme: pt.func,
  isCreateBlock: pt.bool,
}

InstallationForm.defaultProps = {
  setFormValues: noop,
  parentCreateError: noop,
  requestDownloadMultipleObject: noop,
  requestDownloadMultipleObjectScheme: noop,
  isCreateBlock: false,
}

export default React.memo(InstallationForm)
