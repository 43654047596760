import {
    TEXT,
    PHOTO,
    FILE,
} from '@/constants/semanticNames'

export default [
    {
        selector: 'GENERAL_INFORMATION.NAME',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
    },
    {
        selector: 'GENERAL_INFORMATION.IDENTIFIER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.EQUIPMENT_TYPE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.DEVICE_SERIAL_NUMBER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.METER_SN',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.MODEL_MARK',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
    },
    {
        selector: 'GENERAL_INFORMATION.PROCEDURE_SUPPLIER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
    },
    {
        selector: 'GENERAL_INFORMATION.CONN_TYPE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.SWVER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.ICCID',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.ICCID1',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.ICCID2',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
    {
        selector: 'GENERAL_INFORMATION.TIME_ZONE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
    },
    {
        selector: 'GENERAL_INFORMATION.REGISTRY_NUMBER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM ',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
        format: 'range',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
        format: 'temperatureRange',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
        format: 'temperatureRange',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
        format: 'range',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
    },
    {
        selector: 'TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'TECHNICAL_CONSTRAINTS',
        format: 'temperatureRange',
    },
    {
        selector: 'EXPLOITATION.START_DATE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'EXPLOITATION',
        mask: '99.99.9999',
    },
    {
        selector: 'EXPLOITATION.EXPLOITATION_END_DATE',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'EXPLOITATION',
        mask: '99.99.9999',
    },
    {
        selector: 'EXPLOITATION.ORGANIZATION',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'EXPLOITATION',
    },
    {
        selector: 'EXPLOITATION.OWNER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'EXPLOITATION',
    },
    {
        selector: 'EXPLOITATION.GUARANTEE_PERIOD',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'EXPLOITATION',
    },
    {
        selector: 'EXPLOITATION.LIFE_TIME',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'EXPLOITATION',
    },
    {
        selector: 'ATTACHMENT.PHOTO_TITLE',
        title: 'someTitle',
        type: PHOTO,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'ATTACHMENT',
    },
    {
        selector: 'ATTACHMENT.DOCUMENT_TITLE',
        title: 'someTitle',
        type: FILE,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'ATTACHMENT',
    },
]
