import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
// import DefaultImage from '@/components/blocks/DefaultImage'
import CkeckBox from '@/components/controls/CheckBox'
import { defaultImage } from '@/assets/images/objectElement'

import {
  CARD,
  PASSPORT,
} from '@/constants/passportization'
import {
  TabContainer,
  PassportImageContainer,
  PassportView,
  TitleContainer,
  CkeckBoxContainer,
  OptionTitle,
  CkeckBoxsContainer,
} from './styles'

const PassportImage = ({
  element,
  uploadedImage,
  photoUrl,
  setTabType,
  tabType,
  selectedNode,
}) => {
  return (
    <>
      <TabContainer>
        <TitleContainer>
          <Lang id="passportization.passportSidebar.view" />
        </TitleContainer>
        <CkeckBoxsContainer>
          <CkeckBoxContainer onClick={setTabType(PASSPORT)}>
            <CkeckBox
              value={tabType === PASSPORT}
            />
            <OptionTitle>
              <Lang id="passportization.passportSidebar.passport" />
            </OptionTitle>
          </CkeckBoxContainer>
          <CkeckBoxContainer onClick={setTabType(CARD)}>
            <CkeckBox
              value={tabType === CARD}
            />
            <OptionTitle>
              <Lang id="passportization.passportSidebar.card" />
            </OptionTitle>
          </CkeckBoxContainer>
        </CkeckBoxsContainer>
      </TabContainer>
      <PassportImageContainer>
      <PassportView url={element.url || photoUrl || defaultImage[selectedNode?.installationType || selectedNode?.objectType]} base64Url={uploadedImage} />
        {/* {element.url || uploadedImage || photoUrl
          ? (<PassportView url={element.url || photoUrl} base64Url={uploadedImage} />)
          : (<DefaultImage title={(<Lang id="passportization.passportSidebar.noImage" />)} />)} */}
      </PassportImageContainer>
    </>
  )
}

PassportImage.defaultProps = {
  element: {},
  uploadedImage: null,
  photoUrl: '',
  setTabType: noop,
  tabType: PASSPORT,
}

PassportImage.propTypes = {
  element: pt.shape({
    url: pt.string,
  }),
  uploadedImage: pt.object,
  photoUrl: pt.string,
  setTabType: pt.func,
  tabType: pt.oneOf([CARD, PASSPORT]),
}

export default React.memo(PassportImage)
