import AriaIcon from './AriaIcon'
import BackIcon from './BackIcon'
import CheckIcon from './CheckIcon'
import CheckedIcon from './CheckedIcon'
import ConstructionIcon from './ConstructionIcon'
import ControllerIcon from './ControllerIcon'
import CrossIcon from './CrossIcon'
import FilterIcon from './FilterIcon'
import GarbageIcon from './GarbageIcon'
import GeoIcon from './GeoIcon'
import GeoMarkIcon from './GeoMarkIcon'
import LightIcon from './LightIcon'
import LightBulbIcon from './LightBulbIcon'
import ListIcon from './ListIcon'
import MeshIcon from './MeshIcon'
import MinusIcon from './MinusIcon'
import PinIcon from './PinIcon'
import PinGreenIcon from './PinGreenIcon'
import PinRedIcon from './PinRedIcon'
import PinYellowIcon from './PinYellowIcon'
import PinhoverIcon from './PinhoverIcon'
import Placemark1Icon from './Placemark1Icon'
import Placemark2Icon from './Placemark2Icon'
import SwitchIcon from './SwitchIcon'
import TrafficLightIcon from './TrafficLightIcon'
import TrashpinIcon from './TrashpinIcon'
import UnionIcon from './UnionIcon'
import ZoomIcon from './ZoomIcon'
import ArrowIcon from './ArrowIcon'
import ZoneFolderIcon from './ZoneFolderIcon'
import CountrieFolderIcon from './CountrieFolderIcon'
import ConstrollIcon from './ConstrollIcon'
import WarningIcon from './WarningIcon'
import GraphicIcon from './GraphicIcon'
import SettingsIcon from './SettingsIcon'
import CircleChartIcon from './CircleChartIcon'
import DotsIcon from './DotsIcon'
import WaterIcon from './WaterIcon'
import HeatIcon from './HeatIcon'
import InformationIcon from './InformationIcon'
import ToogleIcon from './ToogleIcon'
import ClusterIcon from './ClusterIcon'
import GeoZoneIcon from './GeoZoneIcon'
import ReverseCrossIcon from './ReverseCrossIcon'
import ReverseExclamationIcon from './ReverseExclamationIcon'
import ReverseOkIcon from './ReverseOkIcon'
import ReverseQuestionIcon from './ReverseQuestionIcon'
import SencorIcon from './SencorIcon'
import InvertGeoZoneIcon from './InvertGeoZoneIcon'
import InvertProjectIcon from './InvertProjectIcon'
import InvertMarkIcon from './InvertMarkIcon'
import TypeOfWasteIcon from './TypeOfWasteIcon'
import InvertPlusIcon from './InvertPlusIcon'
import DeleteIcon from './DeleteIcon'
import LightLevelIcon from './LightLevelIcon'
import VoltageIcon from './VoltageIcon'
import ContractorIcon from './ContractorIcon'
import LEPIcon from './LEPIcon'
import PoleIcon from './PoleIcon'
import ControllerChipIcon from './ControllerChipIcon'
import GroupObjectIcon from './GroupObjectIcon'
import CheckIconHover from './CheckIconHover'

export default {
  AriaIcon,
  BackIcon,
  CheckIcon,
  CheckedIcon,
  ConstructionIcon,
  ControllerIcon,
  CrossIcon,
  FilterIcon,
  GarbageIcon,
  GeoIcon,
  GeoMarkIcon,
  LightIcon,
  LightBulbIcon,
  ListIcon,
  MeshIcon,
  MinusIcon,
  PinIcon,
  PinGreenIcon,
  PinRedIcon,
  PinYellowIcon,
  PinhoverIcon,
  Placemark1Icon,
  Placemark2Icon,
  SwitchIcon,
  TrafficLightIcon,
  TrashpinIcon,
  UnionIcon,
  ZoomIcon,
  ArrowIcon,
  ZoneFolderIcon,
  CountrieFolderIcon,
  ConstrollIcon,
  WarningIcon,
  GraphicIcon,
  SettingsIcon,
  CircleChartIcon,
  DotsIcon,
  WaterIcon,
  HeatIcon,
  InformationIcon,
  ToogleIcon,
  ClusterIcon,
  GeoZoneIcon,
  ReverseCrossIcon,
  ReverseExclamationIcon,
  ReverseOkIcon,
  ReverseQuestionIcon,
  SencorIcon,
  InvertGeoZoneIcon,
  InvertProjectIcon,
  InvertMarkIcon,
  TypeOfWasteIcon,
  InvertPlusIcon,
  DeleteIcon,
  LightLevelIcon,
  VoltageIcon,
  ContractorIcon,
  LEPIcon,
  PoleIcon,
  ControllerChipIcon,
  GroupObjectIcon,
  CheckIconHover
}
