import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
        showInDashboard: true,
        selector: 'name',
    },
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
        showInDashboard: true,
        selector: 'objectIdOuter',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
        showInDashboard: true,
        selector: 'ROOT_OBJECT',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'UTILITY_POLE_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'LIGHTING_CONTROL_BOX_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PARENT_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PREVIOUS_LINKED_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PREVIOUS_LINKED_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
        showInDashboard: true,
        selector: 'PARENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.location.latitude" />,
        showInDashboard: true,
        selector: 'latitude',
    },
    {
        passportParameter: <Lang id="passportization.fields.location.longitude" />,
        showInDashboard: true,
        selector: 'longitude',
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
        showInDashboard: true,
        selector: 'START_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
        showInDashboard: true,
        selector: 'END_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
        showInDashboard: true,
        selector: 'LIFE_TIME',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
        showInDashboard: true,
        selector: 'REGION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
        showInDashboard: true,
        selector: 'CITY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.plan.date" />,
        showInDashboard: true,
        selector: 'factDate',
      },
      {
        passportParameter: <Lang id="passportization.fields.fact.date" />,
        showInDashboard: true,
        selector: 'planDate',
      },
]
