import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_OBJECTS_GROUPS_FILTER_OPTIONS_URL } from '@/constants/apiRoutes'
import { GET_OBJECTS_GROUPS_FILTER_OPTIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'
import {
  errorGetObjectsGroupsFilterOptions,
  successGetObjectsGroupsFilterOptions
} from '@/store/actions/dictionaries/objectsGroupsFilterOptions'

function* getDictionary() {
  try {
    const response = yield request({
      url: GET_OBJECTS_GROUPS_FILTER_OPTIONS_URL,
      method: 'get',
    })
    if (response && response.data) {
      const streets = response.data.STREET.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedStreets = sortOptionsByTitle(streets)

      const registryNumbers = response.data.REGISTRY_NUMBER.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedRegistryNumbers = sortOptionsByTitle(registryNumbers)

      const objectTypes = response.data.EQUIPMENT_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedObjectTypes = sortOptionsByTitle(objectTypes)

      const volses = response.data.VOLS.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedVolses = sortOptionsByTitle(volses)

      const utilityPoleTypes = response.data.UTILITY_POLE_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedUtilityPoleTypes = sortOptionsByTitle(utilityPoleTypes)

      const lightFixtureTypes = response.data.LAMP_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedLightFixtureTypes = sortOptionsByTitle(lightFixtureTypes)

      const pedestrianCrossingSigns = response.data.LOCATION_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedPedestrianCrossingSigns = sortOptionsByTitle(pedestrianCrossingSigns)

      const controlCupboards = response.data.SHUO.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedControlCupboards = sortOptionsByTitle(controlCupboards)

      const networkCompanies = response.data.NETWORK_COMPANY.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedNetworkCompanies = sortOptionsByTitle(networkCompanies)

      const formattedData = {
        street: sortedStreets,
        registryNumber: sortedRegistryNumbers,
        objectType: sortedObjectTypes,
        vols: sortedVolses,
        utilityPoleType: sortedUtilityPoleTypes,
        lightFixtureType: sortedLightFixtureTypes,
        pedestrianCrossingSign: sortedPedestrianCrossingSigns,
        controlCupboard: sortedControlCupboards,
        networkCompany: sortedNetworkCompanies,
      }
      yield put(successGetObjectsGroupsFilterOptions(formattedData))
    }
  } catch (error) {
    yield put(errorGetObjectsGroupsFilterOptions(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные для формы фильтров.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_OBJECTS_GROUPS_FILTER_OPTIONS.REQUEST, getDictionary)
}