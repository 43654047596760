import React, { useMemo } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CheckBox from '@/components/controls/CheckBox'

import {
  Container,
  CheckBoxAndLabelContainer,
  Label,
  MainTitle,
} from './styles'

const FieldsSettings = ({
  passportConfig,
  cardConfig,
  setSelectedFields,
  selectedFields,
}) => {
  const formatFieldOptions = useMemo(() => {
    const newOptions = passportConfig.concat(cardConfig)
    return newOptions
  }, [cardConfig, passportConfig])

  const onCheckBoxChangeHandler = (selector) => () => {
    if (selectedFields.includes(selector)) {
      const filteredArray = selectedFields.filter((element) => element !== selector)
      setSelectedFields(filteredArray)
    } else {
      const newArray = [...selectedFields]
      newArray.push(selector)
      setSelectedFields(newArray)
    }
    return null
  }

  const isAllSelected = useMemo(() => {
    const count = passportConfig.length + cardConfig.length
    return count === selectedFields.length
  },
  [
    cardConfig.length,
    passportConfig.length,
    selectedFields.length,
  ])

  const ToggleSelectAll = () => {
    const count = passportConfig.length + cardConfig.length
    if (count === selectedFields.length) {
      setSelectedFields([])
      return null
    }
    const options = passportConfig.concat(cardConfig)
    const selectAllArray = options.map((element) => element.selector)
    setSelectedFields(selectAllArray)
    return null
  }

  return (
    <Container>
      <MainTitle>
        <Lang id="passportization.passportSidebar.fieldSettings" />
      </MainTitle>
      {formatFieldOptions.length > 0 && (
        <CheckBoxAndLabelContainer>
          <CheckBox
            key="select-all"
            value={isAllSelected}
            onChange={ToggleSelectAll}
          />
          <Label>
            <Lang id="passportization.passportSidebar.discardAll" />
          </Label>
        </CheckBoxAndLabelContainer>
      )}
      {formatFieldOptions.length > 0 && formatFieldOptions.map(
        (element) => {
          const { value, selector, catalogSelector } = element
          return (
            <CheckBoxAndLabelContainer>
              <CheckBox
                key={`${catalogSelector}-${selector}-${value}`}
                value={selectedFields.includes(selector)}
                onChange={onCheckBoxChangeHandler(selector)}
              />
              <Label>
                <Lang id={`passport.fields.${selector}`} />
              </Label>
            </CheckBoxAndLabelContainer>
          )
        },
      )}
    </Container>
  )
}

FieldsSettings.propTypes = {
  passportConfig: pt.arrayOf(pt.object),
  cardConfig: pt.arrayOf(pt.object),
  selectedFields: pt.arrayOf(pt.string),
  setSelectedFields: pt.func,
}

FieldsSettings.defaultProps = {
  passportConfig: [],
  cardConfig: [],
  selectedFields: [],
  setSelectedFields: noop,
}
export default FieldsSettings
