import {
  TEXT
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.LATITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.LONGITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.REGION',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.CITY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.STREET',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.HOME_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },


  {
    selector: 'OBJECT_CHARACTERISTIC.LOCATION_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.PREVIOUS_LINKED_IDENTIFIER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },


  {
    selector: 'SURVEY_DATA.CITY_NUMERIC',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.UTILITY_POLE_KIND',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.STATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.POWER_SUPPLY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.POWER_SUPPLY_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.VOLS',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },

  {
    selector: 'SERVICING.PLAN_PPR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
    mask: '99.99.9999',
  },
  {
    selector: 'SERVICING.FACT_PPR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
    mask: '99.99.9999',
  },
  {
    selector: 'SERVICING.MODERNIZATION',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
]
