import {
    TEXT,
    FILE,
    PHOTO,
  } from '@/constants/semanticNames'
  
  export default [
    {
      selector: 'GENERAL_INFORMATION.NAME',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
    {
      selector: 'GENERAL_INFORMATION.IDENTIFIER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
      immutable: true,
    },
    {
      selector: 'GENERAL_INFORMATION.EQUIPMENT_TYPE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
      immutable: true,
    },
    {
      selector: 'GENERAL_INFORMATION.MODEL',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
    {
      selector: 'GENERAL_INFORMATION.PRODUCER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
    {
      selector: 'GENERAL_INFORMATION.SUPPLIER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
    {
      selector: 'GENERAL_INFORMATION.ROOT_OBJECT',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
      immutable: true,
    },
    {
      selector: 'GENERAL_INFORMATION.PARENT_TYPE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
    {
      selector: 'GENERAL_INFORMATION.PARENT_IDENTIFIER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
    {
      selector: 'GENERAL_INFORMATION.REGISTRY_NUMBER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'GENERAL_INFORMATION',
    },
  
  
    {
      selector: 'TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'TECHNICAL_CONSTRAINTS',
      format: 'temperatureRange',
    },
    {
      selector: 'TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'TECHNICAL_CONSTRAINTS',
    },
    {
      selector: 'TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'TECHNICAL_CONSTRAINTS',
    },
  
  
    {
      selector: 'EXPLOITATION.START_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
      mask: '99.99.9999',
    },
    {
      selector: 'EXPLOITATION.EXPLOITATION_END_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
      mask: '99.99.9999',
    },
    {
      selector: 'EXPLOITATION.ORGANIZATION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
    {
      selector: 'EXPLOITATION.OWNER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
    {
      selector: 'EXPLOITATION.GUARANTEE_PERIOD',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
    {
      selector: 'EXPLOITATION.LIFE_TIME',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
    {
      selector: 'EXPLOITATION.BALANCE_SHEET_HOLDER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
    {
      selector: 'EXPLOITATION.PRODUCER_WARRANTY_EXPIRATION_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
    {
      selector: 'EXPLOITATION.SUPPLIER_WARRANTY_EXPIRATION_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'EXPLOITATION',
    },
  
  
    {
      selector: 'ATTACHMENT.PHOTO_TITLE',
      title: 'someTitle',
      type: PHOTO,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'ATTACHMENT',
    },
    {
      selector: 'ATTACHMENT.DOCUMENT_TITLE',
      title: 'someTitle',
      type: FILE,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'ATTACHMENT',
    },
  ]
  