import React, {
  useCallback,
  useEffect,
  useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import DutyTable from '@/components/blocks/DutyTable'
import moment from 'moment'
import useReduxTable from '@/hooks/useReduxTable'
import { PPR_TABLE } from '@/store/actions/Ppr'
import { getTableParameters } from '@/store/selectors/ppr'
import {
  fields,
  exportOptions,
  settingsOptions,
  settingsYear,
} from '@/constants/tablesConfig/Ppr'

import {
  Main,
  Content,
  Title,
} from './styles'

import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'

const Ppr = ({
  setNode,
  selectedNode,
  requestPprTable,
  data,
  globalLoading,
  year,
  setYear,
  setStatus,
  status,
  dates,
  setDatesRange,
  requestPprFile,
}) => {

  const tableProps = useReduxTable(PPR_TABLE, getTableParameters)
  const [title, setTitle] = useState('Для построения отчета выберите геозону и даты')

  useEffect(() => {
    if (!(dates.start || dates.end) && selectedNode.id) {
      setTitle('Для построения отчета выберите даты')
    }
    if ((dates.start || dates.end) && !selectedNode.id) {
      setTitle('Для построения отчета выберите геозону')
    }
  },[dates, selectedNode])

  const setDate = (value) => {
    setDatesRange(value)
  }

  const handleSetNode = useCallback((node, event) => {
    if (event === 'click') {
      setNode(node)
      if ((dates.start || dates.end)) {
        requestPprTable()
      }
    }
  },
  [setNode, requestPprTable, dates])

  const titlePpr = () => <Title>{title}</Title>

  const testData = data.map((item) => {
    
    const factDate = item.details.map((item) => {
      let plan = null
      let fact = null
      const now = moment()
      if (item.planDate) {
        plan = moment(item.planDate) 
      }
      if (item.factDate) {
        fact = moment(item.factDate) 
      }
      if (!fact && plan) {
        return {date: moment(item.factDate).format('DD.MM.YYYY'), expired: plan < now}
      }
      if (!plan && fact) {
        return {date: moment(item.factDate).format('DD.MM.YYYY'), expired: false}
      }
      return {date: moment(item.factDate).format('DD.MM.YYYY'), expired: plan < fact}
    })
    return ({
      ...item, 
      originalInstallationType: item.details.map((item) => item.originalInstallationType),
      planDate: item.details.map((item) => moment(item.planDate).format('DD.MM.YYYY')),
      factDate: factDate,
    })
  })


  return (
    <Main>
      <Content>
        <TreeSidebar
          title={<Lang id={"ppr.title"}/>}
          onSelect={handleSetNode}
          hidden={false}
          selectedNode={selectedNode}
          treeId={'PPR-tree'}
        />
        <DutyTable
          withDates
          {...tableProps}
          fields={fields}
          data={testData}
          exportOptions={exportOptions}
          settingsStatus={settingsOptions}
          dates={dates}
          year={year}
          status={status}
          titlePpr={titlePpr}
          isDataLoading={globalLoading}
          selectedView={'table'}
          onFileSelect={requestPprFile}
          onUpdateData={requestPprTable}
          settingsYear={settingsYear}
          setYear={setYear}
          setStatus={setStatus}
          getTableData={setDate}
          selectedNodeId={!!selectedNode.id}
        />
      </Content>
    </Main>
  )
}

export default Ppr