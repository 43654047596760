import styled from 'styled-components'
import Button from '@/components/blocks/Button'
import LabelWithIcon from '@/components/blocks/LabelWithIcon/styles'
import {
  HeaderContentWrapper,
  HeaderRowWrapper,
  PageSidebarStyled,
  SidebarWrapper, TreeContainer
} from '@/components/regions/sidebars/PageSidebar/styles'

export const Container = styled.div`
  width: 340px;
  min-width: 310px;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const Header = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid  ${({ theme }) => theme.colors.borders.default};
  button {
    height: 28px;
    display: flex;
    align-items: center;
  }
  div:not(:first-child) {
    justify-self: flex-end;
    margin-left: 10px;
  }
  div:first-child {
    flex: 1;
  }
`

export const HeaderTitle = styled.div`
  width: 30%;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
  white-space: wrap;
`

export const FieldsContainer = styled.div` 
  width: 100%;
  padding: 10px;
  ${LabelWithIcon}:first-child {
    margin: 5px 0;
  }
  ${LabelWithIcon} {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    margin: 15px 0 5px 0;
  }
  ${({ ghostMode }) => ghostMode && `
    opacity: 0.3;
    cursor: not-allowed;
    position: relative;
    top: 0;
    left: 0;
    ::after {
      position: absolute;
      width: 100%;
      height: calc(100vh - 96px);
      content: ' ';
      top: 0;
      left: 0;
    }
  `}
`
export const HeaderButton = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  color: ${({ type, theme }) => {return type === 'green' || type === 'red' ? theme.colors.white : theme.colors.colors.redisign.default}};
  svg,
  .svgFill,
  .svgStroke {
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.messages.fix
    if (type === 'red') return theme.colors.messages.critical
    return 'transparent'
}};
  pointer-events: ${({ isSubmitting }) => {
    return isSubmitting ? 'none' : 'auto'
}};
  cursor: pointer;
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
`

export const InfoContainer = styled.div`
  padding: 10px 0;
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  ${({ type, theme }) => type && `
    svg,
    .svgFill {
      height: 14px;
      width: 14px;
      margin-right: 10px;
      fill: ${theme.colors.colors.redisign.dark};
    }
  `}
`

export const ButtonContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .svgFill {
    height: 16px;
    fill: ${({ theme }) => theme.colors.white};
  }
  svg {
    margin-right: 10px;
  }
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.4rem;
`

export const InputAndLabelContainer = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  && > div {
    text-align: left;
  }
  input {
    width: 100%;
    height: 28px;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  & > span {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: green;
  }
  ${LabelWithIcon} {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.colors.redisign.default};
    margin-bottom: 5px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 9px;
`

export const StyledButton = styled(Button)`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  background: transparent;
  color: red;
`

export const ObjectsGroupsForm = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

export const FiltersContainer = styled.div`
  width: 400px;
  min-width: 400px;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`
export const TreeWrapper = styled.div`
  border-radius: 4px;
  ${PageSidebarStyled} {
    width: 100%;
    border-right: none;
    height: fit-content;
    border-radius: 4px;
  }
  ${SidebarWrapper} {
    height: 100%;
  }
  ${TreeContainer} {
    height: calc(100vh - 360px);
    overflow-y: auto;
  }
  ${HeaderContentWrapper} {
    padding: 0;
  }
  ${HeaderRowWrapper} {
    border-bottom: none;
    input {
      border: 1px solid ${({ theme }) => theme.colors.borders.textField};
      color: ${({ theme }) => theme.colors.colors.textField};
      background: #FFFFFF;
    }
  }
  ${({ theme, isError }) => `
    {
      border: 1px solid
      ${isError ? theme.colors.messages.error : '#FAFBFB'};
    }
  `}
`

