import React, {
  useMemo,
  useState,
  useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { Formik, Form } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import hash from 'hash-sum'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import FileControllField from '@/components/fields/FileControllField'
import Button from '@/components/blocks/Button'
import viewTreeIcons from '@/constants/viewTree'
import LiveTime from '@/components/icons/livetime'
import CoreIcons from '@/components/icons/core'
import RadioGroup from '@/components/fields/RadioGroupField'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import MapIcons from '@/components/icons/maps'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import { CHAR_LIMITS } from '@/constants/sizes'
import {
  TEXT,
  RADIO_GROUP,
  FILE,
  PHOTO,
  CUSTOMS_FIELDS,
  ADD_CUSTOMS_FIELDS,
  SELECT,
} from '@/constants/semanticNames'
import {
  CatalogContainer,
  CatalogRow,
  CatalogTitle,
  ToggleContainer,
  CatalogOptionsContainer,
  ButtonContainer,
  InputAndLabelContainer,
  IconContainer,
  InfoField,
  InfoContainer,
  CustomFieldBlock,
  CustomFieldContainer,
  CancelButton,
  AddFieldButton,
} from './styles'
import SelectField from '@/components/fields/SelectField'

const ObjectCreateForm = React.forwardRef(({
  data,
  options,
  block,
  edit,
  setImage,
  handleDownloadFile,
  handleExportFile,
  validationScheme,
  setIsValid,
  displayExport,
  isVisibleForm,
  setNewPointsHash,
  preview,
  objectConnectedIdentifiers,
  intl
}, ref) => {
  const [openCatalogsId, setOpenCatalogsId] = useState([])
  const [isInitialization, setIsInitialization] = useState(false)

  useEffect(() => {
    if (!block && !isInitialization) {
      get(ref, 'current.setFieldValue', noop)('edit', true)
      setIsInitialization(true)
    }
  }, [block, isInitialization, setIsInitialization, ref])

  useEffect(() => {
    const runValidations = get(ref, 'current.runValidations', noop)
    const values = get(ref, 'current.state.values', {})
    runValidations(values)
  }, [validationScheme, ref])

  const downloadFileFromBlobHandler = (file) => () => {
    downloadFileFromBlob({
      blob: file,
      fileName: file.name,
    })
  }

  const catalogToggleHandler = (id) => () => {
    const openCatalogsIdCopy = [...openCatalogsId]
    if (openCatalogsId.includes(id)) {
      const filteredElementsId = openCatalogsIdCopy.filter((element) => id !== element)
      setOpenCatalogsId(filteredElementsId)
      return null
    }
    openCatalogsIdCopy.push(id)
    setOpenCatalogsId(openCatalogsIdCopy)
  }

  const setCustomField = (setField, selector, value) => () => {
    setField(selector, value)
  }

  const formData = useMemo(() => {
    return cloneDeep(data)
  }, [data])

  const formatOptions = useMemo(() => {
    if (options.length === 0) {
      return {}
    }
    const filterByDisabledFieldsByCatalog = options.reduce((accumulator, option) => {
      if (option.selector === 'GENERAL_INFORMATION.PREVIOUS_LINKED_IDENTIFIER') {
        option.options = edit ? objectConnectedIdentifiers.filter(option => option.value !== data.objectElementId) : objectConnectedIdentifiers
      }
      const { catalogSelector } = option
      return {
        ...accumulator,
        [catalogSelector]: {
          selector: catalogSelector,
          ...get(options, `${catalogSelector}`, {}),
          options: [
            ...get(accumulator, `${catalogSelector}.options`, []),
            option,
          ],
        },
      }
    }, {})

    return Object.keys(filterByDisabledFieldsByCatalog).reduce((accumulator, catalogName) => {
      const newOptions = get(filterByDisabledFieldsByCatalog, `${catalogName}.options`, [])
      if (catalogName !== 'ATTACHMENT') {
        newOptions.push({
          type: CUSTOMS_FIELDS,
          catalogSelector: catalogName,
          selector: `${catalogName}.customFields`,
        })
        newOptions.push({
          type: ADD_CUSTOMS_FIELDS,
          catalogSelector: catalogName,
          selector: `${catalogName}.addCustomFields`,
        })
      }

      return {
        ...accumulator,
        [catalogName]: {
          ...filterByDisabledFieldsByCatalog[catalogName],
          options: newOptions,
        },
      }
    }, {})
  }, [options, objectConnectedIdentifiers, edit, data])

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const createCustomFieldHandler = (values, catalogSelector, setFieldValue) => () => {
    const customFieldSelector = `${catalogSelector}.${CUSTOMS_FIELDS}`
    const fields = get(values, customFieldSelector, [])
    setFieldValue(
      customFieldSelector,
      [
        ...fields,
        {},
      ],
    )
  }

  const setPhotoToNull = (selector, setFieldValue) => () => {
    setImage(null)
    setFieldValue(selector, null)
  }

  // const downloadFile = useCallback(() => {
  //   handleDownloadFile()
  // }, [handleDownloadFile])
  const renderOption = (touched, errors, isSubmitting, values, setFieldValue) => (element) => {
    const {
      selector,
      type,
      options,
      format,
      mask,
      immutable,
      catalogSelector,
      pattern,
    } = element
    const localImmutable = !edit ? false : immutable
    const value = get(values, selector, null) || '-'
    return (
      <InputAndLabelContainer>
        {block && type !== FILE && type !== PHOTO && type !== CUSTOMS_FIELDS && type !== ADD_CUSTOMS_FIELDS && (
          <InfoContainer>
            <LabelWithIcon
              isError={(get(touched, selector, false) && get(errors, selector, false))}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <InfoField bg>
              {
                type === RADIO_GROUP ? ((options.filter((element) => element.value === get(values, selector, null))[0] || {}).label || '-').toString() : value
              }
            </InfoField>
          </InfoContainer>
        )}
        {block && type === CUSTOMS_FIELDS && (
          <>
            {((values[catalogSelector] || {}).customFields || []).map((customField, index) => {
              const localSelector = `${selector}[${index}]`
              const localSelectorValue = `${localSelector}.value`
              const localSelectorName = `${localSelector}.fieldName`
              const nameTouch = get(touched, localSelectorName, null)
              const nameError = get(errors, localSelectorName, null)
              return (
                <InfoContainer>
                  <LabelWithIcon
                    isError={nameError && nameTouch}
                    title={get(values, localSelectorName, null)}
                  />
                  <InfoField bg>
                    {get(values, localSelectorValue, null)}
                  </InfoField>
                </InfoContainer>
              )
            })}
          </>
        )}
        {type === TEXT && !block && (
          <>
            <LabelWithIcon
              isError={(get(touched, selector, false) && get(errors, selector, false))}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <TextField
              isError={(get(touched, selector, false) && get(errors, selector, false))}
              name={selector}
              mask={mask}
              errorMessage={get(errors, selector, null)}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (localImmutable && !block) || selector === 'GENERAL_INFORMATION.EQUIPMENT_TYPE',
                format,
                onlyRead: block,
                pattern,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || block || localImmutable)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
        {type === SELECT && !block && (
            <>
              <LabelWithIcon
                  isError={(get(touched, selector, false) && get(errors, selector, false))}
                  title={(<Lang id={`passport.fields.${selector}`} />)}
              />
              <SelectField
                  error={(get(touched, selector, false) && get(errors, selector, false))}
                  name={selector}
                  mask={mask}
                  withSearch
                  placeholder="Выбрать"
                  options={options || []}
                  light
                  fieldProps={{
                    autoComplete: 'off',
                    disabled: isSubmitting,
                    format,
                    onlyRead: isSubmitting,
                  }}
              />
            </>
        )}
        {type === RADIO_GROUP && !block && (
          <>
            <LabelWithIcon
              isError={(get(touched, selector, false) && get(errors, selector, false))}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <RadioGroup
              error={(touched[selector] && errors[selector])}
              options={options}
              name={selector}
              fieldProps={{
                disabled: isSubmitting || localImmutable,
              }}
            />
          </>
        )}
        {type === FILE && (
          <>
            <LabelWithIcon
              isError={(get(touched, selector, false) && get(errors, selector, false))}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <FileControllField
              error={(touched[selector] && errors[selector])}
              name={selector}
              type="file"
              accept="application/pdf"
              controls={!block && (get(values, `${selector}.title`, null) || get(values, `${selector}.name`, null))
                ? (
                  <>
                    {get(data, 'inner.file', null) === get(values, 'inner.file', null) && (
                      <PortalTooltip
                        title={(<Lang id="tooltip.download" />)}
                        renderChildren={(
                          wrapperRef,
                          onMouseEnterHandler,
                          onMouseLeaveHandler,
                        ) => (
                          <IconContainer
                            onClick={downloadFileFromBlobHandler(get(values, selector, {}))}
                            ref={wrapperRef}
                            onMouseEnter={onMouseEnterHandler}
                            onMouseLeave={onMouseLeaveHandler}
                          >
                            <UsersIcons.UploadIcon />
                          </IconContainer>
                        )}
                      />
                    )}
                    <PortalTooltip
                      title={(<Lang id="tooltip.remove" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <UsersIcons.TrashIcon />
                        </IconContainer>
                      )}
                    />
                  </>
                )
                : false}
              disabled={isSubmitting || localImmutable || preview}
            />
          </>
        )}
        {type === PHOTO && (
          <>
            <LabelWithIcon
              isError={(get(touched, selector, false) && get(errors, selector, false))}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <FileControllField
              error={(touched[selector] && errors[selector])}
              name={selector}
              setImage={setImage}
              type="photo"
              accept="image/png,image/jpeg"
              controls={!block && (get(values, `${selector}.title`, null) || get(values, `${selector}.name`, null))
                ? (
                  <PortalTooltip
                    title={(<Lang id="tooltip.remove" />)}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <IconContainer
                        onClick={setPhotoToNull(selector, setFieldValue)}
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                      >
                        <UsersIcons.TrashIcon />
                      </IconContainer>
                    )}
                  />
                )
                : false}
              disabled={isSubmitting || localImmutable || preview}
            />
          </>
        )}
        {!block && type === CUSTOMS_FIELDS && (
          <>
            {((values[catalogSelector] || {}).customFields || []).map((customField, index) => {
              const localSelector = `${selector}[${index}]`
              const localSelectorValue = `${localSelector}.value`
              const localSelectorName = `${localSelector}.fieldName`
              const nameTouch = get(touched, localSelectorName, null)
              const nameError = get(errors, localSelectorName, null)
              const valueTouch = get(touched, localSelectorValue, null)
              const valueError = get(errors, localSelectorValue, null)
              const value = get(values, localSelectorName, null) || intl.messages['installation.projectForm.newField']
              const previewValue = `"${value.length <= CHAR_LIMITS.medium ? value : `${value.substr(0, CHAR_LIMITS.medium)}...`}" ${intl.messages['installation.projectForm.deleted']}`
              return (
                <CustomFieldContainer remove={customField.remove}>
                  {!customField.remove
                    ? (
                      <>
                        <CustomFieldBlock>
                          <LabelWithIcon
                            isError={nameTouch && nameError}
                            title={<Lang id="installation.projectForm.customFieldTitle" />}
                          />
                          <IconContainer onClick={setCustomField(setFieldValue, `${localSelector}.remove`, true)}>
                            <UsersIcons.TrashIcon />
                          </IconContainer>
                          <TextField
                            error={nameTouch && nameError}
                            errorMessage={nameError}
                            name={localSelectorName}
                            mask={mask}
                            fieldProps={{
                              autoComplete: 'off',
                              disabled: isSubmitting || (localImmutable && !isSubmitting),
                              format,
                              onlyRead: isSubmitting,
                            }}
                          />
                        </CustomFieldBlock>
                        <CustomFieldBlock>
                          <LabelWithIcon
                            isError={valueTouch && valueError}
                            title={<Lang id="installation.projectForm.customFieldValue" />}
                          />
                          <TextField
                            error={valueTouch && valueError}
                            errorMessage={valueError}
                            name={localSelectorValue}
                            mask={mask}
                            fieldProps={{
                              autoComplete: 'off',
                              disabled: isSubmitting || (localImmutable && !isSubmitting),
                              format,
                              onlyRead: isSubmitting,
                            }}
                          />
                        </CustomFieldBlock>
                      </>
                    )
                    : (
                      <>
                        {previewValue}
                        <CancelButton
                          onClick={setCustomField(setFieldValue, `${localSelector}.remove`, false)}
                        >
                          <Lang id="installation.cancel" />
                        </CancelButton>
                      </>
                    )}
                </CustomFieldContainer>
              )
            })}
          </>
        )}
        {!block && type === ADD_CUSTOMS_FIELDS && (
          <AddFieldButton styleType="primary" onClick={createCustomFieldHandler(values, catalogSelector, setFieldValue)}>
            <MapIcons.InvertPlusIcon />
            <Lang id="installation.projectForm.customField" />
          </AddFieldButton>
        )}
      </InputAndLabelContainer>
    )
  }
  return (
    <Formik
      ref={ref}
      enableReinitialize={edit}
      validateOnMount
      validateOnChange
      validationSchema={validationScheme}
      initialValues={formData}
      isInitialValid={edit && !isEmpty(formData, 'LOCATION', {})}
      render={({
        touched, errors, isSubmitting, values, setFieldValue, handleChange, isValid,
      }) => {
        setIsValid(isValid)
        setNewPointsHash(hash(values))
        return (
          <Form id="PassportizationForm" name="PassportizationForm">
            {!isEmpty(formatOptions) && Object.keys(formatOptions).map((element) => {
              const { selector, options } = formatOptions[element] || {}
              const isOpen = openCatalogsId.includes(selector)
              return (
                <CatalogContainer key={selector} hidden={!isVisibleForm}>
                  <CatalogRow>
                    <CatalogTitle>
                      <Lang id={`passport.catalogs.${selector}`} />
                    </CatalogTitle>
                    <PortalTooltip
                      title={isOpen
                        ? (<Lang id="tooltip.collapse" />)
                        : (<Lang id="tooltip.deCollapse" />)}
                      renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                        <ToggleContainer
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                          onClick={catalogToggleHandler(selector)}
                          isOpen={isOpen}
                        >
                          <viewTreeIcons.arrow />
                        </ToggleContainer>
                      )}
                    />
                  </CatalogRow>
                  { isOpen
                    && (
                      <CatalogOptionsContainer>
                        {options.map(renderOption(touched, errors, isSubmitting, values, setFieldValue, handleChange))}
                      </CatalogOptionsContainer>
                    )}
                </CatalogContainer>
              )
            })}
            {displayExport && (
              <ButtonContainer>
                <Button type="button" onClick={handleExportFile}>
                  <LiveTime.ExportIcon />
                  <Lang id="passport.passportSidebar.passportExport" />
                </Button>
              </ButtonContainer>
            )}
          </Form>
        )
      }}
    />
  )
})

ObjectCreateForm.propTypes = {
  data: pt.objectOf(pt.object),
  validationScheme: pt.objectOf(pt.object),
  options: pt.arrayOf(pt.object),
  setImage: pt.func,
  setIsValid: pt.func,
  handleDownloadFile: pt.func,
  handleExportFile: pt.func,
  block: pt.bool,
  edit: pt.bool,
  displayExport: pt.bool,
  preview: pt.bool,
  isVisibleForm: pt.bool,
  setNewPointsHash: pt.func,
}

ObjectCreateForm.defaultProps = {
  data: {},
  validationScheme: {},
  options: [],
  setImage: noop,
  setIsValid: noop,
  handleDownloadFile: noop,
  handleExportFile: noop,
  block: false,
  edit: false,
  displayExport: false,
  preview: false,
  isVisibleForm: true,
  setNewPointsHash: noop,
}

export default ObjectCreateForm
