import styled from 'styled-components'

export const CellContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0.01em;
  justify-content: flex-start;
  white-space: pre;
  margin-left: -5px;
  margin-right: -5px;
  min-height: 100%;
  ${({ text }) => text && `
    min-width: 250px
    text-align: left;
    white-space: normal;
  `}
  ${({ date }) => date && `
    justify-content: center;
  `}
  div:not(:last-child) {
    border-bottom: 1px solid #BDC3C7;
    padding-bottom: 5px;
  }
`

export const Row = styled.div`
  padding: 5px 5px 0 5px;
  width: 100%;
  color: #3D3D3D;
  opacity: 0.8;
  display: flex;
  justify-content: space-around;
  height: 38px;
  align-items: center;
  ${({ installationType }) => installationType && `
    text-align: left; 
  `}
  ${({ expired }) => expired && `
    color: #EE2747;
    background-color: rgba(238, 39, 71, 0.15);
 
  `}
 
`
