import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_SCHEDULE_DATA_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_SCHEDULE_DATA } from '@/store/actions/objectsGroups'
import {
  errorGetScheduleData,
  successGetScheduleData
} from '@/store/actions/objectsGroups/getScheduleData'
import { successSetSchedule } from '@/store/actions/objectsGroups/setSchedule'

function* getScheduleData({ payload }) {
  try {
    const { groupId } = payload
    const response = yield request({
      url: GET_SCHEDULE_DATA_URL(groupId),
      method: 'get',
    })
    yield put(successGetScheduleData(response.data))
    yield put(successSetSchedule())
  } catch (error) {
    yield put(errorGetScheduleData(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные расписания. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_SCHEDULE_DATA.REQUEST, getScheduleData)
}
