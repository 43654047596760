import asuno from './asuno'
import baseStation from './baseStation'
import controller from './controller'
import multiTariffElectricityMeter from './multiTariffElectricityMeter'
import coldWaterDevice from './coldWaterDevice'
import heatingDevice from './heatingDevice'
import shuo from './shuo'
import groupObject from './groupObject'
import support from './support'
import multiRateMeter from './multiRateMeter'
import defaultPassport from './default'
import lightingFixture from './lightingFixture'
import module from './module'
import line from './line'
import lampModule from './lampModule'

export default {
  ASUNO: asuno,
  BASE_STATION: baseStation,
  CONTROLLER: controller,
  LIGHT_FIXTURE: lightingFixture,
  MODULE: module,
  STREETLIGHT: lightingFixture,
  LAMP_MODULE: lampModule,
  LIGHT_MODULE: lampModule,
  LIGHT_POINT: lightingFixture,
  LAMP: lightingFixture,
  STREETLIGHT_CONTROL_CABINET: lightingFixture,
  MULTI_TARIFF_ELECTRICITY_METER: multiTariffElectricityMeter,
  CONTROL_CUPBOARD: shuo,
  COLD_WATER_DEVICE: coldWaterDevice,
  CENTRAL_HEATING_DEVICE: heatingDevice,
  HEATING_DEVICE: heatingDevice,
  CENTRAL_HEATION: heatingDevice,
  GROUP_OBJECT: groupObject,
  COMPANY_OBJECT: groupObject,
  WASTE_CONTAINER: defaultPassport,
  UTILITY_POLE: support,
  MULTIRATE_METER: multiRateMeter,
  ELECTRIC_METER: multiRateMeter,
  CONTACTOR: defaultPassport,
  DEVICE: defaultPassport,
  LIGHT_SENSOR: defaultPassport,
  STREETLIGHT_GROUP: defaultPassport,
  SWITCHING_GROUP: defaultPassport,
  DOOR_OPEN_SENSOR: defaultPassport,
  METEO: defaultPassport,
  ROAD_TEMPERATURE_SENSOR: defaultPassport,
  SMOKE_SENSOR: defaultPassport,
  BRANCH_CIRCUIT: defaultPassport,
  CHECKPOINT: defaultPassport,
  CONTROLLER_FUSE: defaultPassport,
  CURRENT_SENSOR: defaultPassport,
  DMX_DEVICE: defaultPassport,
  FIRE_SENSOR: defaultPassport,
  FUSE: defaultPassport,
  PHASE: defaultPassport,
  PHASE_CIRCUIT_BREAKER: defaultPassport,
  PHOTO_SENSOR: defaultPassport,
  TRANSFER_SWITCH: defaultPassport,
  VOLUME_SENSOR: defaultPassport,
  SENSOR: defaultPassport,
  WATHER_SUPLY: defaultPassport,
  LINE: line,
}
