import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
        showInDashboard: true,
        selector: 'name',
    },
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
        showInDashboard: true,
        selector: 'objectIdOuter',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
        showInDashboard: true,
        selector: 'ROOT_OBJECT',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
        showInDashboard: true,
        selector: 'REGISTRY_NUMBER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.UTILITY_POLE_TYPE" />,
        showInDashboard: true,
        selector: 'UTILITY_POLE_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
        showInDashboard: true,
        selector: 'MODEL_MARK',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
        showInDashboard: false,
        selector: 'PROCEDURE_SUPPLIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LINE_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'LINE_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LAMP_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'LAMP_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PARENT_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
        showInDashboard: true,
        selector: 'PARENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PREVIOUS_LINKED_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PREVIOUS_LINKED_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.location.latitude" />,
        showInDashboard: true,
        selector: 'latitude',
    },
    {
        passportParameter: <Lang id="passportization.fields.location.longitude" />,
        showInDashboard: true,
        selector: 'longitude',
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
        showInDashboard: true,
        selector: 'START_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
        showInDashboard: true,
        selector: 'END_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
        showInDashboard: true,
        selector: 'ORGANIZATION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
        showInDashboard: true,
        selector: 'OWNER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
        showInDashboard: true,
        selector: 'GUARANTEE_PERIOD',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
        showInDashboard: true,
        selector: 'LIFE_TIME',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
        showInDashboard: true,
        selector: 'REGION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
        showInDashboard: true,
        selector: 'CITY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
        showInDashboard: true,
        selector: 'STREET',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
        showInDashboard: true,
        selector: 'HOME_NUMBER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.CITY_NUMERIC" />,
        showInDashboard: true,
        selector: 'CITY_NUMERIC',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.UTILITY_POLE_KIND" />,
        showInDashboard: true,
        selector: 'UTILITY_POLE_KIND',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY" />,
        showInDashboard: true,
        selector: 'LIGHTING_CATEGORY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.LOCATION_TYPE" />,
        showInDashboard: true,
        selector: 'LOCATION_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.IMPORTANCE" />,
        showInDashboard: true,
        selector: 'IMPORTANCE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.ROADWAY_WIDTH" />,
        showInDashboard: true,
        selector: 'ROADWAY_WIDTH',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.INSTALLATION_SCHEME" />,
        showInDashboard: true,
        selector: 'INSTALLATION_SCHEME',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.STATE" />,
        showInDashboard: true,
        selector: 'STATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER" />,
        showInDashboard: true,
        selector: 'CONNECTED_FIXTURES_NUMBER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.VOLS" />,
        showInDashboard: true,
        selector: 'VOLS',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.OBJECT_ATTRIBUTE" />,
        showInDashboard: true,
        selector: 'OBJECT_ATTRIBUTE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.BELONGING" />,
        showInDashboard: true,
        selector: 'BELONGING',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.POWER_SUPPLY" />,
        showInDashboard: true,
        selector: 'POWER_SUPPLY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.POWER_SUPPLY_TYPE" />,
        showInDashboard: true,
        selector: 'POWER_SUPPLY_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.INSPECTION_DATE" />,
        showInDashboard: true,
        selector: 'INSPECTION_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
        showInDashboard: true,
        selector: 'BYPASS_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
        showInDashboard: true,
        selector: 'MAINTENANCE_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
        showInDashboard: true,
        selector: 'REPAIR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.CAPITAL_REPAIR_DATE" />,
        showInDashboard: true,
        selector: 'CAPITAL_REPAIR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.inner.photo" />,
        showInDashboard: true,
        selector: 'photo',
    },
    {
        passportParameter: <Lang id="passportization.fields.inner.file" />,
        showInDashboard: true,
        selector: 'DOCUMENT_TITLE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passportization.fields.plan.date" />,
        showInDashboard: true,
        selector: 'factDate',
      },
      {
        passportParameter: <Lang id="passportization.fields.fact.date" />,
        showInDashboard: true,
        selector: 'planDate',
      },
]
