import {
  put, takeLatest, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { CREATE_OBJECTS_GROUP_TREE_ENDPOINT } from '@/constants/apiRoutes'
import get from 'lodash/get'
import { INSTALLATION_SERVER_TO_LOCAL } from '@/constants/passportization/types'
import createNotifications from '@/helpers/notification'
import {
  ROOT,
  GROUP,
} from '@/constants/objectTypes'
import { GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS } from '@/store/actions/objectsGroups'
import {
  errorGetCreateObjectsGroupMapObjects,
  successGetCreateObjectsGroupMapObjects
} from '@/store/actions/objectsGroups/getCreateObjectsGroupMapObjects'
import {
  successGetCreateObjectsGroupSearchTree,
} from '@/store/actions/objectsGroups/getCreateObjectsGroupTree'
import { getObjectsGroup } from '@/store/selectors/objectsGroups'

function* getCreateObjectsGroupMapObjectsSaga({ payload }) {
  try {
    const { filters } = payload
    const card = yield select(getObjectsGroup)
    const data = yield request( {
      url: CREATE_OBJECTS_GROUP_TREE_ENDPOINT({
        params: {
          includeAll: true,
          equipmentTypes: filters.objectType,
          lampTypes: filters.lightFixtureType,
          locationTypes: filters.pedestrianCrossingSign,
          networkCompanies: filters.networkCompany,
          registryNumbers: filters.registryNumber,
          shuns: filters.controlCupboard,
          streets: filters.street,
          utilityPoleTypes: filters.utilityPoleType,
          vols: filters.vols,
        }
      }),
      method: 'get',
    })
    const formattedRequestData = data.data
    const selectType = (element) => {
      if (!element.parentId) {
        return 'country'
      } else if (element.installationType === GROUP || !element.installationType || !INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]) {
        return 'UNKNOWN'
      } else {
        return element.installationType || INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]
      }
    }
    const formattedData = formattedRequestData?.map(element => (
      {
       ...element,
       location: [
         get(element, 'latitude', null),
         get(element, 'longitude', null),
       ],
       type: selectType(element),
       elementType: element.parentId ? element.treeNodeType : ROOT,
       geoZoneId: element.path[1],
       parentGeoZoneId: element.parentId,
       parentTreeId: element.parentId,
       checked: payload?.edit ? card.objects?.some(item => item.id === element.id) : false,
       color: payload?.edit && card.objects?.some(item => item.id === element.id) ? card.color : '',
       nodeType: element.treeNodeType,
       original: {
         options: {
           path: `/${element.path.join('/')}/${element.id}`
         }
       },
      })
    )

    let searchTree = [{name: 'Россия', id: 1, type: ROOT}]
    searchTree[0].children = formattedRequestData.filter(item => item.parentId === searchTree[0].id)

    const getNodeChildren = (id, data) => {
      let children = data.filter(item => item.parentId === id).map(child => {
        return {
          ...child,
          checked: payload?.edit ? card.objects?.some(item => item.id === child.id) : false,
          type: child.installationType || child.treeNodeType,
          nodeType: child.treeNodeType,
          treeNodeType: null,
        }})
      const updatedData = data.filter(item => item.path.includes(id))
      if (children.length) {
        children.forEach(item => {
          item.children = getNodeChildren(item.id, updatedData)
        })
      }
      return children
    }

    searchTree[0].children.forEach(item => {
      item.children = getNodeChildren(item.id, formattedRequestData.filter(node => node.path.includes(item.id)))
    })
    yield put(successGetCreateObjectsGroupSearchTree(searchTree))


    yield put(successGetCreateObjectsGroupMapObjects({
      displayElements: formattedData,
    }))
  } catch (error) {
    yield put(errorGetCreateObjectsGroupMapObjects())
    console.log('function*getMapObjectsSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить объекты для карты.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.REQUEST, getCreateObjectsGroupMapObjectsSaga)
}
