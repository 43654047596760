import request from '@/helpers/axios'
import { GEOZONE, INTEGRATION } from '@/constants/objectTypes'
import get from 'lodash/get'

export const getChildren = ({rootId, closestGeoZoneId, parentTreeId, asu, objectsTypes, integrations, objectStates, url, telemetryStatuses, processes, owners, exploitationOrganizations, ppr} ) => async (node) => {
  const { data } = await request({
    url: url({
      params: {
        parentId: get(node, 'id', null)
            || get(node, 'node.id', null)
            || get(node, 'node.data.id', null)
            || get(node, 'node', null),
        integrationTypes: asu,
        installationTypes: objectsTypes,
        integrationIds: integrations,
        cityProcessTypes: processes,
        telemetryStatuses,
        objectStates,
        owners,
        exploitationOrganizations
      },
    }),
    method: 'get',
  })
  const childWithCount = await Promise.all(data.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map(async (element) => {
    const parentGeoZoneId = element.treeNodeType === GEOZONE
      ? element.id
      : closestGeoZoneId
    return {
      ...element,
      type: element.installationType || element.treeNodeType,
      systemType: element.treeNodeType,
      name: element.name,
      parentGeoZoneId: closestGeoZoneId,
      parentTreeId,
      rootGeoZone: rootId,
      childrenCount: element.countChildElements,
      asuId: element.asuId,
      state: element.objectState,
      getChildren: (ppr && element.treeNodeType === INTEGRATION) ? null : getChildren({rootId, parentGeoZoneId, parentTreeId: element.id, objectStates, objectsTypes, integrations, asu, url, telemetryStatuses, processes, owners, exploitationOrganizations, ppr }),
    }
  }))
  return childWithCount
}

export default getChildren
