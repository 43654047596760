import { connect } from 'react-redux'
import {
  toggleUserProfile,
  toggleSettings,
  setSelectedNode,
  setUnpinNode,
  setGlobalFilterValues,
  resetGlobalFilterValues,
  requestApplicationsVersion,
  requestAllApplicationsFiles,
} from '@/store/actions/appSettings'
import {
    requestGetAlarmsCount,
    endGetAlarmsCountNotification
} from '@/store/actions/alarmManager/getAlarmsCount'
import {
  getProfileOpenState,
  getSettingsOpenState,
  getUserData,
  getNode,
  getPinnedNode,
  getGlobalFilters,
  getApplicationsFiles,
} from '@/store/selectors/appSettings'
import { getSyncInProgress } from '@/store/selectors/integrations'
import { getEventsNewCount, getEventsCountNotification } from '@/store/selectors/alarmManager'
import AppHeader from '@/components/regions/AppHeader'
import { setStatusTypes } from '@/store/actions/alarmManager'
import { requestGetSearchFieldsOptions } from '@/store/actions/dictionaries/searchFieldsOptions'
import { getSearchFieldsOptionsData } from '@/store/selectors/dictionaries/searchFieldsOptions'
import { requestGetAllConnectedIntegrations } from '@/store/actions/dictionaries/connectedIntegrations'
import { getConnectedIntegrationsOptions } from '@/store/selectors/dictionaries/connectedIntegrations'

export default connect(
  (state) => ({
    isProfileOpen: getProfileOpenState(state),
    isSettingsOpen: getSettingsOpenState(state),
    syncInProgress: getSyncInProgress(state),
    eventsCount: getEventsNewCount(state),
    eventsCountNotification: getEventsCountNotification(state),
    user: getUserData(state),
    selectedNode: getNode(state),
    pinnedNode: getPinnedNode(state),
    globalFiltersValues: getGlobalFilters(state),
    searchFieldsOptions: getSearchFieldsOptionsData(state),
    connectedIntegrations: getConnectedIntegrationsOptions(state),
    applicationsFiles: getApplicationsFiles(state),
  }),
  (dispatch) => ({
    onToggleProfile: (state) => dispatch(toggleUserProfile(state)),
    onToggleSettings: (state) => dispatch(toggleSettings(state)),
    onGetAlarmsCount: (tenantId) => dispatch(requestGetAlarmsCount(tenantId)),
    endGetAlarmsCountNotification: (tenantId) => dispatch(endGetAlarmsCountNotification(tenantId)),
    onSetSelectedNode: (node) => dispatch(setSelectedNode(node)),
    onSetUnpinNode: (node) => dispatch(setUnpinNode(node)),
    onSetGlobalFilterValues: (values) => dispatch(setGlobalFilterValues(values)),
    onResetGlobalFilterValues: () => dispatch(resetGlobalFilterValues()),
    setStatusTypes: (payload) => dispatch(setStatusTypes(payload)),
    requestGetSearchFieldsOptions: (payload) => dispatch(requestGetSearchFieldsOptions(payload)),
    requestGetAllConnectedIntegrations: (payload) => dispatch(requestGetAllConnectedIntegrations(payload)),
    requestApplicationsVersion: () => dispatch(requestApplicationsVersion()),
    requestAllApplicationsFiles: () => dispatch(requestAllApplicationsFiles(),)
  }),
)(AppHeader)
