import styled from 'styled-components'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks' 

export const CheckBoxStyled = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 2px solid ${({ theme }) => theme.colors.borders.redisign.dark};
  border-radius: 4px;
  ${({ childSelected, theme }) => childSelected && `
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 10px;
      height: 2px;
      border: solid ${theme.colors.backgrounds.redisign.input.dark};
      border-width: 0 2px 2px 0;
    }
  `}
  ${({ active, theme, childSelected }) => active && !childSelected && `
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 1px;
      left: 0;
      right: 0;
      content: '';
      width: 6px;
      height: 9px;
      border: solid ${theme.colors.borders.redisign.dark};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `}
  ${({ type, theme }) => STATUS_COLORS[type] && type && `
    background: ${STATUS_COLORS[type]};
    ::after {
      border-color: ${theme.colors.backgrounds.redisign.input.light};
    }
  `}
  ${({ marginRight }) => marginRight && `
    margin-right: 10px;
  `}
  ${({ theme, color, active }) => color && active && `
    background: ${color};
    border: solid ${color};
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 1px;
      left: 0;
      right: 0;
      content: '';
      width: 6px;
      height: 9px;
      border: solid ${theme.colors.white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `}
  ${({customCheckBox, color}) => customCheckBox &&`
    border-radius: 50%;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border: solid ${color};
  `}
  ${({customCheckBox, color, childSelected}) => customCheckBox && !childSelected &&`
  ::after {
    top: 4px;
    width: 8px;
    height: 13px;
  }
    `}
  ${({theme, color, childSelected}) => childSelected &&`
    background: ${color};
    ::after {
      border: solid ${theme.colors.white};
    }
    `}
  ${({theme, childrenSelect}) => childrenSelect &&`
    margin-left: 3px;
    margin-bottom: 6px;
    ::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: -5px;
      height: 13px;
      width: 13px;
      border-left: 2px solid ${theme.colors.borders.redisign.dark};
      border-bottom: 2px solid ${theme.colors.borders.redisign.dark};
      border-bottom-left-radius: 4px;
  }
    `}
`
