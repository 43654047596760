import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ScheduleTab from './component'
import {
  getScheduleData,
  getScheduleOptions,
} from '@/store/selectors/objectsGroups'
import { requestGetScheduleOptions } from '@/store/actions/objectsGroups/getScheduleOptions'
import { requestGetScheduleData } from '@/store/actions/objectsGroups/getScheduleData'
import { requestSetSchedule } from '@/store/actions/objectsGroups/setSchedule'
import { requestResetSchedule } from '@/store/actions/objectsGroups/resetSchedule'

const mapStateToProps = (state) => ({
  scheduleData: getScheduleData(state),
  scheduleOptions: getScheduleOptions(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetScheduleOptions: (payload) => dispatch(requestGetScheduleOptions(payload)),
  requestGetScheduleData: (payload) => dispatch(requestGetScheduleData(payload)),
  requestSetSchedule: (payload) => dispatch(requestSetSchedule(payload)),
  requestResetSchedule: (payload) => dispatch(requestResetSchedule(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScheduleTab))
