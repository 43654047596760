import {
  put, takeLatest, select, call, take,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import updateNodeCount from '@/helpers/updateNodeCount'
import request from '@/helpers/axios'
import { successCreateObject } from '@/store/actions/installation/createObject'
import {
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import {
  NOT_VERIFIED,
} from '@/constants/objectStatuses'
import {
  getCard,
} from '@/store/selectors/installation'
import createNotifications from '@/helpers/notification'
import installationFormatFields from '@/helpers/installationFormatFields'
import errorsMapper from '@/constants/errors/createObjectElement'
import { CREATE_OBJECT, UPLOAD_INSTALLATION_OBJECT_FILE } from '@/store/actions/installation'
import { INSTALLATIONS_OBJECT_ENDPOINT } from '@/constants/apiRoutes'
import { requestUploadInstallationObjectFile } from '@/store/actions/installation/uploadObjectFile'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* createObjectSaga({ payload }) {
  const {
    formValues,
    values,
    setUrlFormValues,
    type,
    setErrors,
    setAlertConfig,
  } = payload
  const valuesDeepClone = cloneDeep(values)
  set(valuesDeepClone, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', 'DEFAULT_EQUIPMENT_TYPE')
  const toast = createNotifications()
  const cardCopy = yield select(getCard)
  delete cardCopy.elementsInfo
  try {
    yield put(setGlobalLoading(true))
    const objectElementName = get(valuesDeepClone, 'GENERAL_INFORMATION.NAME', null)
    const objectId = get(valuesDeepClone, 'GENERAL_INFORMATION.IDENTIFIER', null)
    const latitude = get(valuesDeepClone, 'LOCATION.LATITUDE', null)
    const longitude = get(valuesDeepClone, 'LOCATION.LONGITUDE', null)
    const integrationType = get(cardCopy, 'data.integrationType', null)
    const parentId = get(cardCopy, 'data.id', null)
    const parentName = get(cardCopy, 'data.name', null)
    const photoFile = get(values, 'ATTACHMENT.PHOTO_TITLE', null)
    const PDFFile = get(values, 'ATTACHMENT.DOCUMENT_TITLE', null)
    delete valuesDeepClone.LOCATION.LATITUDE
    delete valuesDeepClone.LOCATION.LONGITUDE
    delete valuesDeepClone.GENERAL_INFORMATION.NAME
    delete valuesDeepClone.GENERAL_INFORMATION.EQUIPMENT_TYPE
    delete valuesDeepClone.point
    delete valuesDeepClone.ATTACHMENT
    delete valuesDeepClone.objectElementFieldsDto
    delete valuesDeepClone.objectFields
    delete valuesDeepClone.path
    delete valuesDeepClone.elementsInfo
    const fields = Object.keys(valuesDeepClone).reduce((accumulator, rootName) => {
      if (typeof valuesDeepClone[rootName] !== 'object') {
        return accumulator
      }
      const childField = Object.keys(valuesDeepClone[rootName] || {}).reduce((buffer, childName) => {
        if (childName === 'customFields') {
          const customFields = get(valuesDeepClone, `${rootName}.customFields`, []).reduce((customFieldsAccumulator, element) => {
            if (element.fieldName && element.value && !element.remove) {
              return [
                ...customFieldsAccumulator,
                {
                  ...element,
                  fieldName: element.fieldName,
                  value: element.value,
                  passportBlock: rootName,
                },
              ]
            }
            return customFieldsAccumulator
          }, [])
          return [
            ...buffer,
            ...customFields,
          ]
        }
        return [
          ...buffer,
          {
            fieldName: `${rootName}.${childName}`,
            value: get(valuesDeepClone, `${rootName}.${childName}`, null),
            passportBlock: rootName,
          },
        ]
      }, [])
      return [
        ...accumulator,
        ...childField,
      ]
    }, [])
    const formattedFields = installationFormatFields(fields)
    const formattedFieldsWithFilterOutEmptyFields = formattedFields.filter((el) => el.value)
    const body = {
      name: objectElementName,
      objectIdOuter: objectId,
      point: {
        latitude,
        longitude,
      },
      parentId,
      parentName,
      objectFields: formattedFieldsWithFilterOutEmptyFields,
      integrationType,
      installationType: type,
      objectState: NOT_VERIFIED,
    }
    const { data } = yield request({
      url: INSTALLATIONS_OBJECT_ENDPOINT,
      method: 'post',
      body,
    })
    if (photoFile) {
      yield put(requestUploadInstallationObjectFile({
        elementId: data.id,
        file: photoFile,
        title: photoFile.name,
        fileType: 'PHOTO',
      }))
      yield take([UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS, UPLOAD_INSTALLATION_OBJECT_FILE.ERROR])
    }
    if (PDFFile) {
      yield put(requestUploadInstallationObjectFile({
        elementId: data.id,
        file: PDFFile,
        title: PDFFile.name,
        fileType: 'DOCUMENT',
      }))
      yield take([UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS, UPLOAD_INSTALLATION_OBJECT_FILE.ERROR])
    }
    setAlertConfig({})
    const {
      rootId, path, parentGeoZoneId, FILTER,
    } = formValues

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.id)
    updateNodeCount(tree, path, 'add')
    const selectedNodePath = get(node, 'options.path', '')
    tree.handlers.setSelectedByPath(selectedNodePath)
    setUrlFormValues({
      id: data.id,
      type: OBJECT_ELEMENT,
      rootId,
      parentGeoZoneId,
      parentTreeId: data.parentId,
      path: `${path}/${data.id}`,
      FILTER,
    })
    yield put(setGlobalLoading(false))
    toast({
      title: 'Инсталляция завершена',
      type: 'success',
      description: 'Объект добавлен в систему.',
    })
    yield put(requestGetPinsAndZonesAndTelemetry({
      includeAll: true,
      parentTreeId: formValues.path.split('/')[2],
    }))
    yield put(successCreateObject())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const errors = get(error, 'response.data.details', []).reduce((accumulator, element) => {
      const errorMessageAndLocation = element.message.split(': ')
      return {
        ...accumulator,
        [errorMessageAndLocation[1]]: errorsMapper[errorMessageAndLocation[0]] || errorMessageAndLocation[0],
      }
    }, {})
    const errorObject = {}
    Object.keys(errors).map((errorLocation) => {
      set(errorObject, errorLocation, errors[errorLocation])
      return errorLocation
    })
    setErrors(errorObject)
    console.log('function*createObjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(CREATE_OBJECT.REQUEST, createObjectSaga)
}
