import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import LiveTime from '@/components/icons/livetime'
import numberWithSpaces from '@/helpers/numberWithSpaces'


export const liveTimeFields = [
  {
    id: 'name',
    name: <Lang id="tableFields.liveTime.name" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'geoZoneName',
    name: <Lang id="tableFields.liveTime.geoZoneName" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'address',
    name: <Lang id="tableFields.liveTime.address" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'workTime',
    name: <Lang id="tableFields.liveTime.workTime" />,
    value: 'ASC',
    customCell: (props) => (numberWithSpaces(props.value)),
    type: 'number',
  },
  {
    id: 'currentLifePercent',
    name: <Lang id="tableFields.liveTime.currentLifePercent" />,
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'startDateExploitation',
    name: <Lang id="tableFields.liveTime.startDateExploitation" />,
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'dateEndExploitation',
    name: <Lang id="tableFields.liveTime.dateEndExploitation" />,
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'currentDateExploitation',
    name: <Lang id="tableFields.liveTime.currentDateExploitation" />,
    value: 'ASC',
    type: 'number',
  },
]

export const liveTimeExportOptions = [
  {
    name: 'EXCEL',
    value: 'excel',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]
