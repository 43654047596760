import {
    put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateGetObjectConnectedIdentifiers } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_OBJECT_CONNECTED_IDENTIFIERS } from '@/store/actions/installation'
import {
    errorGetObjectConnectedIdentifiers,
    successGetObjectConnectedIdentifiers
} from '@/store/actions/installation/getObjectConnectedIdentifiers'

function* getObjectConnectedIdentifiers({ payload: { parentId } }) {
    try {
        const { data } = yield request({
            url: generateGetObjectConnectedIdentifiers({
                params:{
                    parentId
                }}),
            method: 'get',
        })
        const formattedData = data.map(item => {
            return {
                value: item.identity,
                title: item.identity
            }
        })
        yield put(successGetObjectConnectedIdentifiers(formattedData))
    } catch (error) {
        yield put(errorGetObjectConnectedIdentifiers({}))
        console.log('function*getObjectConnectedIdentifiers -> error', error)
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить связанные идентификаторы \nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_OBJECT_CONNECTED_IDENTIFIERS.REQUEST, getObjectConnectedIdentifiers)
}