import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
 PPR_TABLE,
 GET_PPR_TABLE,
 SET_YEAR,
 SET_STATUS,
 SET_DATES_RANGE,
} from '@/store/actions/Ppr'
import { fields } from '@/constants/tablesConfig/Ppr'

const initialState = {
  data: [],
  table: generateDefaultTableState(fields),
  loading: false,
  year: null,
  status: null,
  dates: {
    start: null,
    end: null,
  },
}

export const reducer = handleActions(
  {
    [GET_PPR_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      loading: false,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_PPR_TABLE.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_PPR_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [SET_YEAR]: (state, { payload }) => ({
      ...state,
      year: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_STATUS]: (state, { payload }) => ({
      ...state,
      status: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_DATES_RANGE]: (state, { payload }) => ({
      ...state,
      dates: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    ...generateDefaultTableReducer(PPR_TABLE),
  },
  initialState,
)

export default reducer
