import control_cupboard from './control_cupboard.jpg'
import utility_pole from './utility_pole.jpg'
import line from './line.jpg'
import lamp_module from './lamp_module.jpg'
import light_fixture from './light_fixture.jpg'

import { CONTROL_CUPBOARD, LIGHT_FIXTURE, LINE, LAMP_MODULE, UTILITY_POLE } from '@/constants/instalationPassport/types'

export const defaultImage = {
  [CONTROL_CUPBOARD]: control_cupboard,
  [UTILITY_POLE]: utility_pole,
  [LINE]: line,
  [LAMP_MODULE]: lamp_module,
  [LIGHT_FIXTURE]: light_fixture,
}