import React, { useState, useMemo } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { ONLY_NUMBERS } from '@/constants/validationFields'
import CoreIcons from '@/components/icons/core'
import {
  TextFieldStyled,
  Label,
  FieldStyled,
  Error,
  ControlsContainer,
} from './styles'

const TextControl = ({
  dark,
  className,
  icon,
  label,
  type,
  format,
  onChange,
  onBlur,
  name,
  disabled,
  fieldIcon,
  placeholder,
  value,
  error,
  pattern,
  mask,
  beforeMaskedValueChange,
  customError,
  controls: Controls,
  charLimit,
  errorMessage,
  resetButton,
  resetField,
  ...ownProps
}) => {
  const [focused, setFocused] = useState(false)
  const [filled, setFilled] = useState(!!value || value >= 0)
  const Icon = useMemo(() => icon, [icon])
  const isError = useMemo(() => !!error, [error])

  const handleChangeWithCharsLimit = (fieldValue) => {
    if (!charLimit || fieldValue.length <= charLimit) {
      onChange(fieldValue)
    }
  }

  const handleChange = (event) => {
    const { value: newValue } = event.target
    const valueByPattern = pattern
      ? ((newValue || '').match(new RegExp(pattern, 'g')) || []).join('')
      : newValue
    if (format === 'number') {
      const valueForSave = (valueByPattern.match(ONLY_NUMBERS) || []).join('')
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else if (format === 'float') {
      const valueForSave = valueByPattern.replace(/^\[(.|,)]/, '').replace(/[^0-9(.|,)]/g, '').replace(/(\..*)\[(.|,)]/g, '$1')
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else if (format === 'period') {
      const valueForSave = (valueByPattern.match(ONLY_NUMBERS) || []).join('').replace(/^0+/, '')
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else if (format === 'text') {
      setFilled(!!(valueByPattern))
      handleChangeWithCharsLimit(valueByPattern)
    } else if (format === 'angle') {
      const middleValue = (newValue.match(ONLY_NUMBERS) || [])
      const valueForSave = middleValue.length > 0 ? `${middleValue.join('')}°` : ''
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else if (format === 'plusMinusCelsium') {
      const middleValue = (newValue.match(ONLY_NUMBERS) || [])
      const valueForSave = middleValue.length > 0 ? `±${middleValue.join('')}°C` : ''
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else if (format === 'range') {
      const filterValue = newValue.replace(/[^0-9]/g, '')
      const firesPart = filterValue.slice(0, 3) 
      const secondPart = filterValue.slice(3, 6)
      const valueForSave = valueByPattern.length > 0
        ? `${firesPart}${firesPart.length > 2 ? '-' : ''}${secondPart}`
        : ''
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else if (format === 'temperatureRange') {
      const lastInputChar = (newValue[newValue.length - 1] || '').replace(/[^0-9 °]/g, false)
      if (lastInputChar === 'false') {
        return null
      }
      const splitValues = newValue.substring(0, newValue.length - 1).split(' +').map((element) => element.replace(/[^0-9]/g, ''))
      let firstPart = splitValues[0] || ''
      let secondPart = splitValues[1] || ''
      if (lastInputChar !== '°') {
        if (firstPart.length < 3 && lastInputChar !== ' ' && secondPart.length === 0) {
          firstPart = `${firstPart}${lastInputChar}`
        } else if (firstPart.length > 0 && lastInputChar === ' ' && secondPart.length === 0) {
          secondPart = '0'
        } else if (firstPart.length === 3 && lastInputChar !== ' ' && secondPart.length === 0) {
          secondPart = lastInputChar
        } else if (firstPart.length > 0 && lastInputChar !== ' ' && secondPart.length !== 0 && secondPart.length < 3) {
          if (secondPart === '0') {
            secondPart = lastInputChar
          } else {
            secondPart = `${secondPart}${lastInputChar}`
          }
        }
      } else {
        let secondElementWasRemove = false
        if (secondPart.length !== 0) {
          secondPart = secondPart.length === 1
            ? ''
            : secondPart.substring(0, secondPart.length - 1)
          secondElementWasRemove = true
        }
        if (firstPart.length !== 0 && secondPart.length === 0 && !secondElementWasRemove) {
          if (firstPart.length === 1) {
            setFilled(false)
            handleChangeWithCharsLimit('')
            return null
          }
          firstPart = firstPart.length === 1
            ? ''
            : firstPart.substring(0, firstPart.length - 1)
        }
      }
      const valueForSave = newValue.length > 0 || newValue !== ' '
        ? `-${firstPart}${secondPart.length > 0 ? ' +' : ''}${secondPart}°C`
        : ''
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    } else {
      setFilled(false)
      handleChangeWithCharsLimit('')
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur(event)
  }

  return (
    <TextFieldStyled
      dark={dark}
      className={className}
      fieldIcon={fieldIcon || !!icon}
      focused={focused}
      filled={filled}
      disabled={disabled}
      isError={isError || errorMessage}
      resetButton={resetButton}
    >
      <Label htmlFor={name}>
        {icon && <Icon />}
      </Label>
      {Controls
      && (
        <ControlsContainer>
          {Controls}
        </ControlsContainer>
      )}
      <FieldStyled
        name={name}
        {...ownProps}
        mask={mask}
        beforeMaskedValueChange={beforeMaskedValueChange}
        type={type}
        value={value}
        isError={isError || errorMessage}
        disabled={disabled}
        placeholder={placeholder || label}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      />
      {resetButton && !disabled && value && (
        <Label reset onClick={resetField}>
          <CoreIcons.ResetInputIcon />
        </Label>
      )}
      {customError
        ? null
        : (!!error || !!errorMessage) && (
          <Error>
            {<Lang id={error} /> ? <Lang id={error} /> : error || errorMessage}
          </Error>
        )}
    </TextFieldStyled>
  )
}

TextControl.propTypes = {
  className: pt.string,
  error: pt.string,
  pattern: pt.string,
  errorMessage: pt.string,
  mask: pt.string,
  icon: pt.oneOfType([pt.element, pt.object, pt.string]),
  label: pt.oneOfType([pt.string, pt.element]),
  placeholder: pt.oneOfType([pt.string, pt.element]),
  value: pt.oneOfType([pt.string, pt.number]),
  type: pt.string,
  format: pt.string,
  name: pt.string.isRequired,
  disabled: pt.bool,
  fieldIcon: pt.bool,
  customError: pt.bool,
  dark: pt.bool,
  onChange: pt.func,
  onBlur: pt.func,
  controls: pt.element,
  charLimit: pt.number,
  resetButton: pt.bool,
  resetField: pt.func,
}
TextControl.defaultProps = {
  className: '',
  pattern: '',
  errorMessage: '',
  mask: null,
  icon: null,
  label: null,
  placeholder: null,
  value: undefined,
  type: 'text',
  format: 'text',
  disabled: false,
  fieldIcon: false,
  customError: false,
  dark: false,
  error: null,
  onBlur: noop,
  onChange: noop,
  controls: null,
  charLimit: 256,
  resetButton: false,
  resetField: noop,
}

export default React.memo(TextControl)
