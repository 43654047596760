import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { OBJECTS_GROUPS_CREATE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { CREATE_OBJECTS_GROUP } from '@/store/actions/objectsGroups'
import { requestGetObjectsGroupsTree } from '@/store/actions/objectsGroups/getTree'
import { errorCreateObjectsGroup, successCreateObjectsGroup } from '@/store/actions/objectsGroups/createObjectsGroup'

function* createObjectsGroupSaga({ payload }) {
  const { values, onCancel } = payload
  try {
    yield put(setGlobalLoading(true))
    const body = {
      name: values.name,
      color: values.color,
      objects: values.objects.map(item => {
        return {
          id: item.id,
          installationType: item.installationType,
          state: item.state
        }
      })
    }

    const { data } = yield request({
      url: OBJECTS_GROUPS_CREATE_ENDPOINT,
      method: 'post',
      body,
    })

    if (data) {
      const toast = createNotifications()
      yield put(requestGetObjectsGroupsTree())
      yield put(setGlobalLoading(false))
      onCancel()
      toast({
        title: 'Создание группы',
        type: 'success',
        description: 'Группа объектов успешно создана.',
      })
      yield put(successCreateObjectsGroup())
    }
  } catch (error) {
    const toast = createNotifications()
    if (error.response.data.errorMessage === 'Group.name.already.exists') {
      toast({
        title: 'Ошибка операции!',
        description: 'Данное название уже существует',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    if (error.response.data.errorMessage === 'Group.color.already.exists') {
      toast({
        title: 'Ошибка операции!',
        description: 'Данный цвет уже занят',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    yield put(errorCreateObjectsGroup())
    yield put(setGlobalLoading(false))
    onCancel()
    console.log('function*createObjectGroupSaga -> error', error)
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось создать группу объектов. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(CREATE_OBJECTS_GROUP.REQUEST, createObjectsGroupSaga)
}
