import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { OBJECTS_GROUPS_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_OBJECTS_GROUP } from '@/store/actions/objectsGroups'
import { errorGetObjectsGroup, successGetObjectsGroup } from '@/store/actions/objectsGroups/getObjectsGroup'
import { GROUP, ROOT } from '@/constants/objectTypes'
import { INSTALLATION_SERVER_TO_LOCAL } from '@/constants/passportization/types'
import get from 'lodash/get'

function* getObjectsGroupSaga({ payload }) {
  const { id } = payload
  try {
    const { data } = yield request({
      url: OBJECTS_GROUPS_ENDPOINT(id),
      method: 'get',
    })
    const formattedData = {
      id: data.id,
      name: data.name,
      color: data.color,
      installationType: data.allowedScheduleInstallationType,
      objects: data.objects.map(item => {
        return {
          ...item,
          color: data.color,
          checked: true
        }
      })
    }

    const selectType = (element) => {
      if (!element.parentId) {
        return 'country'
      } else if (element.installationType === GROUP || !element.installationType || !INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]) {
        return 'UNKNOWN'
      } else {
        return element.installationType || INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]
      }
    }
    const pins = data.objects.map(element => (
      {
       ...element,
       location: [
         get(element, 'latitude', null),
         get(element, 'longitude', null),
       ],
       type: selectType(element),
       elementType: element.parentId ? element.treeNodeType : ROOT,
       parentGeoZoneId: element.parentId,
       parentTreeId: element.parentId,
       checked: true,
       color: data.color,
      })
    )
    yield put(successGetObjectsGroup({ card: formattedData, displayElements: pins }))
  } catch (error) {
    yield put(errorGetObjectsGroup())
    console.log('function*getObjectsGroupSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные карточки. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECTS_GROUP.REQUEST, getObjectsGroupSaga)
}
