export default {
  desktop: 'Рабочий стол',
  users: 'Пользователи',
  passportization: 'Паспортизация',
  alertManager: 'Уведомления',
  alarms: 'Журнал неисправностей',
  individualControl: 'Контроль',
  groupControl: 'Групповой контроль',
  scheduleManager: 'Менеджер расписаний',
  objectsGroups: 'Группы объектов',
  installation: 'Инсталляции',
  reportManager: 'Менеджер отчетов',
  energyConsumption: 'Энергопотребление',
  life: 'Срок службы',
  objects: 'Центр мониторинга',
  analyticsAlarms: 'Аналитика неисправностей',
  searchWidget: 'Расширенный поиск',
  controlCenter: 'Центр управления',
  equipmentReference: 'Справочники',
  operationAndTechnicalSupport: 'Служба поддержки',
  settings: 'Настройки системы',
  changeLog: 'Журнал изменений',
  foldMenu: 'Свернуть описание',
  menuTitle: 'Меню навигации',
  inventory: 'Инвентаризация',
  lightingObjects: 'Объекты освещения',
  analyticExpenses: 'Потребление ресурсов',
  activityLog: 'Журнал действий',
  ppr: 'ППР',
  pprMenuSidbar: 'ППР',
  advancedSearch: 'Расширенный поиск',
  carElectricStations: 'Зарядные автостанции',
  waterSupply: 'Водоснабжение',
  heatSupply: 'Теплоснабжение',
  smartRecycleBin: 'Контроль наполняемости мусорных баков',
  controlObject: 'Oбъекты контроля',
  mainScreenSettings: 'Настройки главного экрана',
  upperBlock: 'Верхний блок',
  mainApps: 'Основные приложения',
  dragMessage: 'Меняйте элементы местами, перетаскивая их за иконку справа. \nЭлементы можно добавлять или убирать с экрана, нажав на иконку слева.',
  usedElements: 'Используемые элементы',
  unusedElements: 'Неиспользуемые элементы',
  activateMessage: 'Для активации недоступных элементов обратитесь к администратору вашей организации',
  pin: 'Закрепить',
  unpin: 'Открепить',
  pinNode: 'Закрепить объект',
  pinNodeMessage: 'Это действие закрепит данный объект и открепит ранее закреплённый.',
  emptyPinNode: 'Нет закрепленных объектов',
  unpinNode: 'Открепить объект',
  unpinNodeMessage: 'Это действие снимет закрепление данного объекта.',
  statuses: {
    new: 'Новые',
    old: 'Просмотренные',
    all: 'Все статусы',
  },
  statusesPpr: {
    new: 'Не просрочен',
    old: 'Просрочен',
  },
  communicationStatus: 'Состояние связи',
  on: 'Вкл.',
  onLong: 'Включен',
  off: 'Выкл.',
  offLong: 'Выключен',
  online: 'На связи',
  offline: 'Не на связи',
  refreshed: 'Обновлено',
  unknown: 'Неизвестно',
  height: 'Высота',
  builtInRelay: 'Встроенное реле',
  driver: 'Драйвер 1-10',
  driverDali: 'Драйвер по протоколу DALI',
  min: 'Мин',
  max: ' Макс',
  working: 'Работает',
  notWorking: 'Не работает',
  onPhase: 'на фазе',
  globalPopup: {
    accept: 'Принять',
    save: 'Сохранить',
    cancel: 'Отмена',
    verify: 'Верифицировать',
    yes: 'да',
    no: 'нет',
  },
  selected: 'Выбрано',
  multipleSelected: 'Выбрано несколько',
  multipleSelectedMessage: 'Нельзя выбрать больше 20 значений в фильтре',
}
