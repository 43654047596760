import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'


export const GET_PPR_TABLE = createAction('@GET_PPR_TABLE')
export const GET_PPR_FILE = createAction('@/GET_PPR_FILE')
export const SET_YEAR = '@/PPR_SET_YEAR'
export const SET_STATUS = '@/PPR_SET_STATUS'
export const SET_DATES_RANGE = '@PPR_SET_DATES_RANGE'



export const PPR_TABLE = createTableActions('@PPR_TABLE')

export const setYear = (payload) => ({
  type: SET_YEAR,
  payload,
})

export const setStatus = (payload) => ({
  type: SET_STATUS,
  payload,
})

export const setDatesRange = (payload) => ({
  type: SET_DATES_RANGE,
  payload,
})