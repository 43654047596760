import { SET_SCHEDULE } from './index'

export const requestSetSchedule = (payload) => ({
  type: SET_SCHEDULE.REQUEST,
  payload,
})

export const successSetSchedule = (payload) => ({
  type: SET_SCHEDULE.SUCCESS,
  payload,
})

export const errorSetSchedule = (payload) => ({
  type: SET_SCHEDULE.ERROR,
  payload,
})