export default {
  ok: 'ОК',
  cancel: 'Отмена',
  login: 'Войти',
  logout: 'Выход',
  addToDesktop: 'Добавить на рабочий стол',
  install: 'Установить',
  clear: 'Понятно',
  apply: 'Применить',
  all: 'Выбрать все',
  close: 'Закрыть'
}
