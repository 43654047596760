import { all } from 'redux-saga/effects'

import getEquipmentSaga from './getEquipment'
import getObjectDataSaga from './getObjectData'

import addPassportFileRoot from './addPassportFile'
import deletePassportFileRoot from './deletePassportFile'
import deletePassportPhotoRoot from './deletePassportPhoto'
import addPassportPhotoRoot from './addPassportPhoto'
import getPassportFileRoot from './getPassportFile'
import getPassportPhotoRoot from './getPassportPhoto'
import exportPassportFileRoot from './exportPassportFile'
import updatePassportRoot from './updatePassport'
import getPassportizationDataRoot from './getPassportizationData'

export default function* root() {
  yield all([
    getEquipmentSaga(),
    getObjectDataSaga(),

    addPassportFileRoot(),
    deletePassportFileRoot(),
    deletePassportPhotoRoot(),
    addPassportPhotoRoot(),
    getPassportFileRoot(),
    getPassportPhotoRoot(),
    exportPassportFileRoot(),
    updatePassportRoot(),
    getPassportizationDataRoot(),
  ])
}
