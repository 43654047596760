import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE" />,
    showInDashboard: true,
    selector: 'EQUIPMENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
    showInDashboard: true,
    selector: 'objectIdOuter',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
    showInDashboard: true,
    selector: 'ROOT_OBJECT',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.BASE_STATION_IDENTIFIER" />,
    showInDashboard: true,
    selector: 'BASE_STATION_IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LAMP_TYPE" />,
    showInDashboard: true,
    selector: 'LAMP_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER" />,
    showInDashboard: true,
    selector: 'LIGHTING_CONTROL_BOX_IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER" />,
    showInDashboard: true,
    selector: 'UTILITY_POLE_IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
    showInDashboard: true,
    selector: 'PARENT_IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
    showInDashboard: true,
    selector: 'PARENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
    showInDashboard: true,
    selector: 'MODEL_MARK',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
    showInDashboard: false,
    selector: 'PROCEDURE_SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SURVEY_DATA.OBJECT_ATTRIBUTE" />,
    showInDashboard: true,
    selector: 'OBJECT_ATTRIBUTE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_FIXTURE_STATE" />,
    showInDashboard: true,
    selector: 'LIGHT_FIXTURE_STATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.CONSOLE" />,
    showInDashboard: true,
    selector: 'CONSOLE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
    showInDashboard: true,
    selector: 'POWER_CONSUMPTION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.location.latitude" />,
    showInDashboard: true,
    selector: 'latitude',
  },
  {
    passportParameter: <Lang id="passportization.fields.location.longitude" />,
    showInDashboard: true,
    selector: 'longitude',
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_TEMPERATURE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_VOLTAGE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT" />,
    showInDashboard: true,
    selector: 'LIGHT_OUTPUT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE" />,
    showInDashboard: true,
    selector: 'COLOUR_TEMPERATURE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX" />,
    showInDashboard: true,
    selector: 'COLOUR_RENDERING_INDEX',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LAMP_EFFICIENCY" />,
    showInDashboard: true,
    selector: 'LAMP_EFFICIENCY',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.SCATTERING_ANGLE" />,
    showInDashboard: true,
    selector: 'SCATTERING_ANGLE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE" />,
    showInDashboard: true,
    selector: 'LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.FLICKER_PERCENT" />,
    showInDashboard: true,
    selector: 'FLICKER_PERCENT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'EXPLOITATION_END_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIGHT_FIXTURE_WORK_RESOURCE" />,
    showInDashboard: true,
    selector: 'LIGHT_FIXTURE_WORK_RESOURCE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
    showInDashboard: true,
    selector: 'ORGANIZATION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
    showInDashboard: true,
    selector: 'OWNER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
    showInDashboard: true,
    selector: 'GUARANTEE_PERIOD',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
    showInDashboard: true,
    selector: 'LIFE_TIME',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
    showInDashboard: true,
    selector: 'PROJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
    showInDashboard: true,
    selector: 'REGION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
    showInDashboard: true,
    selector: 'CITY',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY" />,
    showInDashboard: true,
    selector: 'LIGHTING_CATEGORY',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.IMPORTANCE" />,
    showInDashboard: true,
    selector: 'IMPORTANCE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.WORK_MODE" />,
    showInDashboard: true,
    selector: 'WORK_MODE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SURVEY_DATA.LAMP_INSTALLATION_HEIGHT" />,
    showInDashboard: true,
    selector: 'LAMP_INSTALLATION_HEIGHT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SURVEY_DATA.LAMP_ANGLE" />,
    showInDashboard: true,
    selector: 'LAMP_ANGLE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SURVEY_DATA.OVERHANG_HEIGHT" />,
    showInDashboard: true,
    selector: 'OVERHANG_HEIGHT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SURVEY_DATA.OVERHANG_LENGTH" />,
    showInDashboard: true,
    selector: 'OVERHANG_LENGTH',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SURVEY_DATA.UTILITY_POLE_LAMP_POSITION" />,
    showInDashboard: true,
    selector: 'UTILITY_POLE_LAMP_POSITION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.INSPECTION_DATE" />,
    showInDashboard: true,
    selector: 'INSPECTION_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
    showInDashboard: true,
    selector: 'BYPASS_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
    showInDashboard: true,
    selector: 'MAINTENANCE_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
    showInDashboard: true,
    selector: 'REPAIR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.CAPITAL_REPAIR_DATE" />,
    showInDashboard: true,
    selector: 'CAPITAL_REPAIR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
    showInDashboard: true,
    selector: 'GUARANTEE_EXPIRATION_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.inner.photo" />,
    showInDashboard: true,
    selector: 'photo',
  },
  {
    passportParameter: <Lang id="passportization.fields.inner.file" />,
    showInDashboard: true,
    selector: 'DOCUMENT_TITLE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.plan.date" />,
    showInDashboard: true,
    selector: 'factDate',
  },
  {
    passportParameter: <Lang id="passportization.fields.fact.date" />,
    showInDashboard: true,
    selector: 'planDate',
  },
]
