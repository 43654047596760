import React, { useMemo, useContext, useCallback, useRef, useEffect } from 'react'
import { treeHandlers } from 'react-hyper-tree'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { ThemeContext } from 'styled-components'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import Loader from '@/components/blocks/Loader'
import viewTreeIcons from '@/constants/viewTree'
import ArrowIcon from '@/components/icons/maps/ArrowIcon'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks'
import {
  TreeNodeArrow,
  TreeNode,
  TreeNodeIcon,
  TreeNodeTitle,
  TreeNodeObjects,
  TreeNodeInfo,
  TreeNodeIconAndInfo,
  TreeNodeToolTip
} from './styles'
import {
  ERROR,
  INFO,
  INSTALLED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  NOT_VERIFIED,
  OFF,
  OK,
  UNINSTALLED,
  VERIFIED,
  WARNING
} from '@/constants/objectStatuses'
import { GROUP, INTEGRATION, OBJECT_ELEMENT } from '@/constants/objectTypes'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import CheckBox from '@/components/controls/CheckBox'

const deleteNodeChildren = (node = {}, defaultParameters = {}) => ({
  ...node,
  children: null,
  data: {
    ...(node.data || {}),
    children: null,
  },
  options: {
    ...(node.options || {}),
    parent: null,
  },
  ...defaultParameters,
})

export const STATE_TO_STATUS = {
  OK,
  WARNING,
  OFF,
  NO_PROBLEM: 'NO_PROBLEM',
  ERROR: 'ERROR',
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
  UNDEFINED: INFO,
  undefined: undefined,
  null: INFO,
  NO_CONNECTION: ERROR,
}

const TreeNodeContainer = ({
  node,
  onToggle,
  onSelect,
  onSelectNode,
  isMonitoring,
  selectedNode,
  isSchedule,
  id,
  intl,
  checkTree,
  onCheckNode,
}) => {
  const ref = useRef(null)
  const isSelectedNode = node.id === selectedNode?.id

  useEffect(()=> {
    if (isSelectedNode) {
      ref.current.scrollIntoViewIfNeeded()
    }
  },[ref, isSelectedNode])


  const Icon = viewTreeIcons[node.data.type] || viewTreeIcons[node.data.treeNodeType]
  const theme = useContext(ThemeContext)
  const nodeStatus = useMemo(() => {
    return node.data.treeNodeType === OBJECT_ELEMENT ? STATE_TO_STATUS[isMonitoring ? node.data.telemetryState : node.data.state] : ''
  }, [node, isMonitoring])

  const nodeForSave = useMemo(() => ({
    ...node.data,
    original: deleteNodeChildren(node, {
      options: {
        ...node.options,
        parent: deleteNodeChildren(node.options.parent),
      },
    }),
  }), [node])

  const handleSelect = useCallback((event) => {
    event.stopPropagation()
    onSelect(event)    
    onSelectNode(nodeForSave, 'click')
  }, [onSelect, onSelectNode, nodeForSave])

  const handleCheck = useCallback(() => {
    node.data.checked = !node.data.checked
    onCheckNode([node.data], true)
  }, [node, onCheckNode])

  const handleChildrenCheck = useCallback(() => {
    node.data.childrenChecked = !node.data.childrenChecked
    const children = node.children.map(child => {
      return {
        ...child.data,
        checked: node.data.childrenChecked
      }
    })
    const tree = treeHandlers.trees['objects-groups-form-tree']
    children.forEach(item => {
      tree.handlers.setNodeData(item.id, item)
    })
    onCheckNode(children, true)
  }, [node, onCheckNode])

  const handleToggle = useCallback((event) => {
    event.stopPropagation() 
    onToggle(event)
    if (checkTree) {
      const tree = treeHandlers.trees['objects-groups-form-tree']
      const formattedNode = {
        ...nodeForSave,
        childrenChecked: node.children.length ? node.children.every(child => child.data.checked) : false,
      }
      tree.handlers.setNodeData(node.data.id, formattedNode)
      onSelectNode(formattedNode, 'toggle')
      return
    }
    onSelectNode(nodeForSave, 'toggle')
  }, [onToggle, onSelectNode, nodeForSave, checkTree, node])

  const childrenCount = node.data.childrenCount

  const returnName = ({name, type, nodeType}) => {
    // const limit = 20
    const treeNodeWidth = document.getElementById('treeNode')?.clientWidth
    const treeNodeInfoWidth = document.getElementById('treeNodeInfo')?.clientWidth
    return (
      <>
        <PortalTooltip
              title={name}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <TreeNodeTitle
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  width={treeNodeWidth-treeNodeInfoWidth}
                  type={type === INTEGRATION || type === OBJECT_ELEMENT || nodeType === OBJECT_ELEMENT }
                  groupsTree={id.includes('groups')}
                >
                  {name}
                </TreeNodeTitle>
              )}
            />
        {/* {((name || '').length > limit)
          ? (
            <PortalTooltip
              title={name}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <TreeNodeTitle
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  width={treeNodeWidth-treeNodeInfoWidth}
                  type={type === INTEGRATION || type === OBJECT_ELEMENT}
                >
                  {name}
                </TreeNodeTitle>
              )}
            />
          )
          : (
            <TreeNodeTitle>
              {name}
            </TreeNodeTitle>
          )} */}
      </>
    )
  }

  const renderChildrenCount = () => {
    if (id === 'PPR-tree') {
      return null
    }

    return !!childrenCount && `${isSchedule ? intl.messages['globalNames.tree.schedules'] : intl.messages['globalNames.tree.objects']}: ${ childrenCount || intl.messages['globalNames.tree.unknown']}`
  }

  const hasChildrenTest = () => {
    if (id === 'PPR-tree' && node.data.treeNodeType === 'INTEGRATION') {
      return false
    }
    return node.children.length > 0 || node.data.childrenCount > 0
  }
  return (
    <TreeNode isRoot={node.options.root} ref={isSelectedNode ? ref : null} id='treeNode'>
      <TreeNodeIconAndInfo onClick={handleSelect} id='treeNodeInfo'>
        {checkTree && node.data.nodeType === OBJECT_ELEMENT && node.data.installationType !== GROUP &&
          <CheckBox
            id="nodeCheck"
            value={node.data.checked}
            onChange={handleCheck}
          />
        }
        {Icon &&
          <PortalTooltip
            title={<Lang id={`globalNames.objectTypes.${ node.data.type || node.data.treeNodeType }`} />}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <TreeNodeToolTip
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                <TreeNodeIcon>
                  {nodeStatus
                    ? <Icon color={STATUS_COLORS[nodeStatus]} />
                    : <Icon color={node.data.color ? node.data.color : theme.colors.colors.default} />}
                </TreeNodeIcon>
              </TreeNodeToolTip>
            )}
          />
        }
        {checkTree && node.data.nodeType === OBJECT_ELEMENT && !!node.children.length && !node.children.some(child => child.data.type === GROUP) &&
            <CheckBox
              id="childrenCheck"
              value={node.data.childrenChecked}
              onChange={handleChildrenCheck}
              childrenSelect
            />
        }
        <TreeNodeInfo>
          {returnName({name: node.data.name, type: node.data.treeNodeType, nodeType: node.data.nodeType})}
          <TreeNodeObjects dark={node.options.selected}>
          {renderChildrenCount()}
          </TreeNodeObjects>
        </TreeNodeInfo>
      </TreeNodeIconAndInfo>
      {hasChildrenTest() && !node.options.loading && (
        <TreeNodeArrow transform={node.options.opened} onClick={handleToggle}>
          <ArrowIcon />
        </TreeNodeArrow>
      )}
      {node.options.loading && (
        <Loader fill />
      )}
    </TreeNode>
  )
}

TreeNodeContainer.propTypes = {
  onToggle: pt.func,
  onSelect: pt.func,
  onSelectNode: pt.func,
  node: pt.shape({
    children: pt.arrayOf([pt.object]),
    data: pt.shape({
      name: pt.string,
      type: pt.string,
      status: pt.string,
      childrenCount: pt.number,
    }),
    options: pt.shape({
      selected: pt.bool,
      loading: pt.bool,
      opened: pt.bool,
      root: pt.bool,
    }),
  }),
}

TreeNodeContainer.defaultProps = {
  onToggle: noop,
  onSelect: noop,
  onSelectNode: noop,
  node: {},
}

export default injectIntl(TreeNodeContainer)
