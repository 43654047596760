import React, { useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import useReduxTable from '@/hooks/useReduxTable'
import { ACTION_LOG_TABLE } from '@/store/actions/actionLog'
import { getTableParameters } from '@/store/selectors/actionLog'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import UsersIcons from '@/components/icons/users'
import DutyTable from '@/components/blocks/DutyTable'
import TextControl from '@/components/controls/TextControl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import {
  fields,
  data as tableData,
  exportOptions,
} from '@/constants/tablesConfig/actionLog'
import {
  Main,
  Content,
} from './styles'
import { fakeTree } from '@/data/mapTree/zoneConfig'

const AlarmsManager = ({
  data,

  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  requestAlarmManagerTable,
  requestAlarmManagerFile,
  intl,
  globalFilters
}) => {
  const [search, setSearch] = useState(null)

  useHtmlTitle(intl.messages['menu.activityLog'])

  const tableProps = useReduxTable(ACTION_LOG_TABLE, getTableParameters)

  const handleSearchChange = (value) => {
    setSearch(value)
  }

  return (
    <Main>
      <Content>
        <PageSidebar
          onIconClick={noop}
          title={intl.messages['menu.activityLog']}
          treeId={'fake-tree'}
          treeData={fakeTree}
          searchQuery={search}
          globalFilters={globalFilters}
          headerContent={(
            <Lang id="usersPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={handleSearchChange}
                  value={search}
                />
              )}
            </Lang>
          )}
        />
        <DutyTable
          {...tableProps}
          fields={fields}
          exportOptions={exportOptions}
          data={data}
          rowSelector={setSelectedElement}
          setEventTypes={setEventTypes}
          setStatusTypes={setStatusTypes}
          requestAlarmManagerTable={requestAlarmManagerTable}
          onFileSelect={requestAlarmManagerFile}
        />
      </Content>
    </Main>
  )
}

AlarmsManager.propTypes = {
  data: pt.arrayOf(pt.object),

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAlarmManagerTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsManager.defaultProps = {
  data: tableData,

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAlarmManagerTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsManager
