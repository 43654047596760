import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import cookie from 'react-cookies'
import qs from 'query-string'
import request from '@/helpers/axios'
import { SIGN_IN_URL } from '@/constants/routes'
import { LOGOUT_API_URL } from '@/constants/apiRoutes'
import { getRouterLocation, getRouterUrl } from '@/store/selectors/router'
import { getClient } from '@/store/selectors/socket'
import { USER_LOGOUT } from '@/store/actions/user'
import {
  successUserLogout,
  errorUserLogout,
} from '@/store/actions/user/logout'
import createNotifications from '@/helpers/notification'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'

export function* redirectToLoginPage() {
  try {
    const url = yield select(getRouterUrl)
    const { search } = yield select(getRouterLocation)
    const queryObject = qs.parse(search)
    const encodedUrl = encodeURIComponent(url)
    cookie.remove(SLS24_ACCESS_TOKEN, { path: '/' })
    if (!queryObject.redirect) {
      yield put(push({
        pathname: SIGN_IN_URL,
        search: qs.stringify({ redirect: encodedUrl }),
      }))
    }
  } catch (error) {
    console.log('TCL: function*redirectToLoginPage -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

function* userLogoutSaga() {
  const client = yield select(getClient)

  try {
    yield request({
      url: LOGOUT_API_URL,
      method: 'delete',
    })

    client.disconnect()

    cookie.remove(
      SLS24_ACCESS_TOKEN,
      { path: '/' },
    )

    yield redirectToLoginPage()
    yield put(successUserLogout())
  } catch (error) {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при попытке выйти из системы!
Повторите попытку позже`,
      type: 'error',
    })
    yield redirectToLoginPage()
    yield put(errorUserLogout())
  }
}

export default function* root() {
  yield takeLatest(USER_LOGOUT.REQUEST, userLogoutSaga)
}
