import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
    showInDashboard: true,
    selector: 'objectIdOuter',
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE" />,
    showInDashboard: true,
    selector: 'EQUIPMENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.DEVICE_SERIAL_NUMBER" />,
    showInDashboard: true,
    selector: 'DEVICE_SERIAL_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.METER_SN" />,
    showInDashboard: true,
    selector: 'METER_SN',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
    showInDashboard: true,
    selector: 'MODEL_MARK',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
    showInDashboard: true,
    selector: 'PROCEDURE_SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.CONN_TYPE" />,
    showInDashboard: true,
    selector: 'CONN_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SWVER" />,
    showInDashboard: true,
    selector: 'SWVER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID" />,
    showInDashboard: true,
    selector: 'ICCID',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID1" />,
    showInDashboard: true,
    selector: 'ICCID1',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID2" />,
    showInDashboard: true,
    selector: 'ICCID2',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.TIME_ZONE" />,
    showInDashboard: true,
    selector: 'TIME_ZONE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS" />,
    showInDashboard: true,
    selector: 'NUMBER_MEASUREMENT_CHANNELS',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM" />,
    showInDashboard: true,
    selector: 'NUMBER_CONTROLLED_HEATING_SYSTEM',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW" />,
    showInDashboard: true,
    selector: 'MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE" />,
    showInDashboard: true,
    selector: 'TEMPERATURE_MEASUREMENT_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN" />,
    showInDashboard: true,
    selector: 'MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE" />,
    showInDashboard: true,
    selector: 'PRESSURE_MEASUREMENT_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE" />,
    showInDashboard: true,
    selector: 'DC_POWER_SUPPLY_VOLTAGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT" />,
    showInDashboard: true,
    selector: 'LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'EXPLOITATION_END_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
    showInDashboard: false,
    selector: 'ORGANIZATION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
    showInDashboard: false,
    selector: 'OWNER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
    showInDashboard: true,
    selector: 'GUARANTEE_PERIOD',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
    showInDashboard: true,
    selector: 'LIFE_TIME',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.atmosphereTemperature" />,
    showInDashboard: true,
    selector: 'atmosphereTemperature',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.atmospherePressure" />,
    showInDashboard: true,
    selector: 'atmospherePressure',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.conventionalStraitPipeline" />,
    showInDashboard: true,
    selector: 'conventionalStraitPipeline',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.conventionalReversPipeline" />,
    showInDashboard: true,
    selector: 'conventionalReversPipeline',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.temperatureInPipe" />,
    showInDashboard: true,
    selector: 'temperatureInPipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.temperatureInReversePipe" />,
    showInDashboard: true,
    selector: 'temperatureInReversePipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.totalMassInPipe" />,
    showInDashboard: true,
    selector: 'totalMassInPipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.totalMassInReversePipe" />,
    showInDashboard: true,
    selector: 'totalMassInReversePipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.massFlowInPipe" />,
    showInDashboard: true,
    selector: 'massFlowInPipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.massFlowInReversePipe" />,
    showInDashboard: true,
    selector: 'massFlowInReversePipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.volumetricFlowInPipe" />,
    showInDashboard: true,
    selector: 'volumetricFlowInPipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.volumetricFlowInReversePipe" />,
    showInDashboard: true,
    selector: 'volumetricFlowInReversePipe',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.straitPipelineVolumeConsumption" />,
    showInDashboard: true,
    selector: 'straitPipelineVolumeConsumption',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.reversePipelineVolumeConsumption" />,
    showInDashboard: true,
    selector: 'reversePipelineVolumeConsumption',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.consumedVolume" />,
    showInDashboard: true,
    selector: 'consumedVolume',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.energy" />,
    showInDashboard: true,
    selector: 'energy',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.heatCosts" />,
    showInDashboard: true,
    selector: 'heatCosts',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.economicImportanceOfOverheat" />,
    showInDashboard: true,
    selector: 'economicImportanceOfOverheat',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.detectionOfUnderheat" />,
    showInDashboard: true,
    selector: 'detectionOfUnderheat',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.detectionOfOverheat" />,
    showInDashboard: true,
    selector: 'detectionOfOverheat',
  },
  {
    passportParameter: <Lang id="passportization.fields.technicalLimitations.workTime" />,
    showInDashboard: true,
    selector: 'workTime',
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
    showInDashboard: true,
    selector: 'PROJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
    showInDashboard: true,
    selector: 'REGION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
    showInDashboard: true,
    selector: 'CITY',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
    showInDashboard: true,
    selector: 'STREET',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
    showInDashboard: true,
    selector: 'HOME_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.CHECKING_DATE" />,
    showInDashboard: true,
    selector: 'CHECKING_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
    showInDashboard: true,
    selector: 'BYPASS_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
    showInDashboard: true,
    selector: 'MAINTENANCE_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
    showInDashboard: true,
    selector: 'REPAIR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
    showInDashboard: true,
    selector: 'GUARANTEE_EXPIRATION_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.inner.photo" />,
    showInDashboard: true,
    selector: 'photo',
  },
  {
    passportParameter: <Lang id="passportization.fields.inner.file" />,
    showInDashboard: false,
    selector: 'DOCUMENT_TITLE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.plan.date" />,
    showInDashboard: true,
    selector: 'factDate',
  },
  {
    passportParameter: <Lang id="passportization.fields.fact.date" />,
    showInDashboard: true,
    selector: 'planDate',
  },
]
