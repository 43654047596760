export default {
    actionLog: {
        action: 'Действие',
        object: 'Объект',
        geoZone: 'Геозона',
        date: 'Дата',
        time: 'Время',
        problem: 'Сбои в работе',
    },
    alarmManager: {
        objectName: 'Объект',
        geoZoneName: 'Геозона',
        address: 'Адрес',
        alertState: 'Событие',
        description: 'Описание события',
        occurrenceDate: 'Дата возникновения',
        decisionDate: 'Дата решения',
        statePeriod: 'Период состояния',
    },
    analyticsAlarms: {
        objectName: 'Объект',
        address: 'Адрес',
        containWarning: 'Предупреждение',
        containFault: 'Отказ',
        description: 'Описание события',
        currentLifePercent: '% выработки ресурса',
        occurrenceDate: 'Дата возникновения',
        statePeriod: 'Период состояния',
    },
    consumptionAnalysis : {
        descr: 'Объект',
        geoZone: 'Геозона',
        address: 'Адрес',
        currentPeriod: 'Текущий период',
        planedValue: 'Плановый расход за текущий период (кВт*ч)',
        currentValue: 'Фактический расход за текущий период (кВт*ч)',
        planedCurrentDifference: 'Отклонение от планового расхода (кВт*ч)',
        prevPeriod: 'Предыдущий период',
        prevValue: 'Фактический расход за предыдущий период (кВт*ч)',
        difference: 'Разница факт. расхода с пред. периодом (кВт*ч)',
        object: 'Объект',
        date: 'Дата',
        planT1: '1 (план)',
        planT2: '2 (план)',
        factT1: '1 (факт)',
        factT2: '2 (факт)',
        planQ: 'план',
        factQ: 'факт',
        energy: 'Гкал',
        overheat: 'Недотоп, Гкал',
        rate: 'Тариф, руб',
        overpayment: 'Сэкономлено, руб',
    },
    faultAnalytics: {
        name: 'Геозона',
        objectCount: 'Инсталляция',
        warningStatePercent: 'Коэффициент предупреждений',
        errorStatePercent: 'Коэффициент неисправностей',
        normalStatePercent: 'Коэффициент исправных',
        undefinedStatePercent: 'Коэффициент неопределенных',
    },
    liveTime: {
        name: 'Объект',
        geoZoneName: 'Геозона',
        address: 'Адрес',
        workTime: 'Часы работы',
        currentLifePercent: '% выработки ресурса',
        startDateExploitation: 'Дата начала эксплуатации',
        dateEndExploitation: 'Дата окончания эксплуатации',
        currentDateExploitation: 'Текущее время эксплуатации',
    },
    objectControlStatistic: {
        dateStep: 'Дата',
        geoZone: 'Геозона',
        project: 'Проект',
        objectInstalled: 'Установлено объектов',
        OKObject: 'Объекты в рабочем состоянии',
        persents: '% объектов в рабочем состоянии'
    },
    reportManager: {
        title: 'Название отчета',
        geoZoneName: 'Геозона',
        type: 'Тип отчета',
        objectTypes: 'Тип объекта для отчета',
        schedule: 'Расписание',
        externalRecipients: 'Получатели',
        lastSendDate: 'Дата последнего отправленного отчета',
        nextSendDate: 'Дата следующего отправления отчета',
    },
    search: {
        name: 'Объект',
        installationType: 'Тип оборудования',
        geoZone: 'Геозона',
        project: 'Проект',
        integrationName: 'Интеграция',
        region: 'Регион',
        city: 'Город',
        exploitationDateStart: 'Дата начала эксплуатации',
        exploitationDateEnd: 'Дата окончания эксплуатации',
        exploitationOrganization: 'Эксплуатирующая организация',
        owner: 'Собственник',
    },
    serviceDeskMyCity: {
        essenceOfAppeal: 'Суть обращения',
        object: 'Объект',
        objectType: 'Тип объекта',
        registrationDate: 'Дата регистрации',
        status: 'Статус',
        plannedExecutionDate: 'Плановая дата исполнения',
        actualDateOfExecution: 'Фактическая дата исполнения',
    },
    userPage: {
        firstName: 'Пользователь',
        groupName: 'Группа',
        userStatus: 'Статус',
        enter: 'Вход в систему',
        sessionTime: 'Время сессии',
    },
    PPR: {
        object: 'Объект',
        address: 'Наименование оборудования',
        registerNumber: 'Реестровый номер',
        installationType: 'Оборудование',
        planDate: 'Дата плана ППР',
        factDate: 'Дата факта ППР',
    },
    scheduleManager: {
        name: 'Название расписания',
        geoZone: 'Геозона',
        deviceCount: 'Количество подключенных устройств',
        error: 'Описание ошибки',
        originDate: 'Дата возникновения',
        errorStatus: 'Текущий статус ошибки',
        resolved: 'Решена',
        notResolved: 'Не решена',
        user: 'Пользователь (ФИО)',
        actionDateTime: 'Дата и время действия',
        action: 'Действие',
        comment: 'Комментарий',
        CREATE: 'Создано расписание',
        EDIT: 'Расписание изменено',
        DELETE: 'Удалено расписание',
    },
}