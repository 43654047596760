import {
  put, takeLatest, select, take, call, delay
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import get from 'lodash/get'
import set from 'lodash/set'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import {
  getCard,
} from '@/store/selectors/installation'
import { successVerifyElements } from '@/store/actions/installation/verifyObject'
import createNotifications from '@/helpers/notification'
import installationFormatFields from '@/helpers/installationFormatFields'
import errorsMapper from '@/constants/errors/createObjectElement'
import { VERIFY_OBJECT } from '@/store/actions/installation'
import { requestUploadInstallationObjectFile } from '@/store/actions/installation/uploadObjectFile'
import { requestDeleteInstallationObjectFile } from '@/store/actions/installation/deleteObjectFile'
import {
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { INSTALLATIONS_OBJECT_ENDPOINT, generateValidateObject } from '@/constants/apiRoutes'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { treeHandlers } from 'react-hyper-tree'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* editObjectSaga({ payload }) {
  const {
    formValues,
    setErrors,
    values,
    setUrlFormValues = noop,
    type,
    setAlertConfig,
    intl,
  } = payload
  try {
    yield put(setGlobalLoading(true))
    setAlertConfig({})
    const cardCopy = yield select(getCard)
    const cardPhotoId = get(cardCopy, 'data.photoId', null)
    const cardFileId = get(cardCopy, 'data.fileId', null)
    const asuId = get(cardCopy, 'data.asuId', null)
    const parentId = get(cardCopy, 'data.parentId', null)
    const parentName = get(cardCopy, 'data.parentName', null)
    const valuesDeepClone = cloneDeep(values)
    set(valuesDeepClone, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', 'DEFAULT_EQUIPMENT_TYPE')
    const objectElementName = get(valuesDeepClone, 'GENERAL_INFORMATION.NAME', null)
    const objectId = get(valuesDeepClone, 'GENERAL_INFORMATION.IDENTIFIER', null)
    const latitude = get(valuesDeepClone, 'LOCATION.LATITUDE', null)
    const longitude = get(valuesDeepClone, 'LOCATION.LONGITUDE', null)
    const integrationType = get(valuesDeepClone, 'integrationType', null)
    const prevObjectElementFieldsDto = get(valuesDeepClone, 'prevObjectElementFieldsDto', {})
    const photoFile = get(values, 'ATTACHMENT.PHOTO_TITLE', null)
    const PDFFile = get(values, 'ATTACHMENT.DOCUMENT_TITLE', null)
    const photoNameFromCard = (cardCopy.data.objectFields.filter((element) => element.fieldName === 'PHOTO_TITLE')[0] || {}).value || null
    delete valuesDeepClone.LOCATION.LATITUDE
    delete valuesDeepClone.LOCATION.LONGITUDE
    delete valuesDeepClone.GENERAL_INFORMATION.NAME
    delete valuesDeepClone.integrationType
    delete valuesDeepClone.prevObjectElementFieldsDto
    delete valuesDeepClone.objectFields
    delete valuesDeepClone.GENERAL_INFORMATION.EQUIPMENT_TYPE
    delete valuesDeepClone.ATTACHMENT
    delete valuesDeepClone.path
    delete cardCopy.elementsInfo
    delete valuesDeepClone.elementsInfo

    if ((!photoFile && cardPhotoId) || (photoFile?.name !== photoNameFromCard && cardPhotoId)) {
      yield put(requestDeleteInstallationObjectFile({
        id: formValues.id,
        fileId: cardPhotoId,
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }
    if (!PDFFile && cardFileId) {
      yield put(requestDeleteInstallationObjectFile({
        id: formValues.id,
        fileId: cardFileId,
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }
    if (photoFile && (photoFile?.name !== photoNameFromCard || !cardPhotoId)) {
      yield put(requestUploadInstallationObjectFile({
        elementId: formValues.id,
        file: photoFile,
        title: photoFile.name,
        fileType: 'PHOTO',
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }
    if (PDFFile && !cardFileId) {
      yield put(requestUploadInstallationObjectFile({
        elementId: formValues.id,
        file: PDFFile,
        title: PDFFile.name,
        fileType: 'DOCUMENT',
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }

    const fields = Object.keys(valuesDeepClone).reduce((accumulator, rootName) => {
      if (typeof valuesDeepClone[rootName] !== 'object') {
        return accumulator
      }
      const childField = Object.keys(valuesDeepClone[rootName] || {}).reduce((buffer, childName) => {
        if (childName === 'customFields') {
          const customFields = get(valuesDeepClone, `${rootName}.customFields`, []).reduce((customFieldsAccumulator, element) => {
            if (element.fieldName && element.value && !element.remove) {
              return [
                ...customFieldsAccumulator,
                {
                  ...element,
                  fieldName: element.fieldName,
                  value: element.value,
                  passportBlock: rootName,
                },
              ]
            }
            return customFieldsAccumulator
          }, [])
          return [
            ...buffer,
            ...customFields,
          ]
        }
        return [
          ...buffer,
          {
            ...prevObjectElementFieldsDto[`${rootName}.${childName}`] || {},
            fieldName: `${rootName}.${childName}`,
            value: get(valuesDeepClone, `${rootName}.${childName}`, null),
            passportBlock: rootName,
          },
        ]
      }, [])
      return [
        ...accumulator,
        ...childField,
      ]
    }, [])
    const formattedFields = installationFormatFields(fields)
    // const formattedFieldsWithFilterOutEmptyFields = formattedFields.filter((el) => el.value)
    const body = {
      name: objectElementName,
      objectIdOuter: objectId,
      point: {
        latitude,
        longitude,
      },
      objectFields: formattedFields,
      integrationType,
      installationType: type,
      id: formValues.id,
      asuId,
      parentId,
      parentName
    }
    yield delay(300)
    const { data } = yield request({
      url: INSTALLATIONS_OBJECT_ENDPOINT,
      method: 'post',
      body,
    })
    const toast = createNotifications()

    try {
      const { data: verificationData } = yield request({
        url: generateValidateObject(data.id),
        method: 'get',
      })
      const { asuActualFields, objectElement } = verificationData
      if (asuActualFields.length !== 0) {
        yield put(setGlobalLoading(false))
        const formattedErrors = asuActualFields.map((errorObject) => {
          return {
            fieldName: errorObject.fieldName,
            value: errorObject.value,
          }
        })
        let errorMessage = 'Не соответствие по следующим полям\n'
        const formErrorObject = formattedErrors.reduce((accumulator, errorObject) => {
          errorMessage += `\nПоле: ${intl.messages[`passport.fields.${errorObject.fieldName}`]},\nзначение: ${errorObject.value}\n`
          const accumulatorCopy = { ...accumulator }
          set(accumulatorCopy, errorObject.fieldName, `необходимое значение - "${errorObject.value}"`)
          return accumulatorCopy
        }, {})
        setErrors(formErrorObject)
        toast({
          title: 'Ошибка верификации.',
          type: 'error',
          description: errorMessage,
        })
      } else {
        yield put(setGlobalLoading(false))
        toast({
          title: 'Верификация завершена',
          type: 'success',
          description: 'Объект успешно проверен.',
        })
        yield put(requestGetPinsAndZonesAndTelemetry({
          includeAll: true,
          parentTreeId: objectElement.path[1],
        }))
        const tree = treeHandlers.trees['installations-tree']
        const parent = tree.instance.getNodeById(objectElement.parentId)
        const parentChildren = yield call(parent.data.getChildren, parent)
        tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
        tree.handlers.rerender()
        const {
          rootId, path, parentGeoZoneId, parentId, FILTER,
        } = formValues
        const newPath = path.replace(parentId, data.parentId)
        setUrlFormValues({
          id: data.id,
          type: OBJECT_ELEMENT,
          rootId,
          parentGeoZoneId,
          parentTreeId: data.parentId,
          path: newPath,
          FILTER,
        })
      }
    } catch (error) {
      yield put(setGlobalLoading(false))
      toast({
        title: 'Ошибка операции.',
        type: 'error',
        description: 'Данные от АСУ будут доступны после синхронизации',
      })
      const {
        rootId, path, parentGeoZoneId, parentTreeId, FILTER,
      } = formValues
      const newPath = path.replace(parentTreeId, data.parentId)
      setUrlFormValues({
        id: data.id,
        type: OBJECT_ELEMENT,
        rootId,
        parentGeoZoneId,
        parentTreeId: data.parentId,
        path: newPath,
        FILTER,
      })
    }
    yield put(successVerifyElements())
  } catch (error) {
    yield put(setGlobalLoading(false))
    setAlertConfig({})
    const errors = get(error, 'response.data.details', []).reduce((accumulator, element) => {
      const errorMessageAndLocation = element.message.split(': ')
      return {
        ...accumulator,
        [errorMessageAndLocation[1]]: errorsMapper[errorMessageAndLocation[0]] || errorMessageAndLocation[0],
      }
    }, {})
    const errorObject = {}
    Object.keys(errors).map((errorLocation) => {
      set(errorObject, errorLocation, errors[errorLocation])
      return errorLocation
    })
    setErrors(errorObject)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: `Невозможно выполнить верификацию. \nКод ошибки: ${get(error, 'response.status', '000')}\nПовторите операцию позже.`,
      type: 'error',
    })
    console.log('function*verifyObjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(VERIFY_OBJECT.REQUEST, editObjectSaga)
}
