import {
  takeLatest, put,
} from 'redux-saga/effects'
import { GET_INTEGRATIONS } from '@/store/actions/integrations'
import { eventsCreateIntegration } from '@/store/actions/integrations/createIntegration'
import { eventsUpdateIntegration } from '@/store/actions/integrations/updateIntegration'
import { eventsDeleteIntegration } from '@/store/actions/integrations/deleteIntegration'
import { eventsRepeatIntegration } from '@/store/actions/integrations/repeatIntegration'
import {
  eventsIntegrationVerificationProgress,
  eventsVerifyIntegration
} from '@/store/actions/integrations/verifyIntegration'
import { eventsGetAlarmsCount } from '@/store/actions/alarmManager/getAlarmsCount'
import { eventsAddIntegration } from '@/store/actions/serviceDeskMyCity/addIntegration'
import { eventsUploadNotificationFile } from '@/store/actions/installation/fileUploadNotification'
import { eventsChangeObjectState } from '@/store/actions/maps/socketEvents'

import { ON_SOCKET_CONNECTED } from '@/store/actions/socket'
import createNotifications from '@/helpers/notification'

function* handleSocketConnected() {
  try {
    // yield put(eventsRefreshWidget())
    yield put(eventsGetAlarmsCount())
    yield put(eventsCreateIntegration())
    yield put(eventsUpdateIntegration())
    yield put(eventsDeleteIntegration())
    yield put(eventsRepeatIntegration())
    yield put(eventsVerifyIntegration())
    // yield put(eventsMapObjectsErrors())
    // yield put(eventsMapObjectsNotifications())
    yield put(eventsAddIntegration())
    yield put(eventsUploadNotificationFile())
    yield put(eventsIntegrationVerificationProgress())
    yield put(eventsChangeObjectState())
  } catch (error) {
    console.log('TCL: function*handleSocketConnected -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(ON_SOCKET_CONNECTED, handleSocketConnected)
  yield takeLatest(GET_INTEGRATIONS.SUCCESS, handleSocketConnected)
}
