import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { OBJECTS_GROUPS_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { requestGetObjectsGroupsTree } from '@/store/actions/objectsGroups/getTree'
import { DELETE_OBJECTS_GROUP } from '@/store/actions/objectsGroups'
import { errorDeleteObjectsGroup, successDeleteObjectsGroup } from '@/store/actions/objectsGroups/deleteObjectsGroup'

function* deleteObjectsGroupSaga({ payload }) {
  const { id } = payload
  const toast = createNotifications()
  try {
    yield put(setGlobalLoading(true))
    yield request({
      url: OBJECTS_GROUPS_ENDPOINT(id),
      method: 'delete',
    })
    yield put(requestGetObjectsGroupsTree())
    yield put(successDeleteObjectsGroup())
    yield put(setGlobalLoading(false))
    toast({
      title: 'Удаление группы',
      type: 'success',
      description: 'Группа объектов успешно удалена.',
    })
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorDeleteObjectsGroup())
    console.log('function*deleteObjectsGroupSaga -> error', error)
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось удалить группу объектов. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_OBJECTS_GROUP.REQUEST, deleteObjectsGroupSaga)
}
