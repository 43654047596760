import { RESET_SCHEDULE } from './index'

export const requestResetSchedule = (payload) => ({
  type: RESET_SCHEDULE.REQUEST,
  payload,
})

export const successResetSchedule = (payload) => ({
  type: RESET_SCHEDULE.SUCCESS,
  payload,
})

export const errorResetSchedule = (payload) => ({
  type: RESET_SCHEDULE.ERROR,
  payload,
})