export const BASE_URL = '/'

export const SIGN_IN_URL = '/sign-in'
export const SIGN_UP_URL = '/confirm-registration'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const USER_AGREEMENT = '/user_agreement'

export const DASHBOARD_URL = '/dashboard'
export const RESTORE_PASSWORD = '/restore-password'
export const SET_PASSWORD = '/set-password'

export const USERS_URL = `${DASHBOARD_URL}/users`

export const OBJECTS_URL = `${DASHBOARD_URL}/maps`

export const PASPORTIZATION_URL = `${DASHBOARD_URL}/passportization`
export const ALARMS_URL = `${DASHBOARD_URL}/alarms`
export const ALARMS_ANALYTICS_URL = `${DASHBOARD_URL}/analytics-alarms`
export const SUMMARY_ALARMS_ANALYTICS_URL = `${ALARMS_ANALYTICS_URL}/all`
export const ALARMS_ANALYTICS_URL_WITH_MASK = `${ALARMS_ANALYTICS_URL}/:id`
export const ACTIVITY_LOG_URL = `${DASHBOARD_URL}/activity-log`
export const INDIVIDUAL_CONTROL_URL = `${DASHBOARD_URL}/individual-control`
export const GROUP_CONTROL_URL = `${DASHBOARD_URL}/group-control`
export const SCHEDULE_MANAGER_URL = `${DASHBOARD_URL}/schedule-manager`
export const OBJECTS_GROUPS_URL = `${DASHBOARD_URL}/objects-groups`
export const INSTALLATION_URL = `${DASHBOARD_URL}/installations`
export const REPORTS_MANAGER_URL = `${DASHBOARD_URL}/reports-manager`
export const ENERGY_URL = `${DASHBOARD_URL}/energy`
export const LIFE_URL = `${DASHBOARD_URL}/life`
export const SEARCH_URL = `${DASHBOARD_URL}/search`
export const CHARTS_URL = `${DASHBOARD_URL}/charts`
export const SUPPORT_URL = `${DASHBOARD_URL}/support`
export const CHANGE_LOG_URL = `${DASHBOARD_URL}/change-log`
export const MANAGER_LOG_URL = `${DASHBOARD_URL}/log-manager`
export const PERIOD_OF_EXPLOITATION = `${DASHBOARD_URL}/period-of-exploitation`
export const ANALYTICS_EXPENSES_URL = `${DASHBOARD_URL}/analytics-expenses`
export const TYUMEN_OUR_HOME_URL = `${DASHBOARD_URL}/tyumen-our-home`
export const TYUMEN_OUR_HOME_GRAPHS_URL = `${DASHBOARD_URL}/tyumen-our-home/graphs`
export const SERVICE_DESK_MY_CITY_URL = `${DASHBOARD_URL}/service-desk-my-city`
export const SERVICE_DESK_MY_CITY_TABLE_URL = `${DASHBOARD_URL}/service-desk-my-city/all`
export const SERVICE_DESK_MY_CITY_APPEALS_URL = `${DASHBOARD_URL}/service-desk-my-city/:filter`
export const WIDGETS_DASHBOARD_URL = `${DASHBOARD_URL}/widgets-dashboard`
export const WIDGETS_DASHBOARD_GOV_URL = `${DASHBOARD_URL}/widgets-dashboard-gov`
export const REPORT_MANAGER_URL = `${DASHBOARD_URL}/report-manager`
export const CONTROL_OBJECTS_STATISTICS = `${DASHBOARD_URL}/object-controll-statistic`
export const MOBILE_SETTINGS_URL = `${DASHBOARD_URL}/settings`
export const MOBILE_APPLICATIONS_URL = `${DASHBOARD_URL}/applications`
export const PPR_URL= `${DASHBOARD_URL}/ppr`

export const NOT_FOUND_URL = '/404'
export const SERVER_ERROR_URL = '/500'
