import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
    showInDashboard: true,
    selector: 'objectIdOuter',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER" />,
    showInDashboard: false,
    selector: 'idSHUO',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.modelMark" />,
    showInDashboard: true,
    selector: 'model/brand',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.manufacturer" />,
    showInDashboard: false,
    selector: 'manufacturer',
  },
  {
    passportParameter: <Lang id="passportization.fields.location.latitude" />,
    showInDashboard: true,
    selector: 'geographicLatitude',
  },
  {
    passportParameter: <Lang id="passportization.fields.location.longitude" />,
    showInDashboard: true,
    selector: 'geographicLongitude',
  },
  {
    passportParameter: <Lang id="passportization.fields.exploitation.startDate" />,
    showInDashboard: true,
    selector: 'startDate',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'endDate',
  },
  {
    passportParameter: <Lang id="passport.fields.exploitation.organization" />,
    showInDashboard: false,
    selector: 'operatingOrganization',
  },
  {
    passportParameter: <Lang id="passportization.fields.exploitation.owner" />,
    showInDashboard: false,
    selector: 'owner',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
    showInDashboard: true,
    selector: 'warrantyPeriod',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
    showInDashboard: true,
    selector: 'lifeTime',
  },
  {
    passportParameter: <Lang id="passport.fields.technicalLimitations.workingTemperatureRange" />,
    showInDashboard: true,
    selector: 'operatingTemperatureRange',
  },
  {
    passportParameter: <Lang id="passport.fields.objectService.checkingDate" />,
    showInDashboard: true,
    selector: 'verificationDate',
  },
  {
    passportParameter: <Lang id="passport.fields.technicalLimitations.calibrationInterval" />,
    showInDashboard: true,
    selector: 'intertestingInterval',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_ONE" />,
    showInDashboard: true,
    selector: 'tariff1',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_TWO" />,
    showInDashboard: true,
    selector: 'tariff2',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_THREE" />,
    showInDashboard: true,
    selector: 'tariff3',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.TARIFF_FOUR" />,
    showInDashboard: true,
    selector: 'tariff4',
  },
  {
    passportParameter: <Lang id="passportization.fields.inner.photo" />,
    showInDashboard: true,
    selector: 'photo',
  },
  {
    passportParameter: <Lang id="passportization.fields.inner.file" />,
    showInDashboard: false,
    selector: 'productDataSheetFile',
  },
  {
    passportParameter: <Lang id="passportization.fields.plan.date" />,
    showInDashboard: true,
    selector: 'factDate',
  },
  {
    passportParameter: <Lang id="passportization.fields.fact.date" />,
    showInDashboard: true,
    selector: 'planDate',
  },
]