import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getPpr = createSelector(
  getState,
  (state) => get(state, 'ppr', {}),
)

export const getData = createSelector(
  getPpr,
  (settings) => get(settings, 'data'),
)

export const getTableParameters = createSelector(
  getPpr,
  (settings) => get(settings, 'table', {}),
)

export const getYear = createSelector(
  getPpr,
  (settings) => get(settings, 'year', {}),
)

export const getStatus = createSelector(
  getPpr,
  (settings) => get(settings, 'status', {}),
)

export const getDatesRange = createSelector(
  getPpr,
  (settings) => get(settings, 'dates', {}),
)
