import { connect } from 'react-redux'
import {
  getExportFileStatus,
  getSelector,
  getSelectedElement,
} from '@/store/selectors/analyticsAlarms'
import {
  getTypes,
  getIsTypesLoading,
  getObjectTypes,
  getIsObjectTypesLoading,
  getCommonUsers,
  getIsCommonUsersLoading,
  getIsFormModifying,
  getSelectedReport,
  getLoadingSelectedReport,
  getTableLoading,
} from '@/store/selectors/reportManager'
import {
  getPinnedNode,
  getGlobalLoading
} from '@/store/selectors/appSettings'

import { getData, getYear, getStatus, getDatesRange } from '@/store/selectors/ppr'
import { setSelectedNode } from '@/store/actions/appSettings'
import { requestPprTable } from '@/store/actions/Ppr/getTable'
import { requestPprFile } from '@/store/actions/Ppr/getFile'
import { setYear, setStatus, setDatesRange } from '@/store/actions/Ppr'


import Ppr from './component'
import { getRoot, getRootStatus } from '@/store/selectors/installation'

const mapStateToProps = (state) => ({
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  selectedElement: getSelectedElement(state),
  data: getData(state),
  year: getYear(state),
  status: getStatus(state),
  dates: getDatesRange(state),
  reportTypes: getTypes(state),
  isTypesLoading: getIsTypesLoading(state),
  reportObjectTypes: getObjectTypes(state),
  isObjectTypesLoading: getIsObjectTypesLoading(state),
  commonUsers: getCommonUsers(state),
  isCommonUsersLoading: getIsCommonUsersLoading(state),
  reportFormOpen: getIsFormModifying(state),
  selectedReport: getSelectedReport(state),
  loadingSelectedReport: getLoadingSelectedReport(state),
  selectedNode: getPinnedNode(state),
  loadingTable: getTableLoading(state),
  roots: getRoot(state),
  rootStatus: getRootStatus(state),
  globalLoading: getGlobalLoading(state),
})
const mapDispatchToProps = (dispatch) => ({
  setNode: (data, withoutPinned) => dispatch(setSelectedNode(data, withoutPinned)),
  requestPprTable: (payload) => dispatch(requestPprTable(payload)),
  requestPprFile: (payload) => dispatch(requestPprFile(payload)),
  setYear: (payload) => dispatch(setYear(payload)),
  setStatus: (payload) => dispatch(setStatus(payload)),
  setDatesRange: (payload) => dispatch(setDatesRange(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Ppr)
