import {
  DARK_THEME_NAME,
  SKY_SCHEME_NAME,
  RU_LANGUAGE,
  WIDGET_SHOP_APP,
} from '@/constants/names'
import createAction from '@/helpers/redux/createAction'
import { SET_PASSPORTIZATION_TABLE_FILTER_VALUES } from '@/store/actions/objectsGroups'

export const SELECT_THEME = '@/SELECT_THEME'
export const SELECT_LANGUAGE = '@/SELECT_LANGUAGE'
export const SELECT_FONTSIZE = '@/SELECT_FONTSIZE'
export const SELECT_SCHEME = '@/SELECT_SCHEME'
export const SELECT_WIDGET_SHOP_VALUE = '@/SELECT_WIDGET_SHOP_VALUE'

export const MENU_SIDEBAR_PINNED = '@/MENU_SIDEBAR_PINNED'
export const USER_PROFILE_TOGGLE = '@/USER_PROFILE_TOGGLE'
export const SETTINGS_TOGGLE = '@/SETTINGS_TOGGLE'
export const ALARMS_WIDGET_SETTINGS_TOGGLE = '@/ALARMS_WIDGET_SETTINGS_TOGGLE'

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

export const START_ADD_WIDGET_TO_GENERAL_PAGE = '@/START_ADD_WIDGET_TO_GENERAL_PAGE'
export const START_REMOVE_WIDGET_FROM_GENERAL_PAGE = '@/START_REMOVE_WIDGET_FROM_GENERAL_PAGE'
export const START_SET_WIDGET_SETTINGS = '@/START_SET_WIDGET_SETTINGS'
export const UPDATE_WIDGETS_LIST = '@/UPDATE_WIDGETS_LIST'
export const UPDATE_APPLICATIONS_LIST = '@/UPDATE_APPLICATIONS_LIST'

export const SET_SELECTED_NODE = '@/SET_SELECTED_NODE'
export const SET_FAKE_NODE = '@/SET_FAKE_NODE'
export const SET_ANALYTICS_NODE = '@/SET_ANALYTICS_NODE'
export const UNPIN_NODE = '@/UNPIN_NODE'

export const SET_GLOBAL_FILTER_VALUES = '@/SET_GLOBAL_FORM_VALUES'
export const RESET_GLOBAL_FILTER_VALUES = '@/RESET_GLOBAL_FILTER_VALUES'
export const SET_FALSE_STATUS_GLOBAL_FILTER = '@/SET_FALSE_STATUS_GLOBAL_FILTER'
export const SET_TRUE_STATUS_GLOBAL_FILTER = '@/SET_TRUE_STATUS_GLOBAL_FILTER'
export const SET_GLOBAL_FILTER_OPEN = '@/SET_GLOBAL_FILTER_OPEN'

export const SET_ALARMS_FILTER_VALUES = '@/SET_ALARMS_FORM_VALUES'


export const TOGGLE_MENU = '@/TOGGLE_MENU'
export const TOGGLE_TREE = '@/TOGGLE_TREE'

export const SET_GLOBAL_LOADING = '@/SET_GLOBAL_LOADING'
export const SET_MAP_LAYER = '@/SET_MAP_LAYER'
export const SET_SEARCH_TREE = '@/SET_SEARCH_TREE'
export const TOGGLE_RUSSIAN_BORDERS = '@/TOGGLE_RUSSIAN_BORDERS '

export const SET_MOBILE = '@/SET_MOBILE'

export const SWITCH_STATE_NOTIFICATION = '@/SWITCH_STATE_NOTIFICATION'
export const SWITCH_STATE_SWIPER = '@/SWITCH_STATE_SWIPER'

export const GET_APPLICATIONS_VERSION = createAction('@/GET_APPLICATIONS_VERSION')
export const GET_ALL_APPLICATIONS_FILES = createAction('@/GET_ALL_APPLICATIONS_FILES')

export const setTheme = (theme = DARK_THEME_NAME) => ({
  type: SELECT_THEME,
  payload: { theme },
})

export const onToggleMenu = (payload) => ({
  type: TOGGLE_MENU,
  payload,
})

export const onToggleTree = (payload) => ({
  type: TOGGLE_TREE,
  payload,
})

export const setMobile = (payload) => ({
  type: SET_MOBILE,
  payload,
})

export const setSelectedNode = (node = {}, withoutPinned = false) => ({
  type: SET_SELECTED_NODE,
  payload: { node, withoutPinned },
})

export const setFakeNode = (node = {}, withoutPinned = false) => ({
  type: SET_FAKE_NODE,
  payload: { node, withoutPinned },
})

export const setUnpinNode = (node = {}) => ({
  type: UNPIN_NODE,
  payload: { node },
})

export const setWidgetShopValue = (widgetShopValue = WIDGET_SHOP_APP) => ({
  type: SELECT_WIDGET_SHOP_VALUE,
  payload: widgetShopValue,
})

export const setScheme = (scheme = SKY_SCHEME_NAME) => ({
  type: SELECT_SCHEME,
  payload: { scheme },
})

export const setLanguage = (language = RU_LANGUAGE) => ({
  type: SELECT_LANGUAGE,
  payload: { language },
})

export const setFontSize = (large = false) => ({
  type: SELECT_FONTSIZE,
  payload: { large },
})

export const pinnedSideBarMenu = (isOpen = false) => ({
  type: MENU_SIDEBAR_PINNED,
  payload: { isOpen },
})

export const toggleUserProfile = (isOpen = false) => ({
  type: USER_PROFILE_TOGGLE,
  payload: { isOpen },
})
export const toggleAlarmsWidgetSettings = (isOpen = false) => ({
  type: ALARMS_WIDGET_SETTINGS_TOGGLE,
  payload: { isOpen },
})

export const toggleSettings = (isOpen = false) => ({
  type: SETTINGS_TOGGLE,
  payload: { isOpen },
})

export const startAddWidgetToGeneralPage = (type, widgetType, large) => ({
  type: START_ADD_WIDGET_TO_GENERAL_PAGE,
  payload: { type, widgetType, large },
})

export const startDeleteWidgetFromGeneralPage = (id, widgetType, integrationId) => ({
  type: START_REMOVE_WIDGET_FROM_GENERAL_PAGE,
  payload: { id, widgetType, integrationId },
})

export const startSetWidgetSettings = (id, settings, widgetType) => ({
  type: START_SET_WIDGET_SETTINGS,
  payload: { id, settings, widgetType },
})

export const updateWidgetsList = (widgets) => ({
  type: UPDATE_WIDGETS_LIST,
  payload: { widgets },
})

export const updateApplicationsList = (applications) => ({
  type: UPDATE_APPLICATIONS_LIST,
  payload: { applications },
})

export const setGlobalFilterValues = (globalFormValues) => ({
  type: SET_GLOBAL_FILTER_VALUES,
  payload: globalFormValues,
})

export const resetGlobalFilterValues = () => ({
  type: RESET_GLOBAL_FILTER_VALUES,
})

export const setFalseStatusGlobalFilter = () => ({
  type: SET_FALSE_STATUS_GLOBAL_FILTER,
})

export const setTrueStatusGlobalFilter = () => ({
  type: SET_TRUE_STATUS_GLOBAL_FILTER,
})

export const setIsGlobalFilterOpen = () => ({
  type: SET_GLOBAL_FILTER_OPEN,
})

export const setAlarmsFilterValues = (AlarmslFormValues) => ({
  type: SET_ALARMS_FILTER_VALUES,
  payload: AlarmslFormValues,
})

export const setObjectsGroupsPassportizationFilterValues = (values) => ({
  type: SET_PASSPORTIZATION_TABLE_FILTER_VALUES,
  payload: values,
})

export const setGlobalLoading = (value) => ({
  type: SET_GLOBAL_LOADING,
  payload: value,
})

export const setMapLayer = (value) => ({
  type: SET_MAP_LAYER,
  payload: value,
})

export const setRussianBorders = (value) => ({
  type: TOGGLE_RUSSIAN_BORDERS,
  payload: value,
})

export const setSearchTree = (tree) => ({
  type: SET_SEARCH_TREE,
  payload: tree,
})

export const switchStateNotification = () => ({
  type: SWITCH_STATE_NOTIFICATION,
})

export const switchStateSwiper = () => ({
  type: SWITCH_STATE_SWIPER,
})

export const requestApplicationsVersion = () => ({
  type: GET_APPLICATIONS_VERSION.REQUEST,
})

export const successApplicationsVersion = (payload) => ({
  type: GET_APPLICATIONS_VERSION.SUCCESS,
  payload,
})

export const requestAllApplicationsFiles = () => ({
  type: GET_ALL_APPLICATIONS_FILES.REQUEST,
})

export const successAllApplicationsFiles = (payload) => ({
  type: GET_ALL_APPLICATIONS_FILES.SUCCESS,
  payload,
})
