import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { Formik, Form } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import FileControllField from '@/components/fields/FileControllField'
import Button from '@/components/blocks/Button'
import viewTreeIcons from '@/constants/viewTree'
import LiveTime from '@/components/icons/livetime'
import CoreIcons from '@/components/icons/core'
import RadioGroup from '@/components/fields/RadioGroupField'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import {
  TEXT,
  RADIO_GROUP,
  FILE,
  PHOTO,
  CUSTOMS_FIELDS,
  SELECT,
} from '@/constants/semanticNames'
import {
  CatalogContainer,
  CatalogRow,
  CatalogTitle,
  ToggleContainer,
  CatalogOptionsContainer,
  ButtonContainer,
  InputAndLabelContainer,
  IconContainer,
  InfoField,
  InfoContainer,
  CustomFieldBlock,
  CustomFieldContainer,
  CancelButton
} from './styles'
import { CHAR_LIMITS } from '@/constants/sizes';
import SelectField from "@/components/fields/SelectField";

const PassportizationForm = React.forwardRef(({
  initialValues,
  options,
  selectedFields,
  block,
  setImage,
  setDirty,
  handleDownloadFile,
  handleExportFile,
  isExportButtonVisible,
  objectConnectedIdentifiers
}, ref) => {
  const [localInitialValues, setLocalInitialValues] = useState(initialValues)
  const [openCatalogsId, setOpenCatalogsId] = useState([])

  useEffect(() => {
    if (!block) {
      get(ref, 'current.setFieldValue', noop)('edit', true)
    }
  }, [block, setDirty, ref])

  useEffect(() => {
    if (!isEqual(localInitialValues, initialValues)) {
      setLocalInitialValues(initialValues)
    }
  },
  [
    initialValues,
    localInitialValues,
    setLocalInitialValues,
  ])

  const catalogToggleHandler = (id) => () => {
    const openCatalogsIdCopy = [...openCatalogsId]
    if (openCatalogsId.includes(id)) {
      const filteredElementsId = openCatalogsIdCopy.filter((element) => id !== element)
      setOpenCatalogsId(filteredElementsId)
      return null
    }
    openCatalogsIdCopy.push(id)
    setOpenCatalogsId(openCatalogsIdCopy)
  }

  const formatOptions = useMemo(() => {
    return options.reduce((accumulator, option) => {
      if (!selectedFields.includes(option.selector)) {
        return accumulator
      }
      if (option.selector === 'GENERAL_INFORMATION.PREVIOUS_LINKED_IDENTIFIER') {
        option.options = objectConnectedIdentifiers
      }
      const { catalogSelector } = option
      return {
        ...accumulator,
        [catalogSelector]: {
          selector: catalogSelector,
          ...get(options, `${catalogSelector}`, {}),
          options: [
            ...get(accumulator, `${catalogSelector}.options`, []),
            option,
          ],
        },
      }
    }, {})
  }, [options, selectedFields, objectConnectedIdentifiers])

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
    setDirty(true)
  }
  
  const setPhotoToNull = (selector, setFieldValue) => () => {
    setImage(null)
    setFieldValue(selector, null)
  }

  const downloadFile = useCallback(() => {
    handleDownloadFile()
  }, [handleDownloadFile])

  const setCustomField = (setField, selector, value) => () => {
    setField(selector, value)
  }

  const renderOption = (errors, touched, isSubmitting, values, setFieldValue) => (element) => {
    const {
      selector,
      type,
      options,
      format,
      mask,
      immutable,
      catalogSelector
    } = element
    let disabled = immutable
    if (selector === 'LOCATION.LATITUDE' || selector === 'LOCATION.LONGITUDE') {
      disabled = true
    }
    const value = get(values, selector, null) || '-'
    return (
      <InputAndLabelContainer>
        {block && type !== FILE && type !== PHOTO && type !== CUSTOMS_FIELDS && (
          <InfoContainer>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <InfoField bg>
              {
                type === RADIO_GROUP ? ((options.filter((element) => element.value === get(values, selector, null))[0] || {}).label || '-').toString() : null
              }
              {
                type === SELECT ? options?.filter(option => option.value.toString() === value)[0]?.title : null
              }
              {
                type !== RADIO_GROUP && type !== SELECT ? value : null
              }
            </InfoField>
          </InfoContainer>
        )}
        {block && type === CUSTOMS_FIELDS && (
            <>
              {((values[catalogSelector] || {}).customFields || []).map((customField, index) => {
                const localSelector = `${selector}[${index}]`
                const localSelectorValue = `${localSelector}.value`
                const localSelectorName = `${localSelector}.fieldName`
                const nameTouch = get(touched, localSelectorName, null)
                const nameError = get(errors, localSelectorName, null)
                return (
                    <InfoContainer>
                      <LabelWithIcon
                          isError={nameError && nameTouch}
                          title={get(values, localSelectorName, null)}
                      />
                      <InfoField bg>
                        {get(values, localSelectorValue, null)}
                      </InfoField>
                    </InfoContainer>
                )
              })}
            </>
        )}
        {type === TEXT && !block && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <TextField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (disabled && !block),
                format,
                onlyRead: block,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || block || disabled)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
        {type === SELECT && !block && (
            <>
              <LabelWithIcon
                  isError={(get(touched, selector, false) && get(errors, selector, false))}
                  title={(<Lang id={`passport.fields.${selector}`} />)}
              />
              <SelectField
                  error={(get(touched, selector, false) && get(errors, selector, false))}
                  name={selector}
                  mask={mask}
                  withSearch
                  placeholder="Выбрать"
                  options={options || []}
                  light
                  fieldProps={{
                    autoComplete: 'off',
                    disabled: isSubmitting,
                    format,
                    onlyRead: isSubmitting,
                  }}
              />
            </>
        )}
        {type === RADIO_GROUP && !block && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <RadioGroup
              error={(touched[selector] && errors[selector])}
              options={options}
              name={selector}
              fieldProps={{
                disabled: isSubmitting || disabled,
              }}
            />
          </>
        )}
        {type === FILE && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <FileControllField
              error={(touched[selector] && errors[selector])}
              name={selector}
              type="file"
              accept="application/pdf"
              controls={(
                <>
                  {(get(values, `${selector}.title`, null) || get(values, `${selector}.name`, null))
                    && (
                      <>
                        {get(initialValues, 'inner.file', null) === get(values, 'inner.file', null) && (
                          <PortalTooltip
                            title={(<Lang id="tooltip.download" />)}
                            renderChildren={(
                              wrapperRef,
                              onMouseEnterHandler,
                              onMouseLeaveHandler,
                            ) => (
                              <IconContainer
                                onClick={downloadFile}
                                ref={wrapperRef}
                                onMouseEnter={onMouseEnterHandler}
                                onMouseLeave={onMouseLeaveHandler}
                              >
                                <UsersIcons.UploadIcon />
                              </IconContainer>
                            )}
                          />
                        )}
                        {
                          !block &&
                            <PortalTooltip
                                title={(<Lang id="tooltip.remove" />)}
                                renderChildren={(
                                    wrapperRef,
                                    onMouseEnterHandler,
                                    onMouseLeaveHandler,
                                ) => (
                                    <IconContainer
                                        onClick={setFieldToNull(selector, setFieldValue)}
                                        ref={wrapperRef}
                                        onMouseEnter={onMouseEnterHandler}
                                        onMouseLeave={onMouseLeaveHandler}
                                    >
                                      <UsersIcons.TrashIcon />
                                    </IconContainer>
                                )}
                            />
                        }
                      </>
                    )}
                </>
              )}
              disabled={block}
            />
          </>
        )}
        {type === PHOTO && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`passport.fields.${selector}`} />)}
            />
            <FileControllField
              error={(touched[selector] && errors[selector])}
              name={selector}
              setImage={setImage}
              type="photo"
              accept="image/png,image/jpeg"
              controls={(
                <>
                  {(get(values, `${selector}.title`, null) || get(values, `${selector}.name`, null))
                  && !block && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.remove" />)}
                      renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                        <IconContainer
                          onClick={setPhotoToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <UsersIcons.TrashIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
              disabled={block}
            />
          </>
        )}
        {!block && type === CUSTOMS_FIELDS && (
            <>
              {((values[catalogSelector] || {}).customFields || []).map((customField, index) => {
                const localSelector = `${selector}[${index}]`
                const localSelectorValue = `${localSelector}.value`
                const localSelectorName = `${localSelector}.fieldName`
                const nameTouch = get(touched, localSelectorName, null)
                const nameError = get(errors, localSelectorName, null)
                const valueTouch = get(touched, localSelectorValue, null)
                const valueError = get(errors, localSelectorValue, null)
                const value = get(values, localSelectorName, null) || 'Новое поле'
                const previewValue = `"${value.length <= CHAR_LIMITS.medium ? value : `${value.substr(0, CHAR_LIMITS.medium)}...`}" удалено`
                return (
                    <CustomFieldContainer remove={customField.remove}>
                      {!customField.remove
                          ? (
                              <>
                                <CustomFieldBlock>
                                  <LabelWithIcon
                                      isError={nameTouch && nameError}
                                      title="Название:"
                                  />
                                  <IconContainer onClick={setCustomField(setFieldValue, `${localSelector}.remove`, true)}>
                                    <UsersIcons.TrashIcon />
                                  </IconContainer>
                                  <TextField
                                      name={localSelectorName}
                                      mask={mask}
                                      fieldProps={{
                                        autoComplete: 'off',
                                        disabled: isSubmitting || (disabled && !isSubmitting),
                                        format,
                                        onlyRead: isSubmitting,
                                      }}
                                  />
                                </CustomFieldBlock>
                                <CustomFieldBlock>
                                  <LabelWithIcon
                                      isError={valueTouch && valueError}
                                      title="Содержимое:"
                                  />
                                  <TextField
                                      name={localSelectorValue}
                                      mask={mask}
                                      fieldProps={{
                                        autoComplete: 'off',
                                        disabled: isSubmitting || (disabled && !isSubmitting),
                                        format,
                                        onlyRead: isSubmitting,
                                      }}
                                  />
                                </CustomFieldBlock>
                              </>
                          )
                          : (
                              <>
                                {previewValue}
                                <CancelButton
                                    onClick={setCustomField(setFieldValue, `${localSelector}.remove`, false)}
                                >
                                  Отменить
                                </CancelButton>
                              </>
                          )}
                    </CustomFieldContainer>
                )
              })}
            </>
        )}
      </InputAndLabelContainer>
    )
  }

  return (
    <Formik
      key={JSON.stringify(localInitialValues)}
      ref={ref}
      enableReinitialize
      initialValues={localInitialValues}
      render={({
        handleSubmit, touched, errors, isSubmitting, values, setFieldValue, handleChange, dirty,
      }) => {
        setDirty(dirty)
        return (
          <Form onSubmit={handleSubmit} id="PassportizationForm" name="PassportizationForm">
            {!isEmpty(formatOptions) && Object.keys(formatOptions).map((element) => {
              const { selector, options } = formatOptions[element] || {}
              const isOpen = openCatalogsId.includes(selector)
              return (
                <CatalogContainer key={selector}>
                  <CatalogRow>
                    <CatalogTitle>
                      <Lang id={`passport.catalogs.${selector}`} />
                    </CatalogTitle>
                    <PortalTooltip
                      title={isOpen
                        ? (<Lang id="tooltip.collapse" />)
                        : (<Lang id="tooltip.deCollapse" />)}
                      renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                        <ToggleContainer
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                          onClick={catalogToggleHandler(selector)}
                          isOpen={isOpen}
                        >
                          <viewTreeIcons.arrow />
                        </ToggleContainer>
                      )}
                    />
                  </CatalogRow>
                  { isOpen
                    && (
                      <CatalogOptionsContainer>
                        {options.map(renderOption(touched, errors, isSubmitting, values, setFieldValue, handleChange))}
                      </CatalogOptionsContainer>
                    )}
                </CatalogContainer>
              )
            })}
            {block && isExportButtonVisible && (
              <ButtonContainer>
                <Button type="button" onClick={handleExportFile}>
                  <LiveTime.ExportIcon />
                  <Lang id="passportization.passportSidebar.passportExport" />
                </Button>
              </ButtonContainer>
            )}
          </Form>
        )
      }}
    />
  )
})

PassportizationForm.propTypes = {
  initialValues: pt.objectOf(pt.object),
  options: pt.arrayOf(pt.object),
  selectedFields: pt.arrayOf(pt.strings),
  setSelectedPassportFields: pt.func,
  setImage: pt.func,
  block: pt.bool,
}
PassportizationForm.defaultProps = {
  initialValues: {},
  options: [],
  selectedFields: [],
  setSelectedPassportFields: noop,
  setImage: noop,
  block: false,
}

export default PassportizationForm
