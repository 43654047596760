import React, { useEffect } from 'react'
import pt from 'prop-types'
import cookie from 'react-cookies'
import noop from 'lodash/noop'
import { Switch, Route, Redirect } from 'react-router-dom'
import withPermissions from '@/hocs/withPermissions'
import { useHandleActivity } from '@/hooks/useHandleActivity'
import {
  routerHistoryType,
  routerHistoryDefault,
} from '@/propTypes/router'
import BaseLayout from '@/components/layouts/BaseLayout'
import Loader from '@/components/blocks/Loader'
import {
  DASHBOARD_URL,
  USERS_URL,
  OBJECTS_URL,
  INSTALLATION_URL,
  PASPORTIZATION_URL,
  ALARMS_URL,
  ALARMS_ANALYTICS_URL,
  CHARTS_URL,
  PERIOD_OF_EXPLOITATION,
  ANALYTICS_EXPENSES_URL,
  ACTIVITY_LOG_URL,
  TYUMEN_OUR_HOME_URL,
  SERVICE_DESK_MY_CITY_URL,
  WIDGETS_DASHBOARD_URL,
  WIDGETS_DASHBOARD_GOV_URL,
  REPORT_MANAGER_URL,
  CONTROL_OBJECTS_STATISTICS,
  SIGN_IN_URL,
  MOBILE_SETTINGS_URL,
  MOBILE_APPLICATIONS_URL,
  SEARCH_URL,
  PPR_URL,
  SCHEDULE_MANAGER_URL,
  OBJECTS_GROUPS_URL
} from '@/constants/routes'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'
import {
  PENDING,
  REQUEST_STATUSES,
} from '@/constants/requests'
import { SERVER_URL } from '@/constants/socket'
import DashboardPage from '@/containers/pages/Dashboard'
import MapsPage from '@/containers/pages/Maps'
import UsersPage from '@/containers/pages/Users'
import PassportizationPage from '@/containers/pages/Passportization'
import AnalyticsAlarms from '@/containers/pages/AnalyticsAlarms'
import AlarmsManager from '@/containers/pages/AlarmsManager'
import ActionLog from '@/containers/pages/ActionLog'
import Ppr from '@/containers/pages/Ppr'
import ControlsPage from '@/containers/pages/Controls'
import LiveTimePage from '@/containers/pages/LiveTime'
import ConsumptionAnalysisPage from '@/containers/pages/ConsumptionAnalysis'
import SettingMobilePage from '@/containers/pages/SettingsMobile'
import ApplicationMobile from '@/containers/pages/ApplicationsMobile'
import TyumenOurHome from '@/containers/pages/TyumenOurHome'
import ServiceDeskMyCity from '@/containers/pages/ServiceDeskMyCity'
import WidgetsDashboard from '@/containers/pages/WidgetsDashboard'
import WidgetsDashboardGov from '@/containers/pages/WidgetsDashboardGov'
import Installation from '@/containers/pages/Installation'
import ReportManager from '@/containers/pages/ReportManager'
import ObjectControllStatistic from '@/containers/pages/ObjectControllStatistic'
import SearchPage from '@/containers/pages/Search'
import ScheduleManagerPage from '@/containers/pages/ScheduleManger'
import ObjectsGroupsPage from '@/containers/pages/ObjectsGroups'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'

const PrivateRoutes = ({
  history,
  refreshTokenStatus,
  connectToSocket,
  user,
  requestGetSearchFieldsOptions,
}) => {
  const accessToken = cookie.load(SLS24_ACCESS_TOKEN)
  useHandleActivity()
  useEffect(() => {
    if (accessToken) {
      connectToSocket({
        url: SERVER_URL,
        token: accessToken,
      })
    }
  }, [connectToSocket, history, accessToken])

  useEffect(() => {
    requestGetSearchFieldsOptions()
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      const viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute('content', 'width=1440 height=device-height initial-scale=1');
    }
  },[requestGetSearchFieldsOptions])

  if (refreshTokenStatus === REQUEST_STATUSES[PENDING]) {
    return <Loader fullScreen />
  }

  if (!Object.keys(user).length && !accessToken) {
    return (<Redirect to={SIGN_IN_URL} />)
  }

  return (
    <BaseLayout>
      <Switch>
        <Route
          exact
          path={DASHBOARD_URL}
          component={DashboardPage}
        />
        <Route
          exact
          path={OBJECTS_URL}
          component={withPermissions(
            MapsPage,
            ID_BY_APPLICATIONS.MONITORING_CENTER,
          )}
        />
        <Route
          exact
          path={INSTALLATION_URL}
          component={withPermissions(
            Installation,
            ID_BY_APPLICATIONS.INSTALLATION,
          )}
        />
        <Route
          exact
          path={USERS_URL}
          component={withPermissions(
            UsersPage,
            ID_BY_APPLICATIONS.USERS,
          )}
        />
        <Route
          exact
          path={ACTIVITY_LOG_URL}
          component={withPermissions(
            ActionLog,
            ID_BY_APPLICATIONS.ACTIVITY_LOG,
          )}
        />
        <Route
          exact
          path={PASPORTIZATION_URL}
          component={withPermissions(
            PassportizationPage,
            ID_BY_APPLICATIONS.PASSPORTIZATION,
          )}
        />
        <Route
          path={ALARMS_ANALYTICS_URL}
          component={withPermissions(
            AnalyticsAlarms,
            ID_BY_APPLICATIONS.ALARM_ANALYTICS,
          )}
        />
        <Route
          exact
          path={ALARMS_URL}
          component={withPermissions(
            AlarmsManager,
            ID_BY_APPLICATIONS.ACTIVITY_LOG,
          )}
        />
        <Route
          exact
          path={CHARTS_URL}
          component={ControlsPage}
        />
        <Route
          exact
          path={PERIOD_OF_EXPLOITATION}
          component={withPermissions(
            LiveTimePage,
            ID_BY_APPLICATIONS.LIFETIME,
          )}
        />
        <Route
          path={TYUMEN_OUR_HOME_URL}
          component={withPermissions(
            TyumenOurHome,
            ID_BY_APPLICATIONS.TYUMEN_PORTAL,
          )}
        />
        <Route
          path={SERVICE_DESK_MY_CITY_URL}
          component={withPermissions(
            ServiceDeskMyCity,
            ID_BY_APPLICATIONS.SERVICE_DESK,
          )}
        />
        <Route
          exact
          path={ANALYTICS_EXPENSES_URL}
          component={withPermissions(
            ConsumptionAnalysisPage,
            ID_BY_APPLICATIONS.RESOURCE_ANALYTICS,
          )}
        />
        <Route
          exact
          path={WIDGETS_DASHBOARD_URL}
          component={withPermissions(
            WidgetsDashboard,
            ID_BY_APPLICATIONS.DASHBOARD,
          )}
        />
        <Route
          exact
          path={WIDGETS_DASHBOARD_GOV_URL}
          component={withPermissions(
            WidgetsDashboardGov,
            ID_BY_APPLICATIONS.DASHBOARD,
          )}
        />
        <Route
          exact
          path={REPORT_MANAGER_URL}
          component={withPermissions(
            ReportManager,
            ID_BY_APPLICATIONS.REPORT_MANAGER,
          )}
        />
        <Route
          exact
          path={CONTROL_OBJECTS_STATISTICS}
          component={withPermissions(
            ObjectControllStatistic,
            ID_BY_APPLICATIONS.CONTROL_OBJECTS_STATISTICS,
          )}
        />
         <Route
          exact
          path={PPR_URL}
          component={withPermissions(
            Ppr,
            ID_BY_APPLICATIONS.PPR,
          )}
        />
         <Route
          exact
          path={MOBILE_APPLICATIONS_URL}
          component={ApplicationMobile}
        />
        <Route
          exact
          path={MOBILE_SETTINGS_URL}
          component={SettingMobilePage}
        />
        <Route
          exact
          path={SEARCH_URL}
          component={SearchPage}
        />
        <Route
          exact
          path={SCHEDULE_MANAGER_URL}
          component={withPermissions(
            ScheduleManagerPage,
            ID_BY_APPLICATIONS.SCHEDULE_MANAGER,
          )}
        />
        <Route
          exact
          path={OBJECTS_GROUPS_URL}
          component={withPermissions(
            ObjectsGroupsPage,
            ID_BY_APPLICATIONS.OBJECTS_GROUPS,
          )}
        />
        <Route path="*">
          <Redirect to={DASHBOARD_URL} />
        </Route>
      </Switch>
    </BaseLayout>
  )
}

PrivateRoutes.propTypes = {
  connectToSocket: pt.func,
  refreshTokenStatus: pt.string,
  history: routerHistoryType,
}
PrivateRoutes.defaultProps = {
  connectToSocket: noop,
  refreshTokenStatus: null,
  history: routerHistoryDefault,
}

export default PrivateRoutes
