import {
  takeLatest, put, take, select, delay
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_PASSPORT_FILES_URL,
  INSTALLATIONS_OBJECT_ENDPOINT
} from '@/constants/apiRoutes'
import { PUT_PASSPORT_AND_CARD_DATA } from '@/store/actions/passportization'
import {
  responseUpdatePassport,
  errorUpdatePassport,
} from '@/store/actions/passportization/updatePassport'
import toastErrorHelper from '@/helpers/toastHelper'
import createNotifications from '@/helpers/notification'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import installationFormatFields from '@/helpers/installationFormatFields'
import { requestDeleteInstallationObjectFile } from '@/store/actions/installation/deleteObjectFile'
import { requestUploadInstallationObjectFile } from '@/store/actions/installation/uploadObjectFile'
import installationPassportConfig from '@/constants/instalationPassport'
import { isAdditionalFields, isInConfig } from '@/store/sagas/installation/getCard'
import { getData } from '@/store/selectors/passportization'
import {CONNECTED_LINES_OBJECT_TYPES} from "@/constants/instalationPassport/types";
import {requestGetConnectedLinesCoordinates} from "@/store/actions/installation/getConnectedLinesCoordinates";

function* putPassportSage({ payload }) {
  const {
    type,
    values,
    form,
    id,
    asuId,
    parentId,
    parentName,
    fileId,
    photoId
  } = payload
  const toast = createNotifications()
  const cardData = yield select(getData)
  const photoFile = get(values, 'ATTACHMENT.PHOTO_TITLE', null)
  const PDFFile = get(values, 'ATTACHMENT.DOCUMENT_TITLE', null)
  const valuesDeepClone = cloneDeep(values)
  const cardDeepClone = cloneDeep(cardData)
  const name = get(values, 'GENERAL_INFORMATION.NAME', null)
  const photoNameFromCard = (cardDeepClone.objectFields.filter((element) => element.fieldName === 'PHOTO_TITLE')[0] || {}).value || null
  const objectId = get(valuesDeepClone, 'GENERAL_INFORMATION.IDENTIFIER', null)
  const latitude = get(valuesDeepClone, 'LOCATION.LATITUDE', null)
  const longitude = get(valuesDeepClone, 'LOCATION.LONGITUDE', null)
  const integrationType = get(valuesDeepClone, 'integrationType', null)
  const prevObjectElementFieldsDto = get(valuesDeepClone, 'prevObjectElementFieldsDto', {})
  delete valuesDeepClone.LOCATION.LATITUDE
  delete valuesDeepClone.LOCATION.LONGITUDE
  delete valuesDeepClone.GENERAL_INFORMATION.NAME
  delete valuesDeepClone.integrationType
  delete valuesDeepClone.prevObjectElementFieldsDto
  delete valuesDeepClone.objectFields
  // delete valuesDeepClone.GENERAL_INFORMATION.EQUIPMENT_TYPE
  delete valuesDeepClone.path
  delete valuesDeepClone.elementsInfo
  delete valuesDeepClone.ATTACHMENT

  if ((!photoFile && photoId) || (photoFile?.name !== photoNameFromCard && photoId)) {
    yield put(requestDeleteInstallationObjectFile({
      id,
      fileId: photoId,
    }))
    const action = yield take('*')
    if (action.type === '@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE_ERROR') {
      return null
    }
  }
  if (!PDFFile && fileId) {
    yield put(requestDeleteInstallationObjectFile({
      id,
      fileId: fileId,
    }))
    const action = yield take('*')
    if (action.type === '@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE_ERROR') {
      return null
    }
  }
  if (photoFile && (photoFile?.name !== photoNameFromCard || !photoId)) {
    yield put(requestUploadInstallationObjectFile({
      elementId: id,
      file: photoFile,
      title: photoFile.name,
      fileType: 'PHOTO',
    }))
    const action = yield take('*')
    if (action.type === '@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE_ERROR') {
      return null
    }
  }
  if (PDFFile && !fileId) {
    yield put(requestUploadInstallationObjectFile({
      elementId: id,
      file: PDFFile,
      title: PDFFile.name,
      fileType: 'DOCUMENT',
    }))
    const action = yield take('*')
    if (action.type === '@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE_ERROR') {
      return null
    }
  }

  const fields = Object.keys(valuesDeepClone).reduce((accumulator, rootName) => {
    if (typeof valuesDeepClone[rootName] !== 'object') {
      return accumulator
    }
    const childField = Object.keys(valuesDeepClone[rootName] || {}).reduce((buffer, childName) => {
      if (childName === 'customFields') {
        const customFields = get(valuesDeepClone, `${rootName}.customFields`, []).reduce((customFieldsAccumulator, element) => {
          if (element.fieldName && element.value && !element.remove) {
            return [
              ...customFieldsAccumulator,
              {
                ...element,
                fieldName: element.fieldName,
                value: element.value,
                passportBlock: rootName,
              },
            ]
          }
          return customFieldsAccumulator
        }, [])
        return [
          ...buffer,
          ...customFields,
        ]
      }
      return [
        ...buffer,
        {
          ...prevObjectElementFieldsDto[`${rootName}.${childName}`] || {},
          fieldName: `${rootName}.${childName}`,
          value: get(valuesDeepClone, `${rootName}.${childName}`, null),
          passportBlock: rootName,
        },
      ]
    }, [])
    return [
      ...accumulator,
      ...childField,
    ]
  }, [])
  const formattedFields = installationFormatFields(fields)

  const body = {
    name,
    id,
    objectIdOuter: objectId,
    point: {
      latitude,
      longitude,
    },
    objectFields: formattedFields,
    integrationType,
    installationType: type,
    asuId,
    parentId,
    parentName,
  }
  try {
    yield delay(300)
    const response = yield request({
      url: INSTALLATIONS_OBJECT_ENDPOINT,
      method: 'post',
      body,
    })

    if (response && response.data) {
      const data = { ...response.data }
      const parentElementName = { parentProjectName: data.parentName }
      const formattedData = {
        ...data,
        ...parentElementName,
        name,
        id: data.treeElementId || data.id,
        identifier: data.objectIdOuter,
      }
      const integrationType = get(data, 'integrationType', null)
      const objectType = get(data, 'installationType', null)
      const cardAndPassportConfig = get(installationPassportConfig, integrationType, [])
      const cardConfig = get(cardAndPassportConfig, `card.${objectType}`, [])
      const passportConfig = get(cardAndPassportConfig, `passport.${objectType}`, [])
      const config = [...cardConfig, ...passportConfig]

      const formattedObjectFields = (data.objectFields || []).map((element) => {
        const localName = `${element.passportBlock}.${element.fieldName}`
        if (isInConfig(localName, config) || isAdditionalFields(element.fieldName)) {
          return {
            ...element,
            fieldName: localName,
          }
        }
        return element
      })

      const filterElementFields = formattedObjectFields.filter((element) => element.fieldName.split('.')[0] !== 'ATTACHMENT')
      const attachment = formattedObjectFields.filter((element) => element.fieldName.split('.')[0] === 'ATTACHMENT')
      const photoFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_ID')[0] || {}).value || null
      const PDFFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_ID')[0] || {}).value || null
      const photoFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_TITLE')[0] || {}).value || null
      const PDFFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_TITLE')[0] || {}).value || null
      try {
        if (photoFileId && !photoId) {
          const { data: photoFile } = yield request({
            url: `${GET_PASSPORT_FILES_URL}?id=${photoFileId}`,
            method: 'get',
            options: {
              responseType: 'blob',
            },
          })
          const photoFileFromBlob = new window.File([photoFile], photoFileName, { type: 'image/png' })
          filterElementFields.push({
            passportBlock: 'ATTACHMENT',
            fieldName: 'ATTACHMENT.PHOTO_TITLE',
            value: photoFileFromBlob,
          })
        }
      } catch (photoError) {
        console.log('photoError', photoError)
      }
      try {
        if (PDFFileId && !fileId) {
          const { data: PDFFile } = yield request({
            url: `${GET_PASSPORT_FILES_URL}?id=${PDFFileId}`,
            method: 'get',
            options: {
              responseType: 'blob',
            },
          })
          const fileFileFromBlob = new window.File([PDFFile], PDFFileName, { type: 'application/pdf' })
          filterElementFields.push({
            passportBlock: 'ATTACHMENT',
            fieldName: 'ATTACHMENT.DOCUMENT_TITLE',
            value: fileFileFromBlob,
          })
        }
      } catch (fileError) {
        console.log('fileError', fileError)
      }
      formattedData.objectElementFieldsDto = filterElementFields
      formattedData.photoId = photoFileId
      formattedData.fileId = PDFFileId

      if (CONNECTED_LINES_OBJECT_TYPES.some(type => type === response.data.installationType)) {
        yield put(requestGetConnectedLinesCoordinates({
          id: response.data.parentId
        }))
      }
      yield put(responseUpdatePassport(formattedData))
    }
    toast({
      title: 'Успешная операция!',
      description: `Паспорт объекта "${name}" успешно отредактирован.`,
    })
    form.setSubmitting(false)
  } catch (error) {
    yield put(errorUpdatePassport(error))
    form.setSubmitting(false)
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при редактированье паспорта! Повторите попытку позже. Код ошибки: ${toastErrorHelper(error)}`,
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(PUT_PASSPORT_AND_CARD_DATA.REQUEST, putPassportSage)
}
